<template lang="pug">
span.explicit {{ $t('single_words.explicit') }}
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.explicit {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 4px 8px;
  margin-left: $gap;
  line-height: 14px;
  border-radius: 4px;
  mix-blend-mode: normal;
  letter-spacing: -0.53px;
  border: 1px solid rgba(255, 255, 255, 0.8);
}
</style>
