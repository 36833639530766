<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5 10.5L20.0868 11.5763C21.2111 11.8344 22 12.8404 22 14.0053C22 14.6936 21.7239 15.3158 21.2768 15.7658C20.8297 16.2159 20.2117 16.4939 19.5279 16.4939H19.0414"
      :fill="`url(#${componentId}_paint0_linear_2042_27997)`"
    />
    <path
      d="M4.95858 16.4936H4.47206C3.7883 16.4936 3.17028 16.2157 2.72321 15.7656C2.27613 15.3155 2 14.6934 2 14.0051C2 12.8402 2.78895 11.8342 3.91321 11.5761L7.5 10.5"
      :fill="`url(#${componentId}_paint1_linear_2042_27997)`"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19 16C19 19.866 15.866 23 12 23C8.13401 23 5 19.866 5 16C5 12.134 8.13401 9 12 9C15.866 9 19 12.134 19 16ZM10.9941 9.4387C10.8297 9.5446 10.81 9.78949 10.9612 9.92186L11.4477 10.3653C11.8028 10.6896 12.3485 10.6764 12.6903 10.3322L13.098 9.92186C13.2295 9.78287 13.2163 9.55122 13.0519 9.44532C12.8218 9.29972 12.48 9.12763 12.0395 9.12763C11.5858 9.12102 11.2308 9.28648 10.9941 9.4387Z"
      :fill="`url(#${componentId}_paint2_linear_2042_27997)`"
    />
    <g :filter="`url(#${componentId}_filter0_i_2042_27997)`">
      <path
        d="M17 23C18.6569 23 20 21.6569 20 20C20 18.3431 18.6569 17 17 17C15.3431 17 14 18.3431 14 20C14 21.6569 15.3431 23 17 23Z"
        :fill="`url(#${componentId}_paint3_linear_2042_27997)`"
        fill-opacity="0.6"
      />
    </g>
    <g :filter="`url(#${componentId}_filter1_i_2042_27997)`">
      <path
        d="M7 23C8.65685 23 10 21.6569 10 20C10 18.3431 8.65685 17 7 17C5.34315 17 4 18.3431 4 20C4 21.6569 5.34315 23 7 23Z"
        :fill="`url(#${componentId}_paint4_linear_2042_27997)`"
        fill-opacity="0.6"
      />
    </g>
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12 13.6348C15.3137 13.6348 18 10.9485 18 7.63477C18 4.32106 15.3137 1.63477 12 1.63477C8.68629 1.63477 6 4.32106 6 7.63477C6 10.9485 8.68629 13.6348 12 13.6348ZM10.9612 9.92186C10.81 9.78949 10.8297 9.5446 10.9941 9.4387C11.2308 9.28648 11.5858 9.12102 12.0395 9.12763C12.48 9.12763 12.8218 9.29972 13.0519 9.44532C13.2163 9.55122 13.2295 9.78287 13.098 9.92186L12.6903 10.3322C12.3485 10.6764 11.8028 10.6896 11.4477 10.3653L10.9612 9.92186ZM10.3366 8.1215C10.3366 8.41027 10.1041 8.64436 9.81723 8.64436C9.53038 8.64436 9.29784 8.41027 9.29784 8.1215C9.29784 7.83273 9.53038 7.59863 9.81723 7.59863C10.1041 7.59863 10.3366 7.83273 10.3366 8.1215ZM14.8008 8.1215C14.8008 8.41027 14.5682 8.64436 14.2814 8.64436C13.9945 8.64436 13.762 8.41027 13.762 8.1215C13.762 7.83273 13.9945 7.59863 14.2814 7.59863C14.5682 7.59863 14.8008 7.83273 14.8008 8.1215Z"
      :fill="`url(#${componentId}_paint5_linear_2042_27997)`"
    />
    <g :filter="`url(#${componentId}_filter2_bi_2042_27997)`">
      <path
        d="M7.5 6C8.88071 6 10 4.88071 10 3.5C10 2.11929 8.88071 1 7.5 1C6.11929 1 5 2.11929 5 3.5C5 4.88071 6.11929 6 7.5 6Z"
        :fill="`url(#${componentId}_paint6_linear_2042_27997)`"
        fill-opacity="0.6"
      />
    </g>
    <g :filter="`url(#${componentId}_filter3_bi_2042_27997)`">
      <path
        d="M16.5 6C17.8807 6 19 4.88071 19 3.5C19 2.11929 17.8807 1 16.5 1C15.1193 1 14 2.11929 14 3.5C14 4.88071 15.1193 6 16.5 6Z"
        :fill="`url(#${componentId}_paint7_linear_2042_27997)`"
        fill-opacity="0.6"
      />
    </g>
    <defs>
      <filter
        :id="`${componentId}_filter0_i_2042_27997`"
        x="14"
        y="17"
        width="6.04768"
        height="6.02384"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.047683" dy="0.0238415" />
        <feGaussianBlur stdDeviation="0.119208" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2042_27997"
        />
      </filter>
      <filter
        :id="`${componentId}_filter1_i_2042_27997`"
        x="4"
        y="17"
        width="6.04768"
        height="6.02384"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.047683" dy="0.0238415" />
        <feGaussianBlur stdDeviation="0.119208" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_2042_27997"
        />
      </filter>
      <filter
        :id="`${componentId}_filter2_bi_2042_27997`"
        x="4.47778"
        y="0.47778"
        width="6.04444"
        height="6.04444"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.26111" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_2042_27997"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_2042_27997"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.047683" dy="0.0238415" />
        <feGaussianBlur stdDeviation="0.119208" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2042_27997"
        />
      </filter>
      <filter
        :id="`${componentId}_filter3_bi_2042_27997`"
        x="13.4778"
        y="0.47778"
        width="6.04444"
        height="6.04444"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.26111" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_2042_27997"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_2042_27997"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.047683" dy="0.0238415" />
        <feGaussianBlur stdDeviation="0.119208" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2042_27997"
        />
      </filter>
      <linearGradient
        :id="`${componentId}_paint0_linear_2042_27997`"
        x1="18.4896"
        y1="15.9054"
        x2="20.8341"
        y2="10.6035"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9BF8F4" />
        <stop offset="1" stop-color="#6F7BF7" />
      </linearGradient>
      <linearGradient
        :id="`${componentId}_paint1_linear_2042_27997`"
        x1="3.38764"
        y1="15.9045"
        x2="5.73682"
        y2="10.5984"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9BF8F4" />
        <stop offset="1" stop-color="#6F7BF7" />
      </linearGradient>
      <linearGradient
        :id="`${componentId}_paint2_linear_2042_27997`"
        x1="8.96622"
        y1="21.5322"
        x2="14.2772"
        y2="7.7704"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9BF8F4" />
        <stop offset="1" stop-color="#6F7BF7" />
      </linearGradient>
      <linearGradient
        :id="`${componentId}_paint3_linear_2042_27997`"
        x1="14"
        y1="20"
        x2="20"
        y2="20"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9BF8F4" />
        <stop offset="1" stop-color="#6F7BF7" />
      </linearGradient>
      <linearGradient
        :id="`${componentId}_paint4_linear_2042_27997`"
        x1="4"
        y1="20"
        x2="10"
        y2="20"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9BF8F4" />
        <stop offset="1" stop-color="#6F7BF7" />
      </linearGradient>
      <linearGradient
        :id="`${componentId}_paint5_linear_2042_27997`"
        x1="6"
        y1="7.63477"
        x2="18"
        y2="7.63477"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9BF8F4" />
        <stop offset="1" stop-color="#6F7BF7" />
      </linearGradient>
      <linearGradient
        :id="`${componentId}_paint6_linear_2042_27997`"
        x1="5"
        y1="3.5"
        x2="10"
        y2="3.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9BF8F4" />
        <stop offset="1" stop-color="#6F7BF7" />
      </linearGradient>
      <linearGradient
        :id="`${componentId}_paint7_linear_2042_27997`"
        x1="14"
        y1="3.5"
        x2="19"
        y2="3.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9BF8F4" />
        <stop offset="1" stop-color="#6F7BF7" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    componentId: {
      type: String,
      default: ''
    }
  }
}
</script>
