<template>
  <svg
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.72793 2.57839V6.91845L3.2667 6.2001C4.89906 4.02366 7.11574 2.33014 9.68158 1.33176C13.0271 0.0299906 16.7339 0.000430177 20.0998 1.24868C23.4657 2.49693 26.2569 4.93629 27.9446 8.10468C29.6324 11.2731 30.0995 14.9504 29.2575 18.4402C28.4155 21.9299 26.3229 24.9897 23.376 27.0398C20.4291 29.09 16.8326 29.9881 13.2679 29.5642C9.70313 29.1402 6.41765 27.4235 4.03382 24.7394C1.71631 22.1299 0.407459 18.7822 0.336426 15.2995L2.72354 15.3003L2.72625 15.3069C2.79856 18.2022 3.89256 20.9826 5.8201 23.153C7.81563 25.3999 10.5659 26.8369 13.55 27.1918C16.5341 27.5467 19.5448 26.7949 22.0116 25.0787C24.4785 23.3625 26.2303 20.8011 26.9351 17.8798C27.64 14.9585 27.249 11.8802 25.8361 9.22788C24.4233 6.57558 22.0867 4.53357 19.2691 3.48865C16.4515 2.44372 13.3485 2.46847 10.5479 3.55819C8.22106 4.46358 6.23726 6.05186 4.84673 8.09717L4.52883 8.56478L8.71432 8.56478V10.9593H0.333374V2.57839H2.72793Z"
      fill="white"
    />
    <path
      d="M0.336426 15.2995L0.334308 15.2988L0.335575 15.2967L0.336306 15.2935L0.336426 15.2995Z"
      fill="white"
    />
    <path
      d="M12.453 10.5104V20.8452H9.75185V13.0941H9.69355L7.51704 14.4465V12.0646L9.96561 10.5104H12.453Z"
      fill="white"
    />
    <path
      d="M18.4797 20.9865C17.7154 20.9865 17.0368 20.8469 16.4441 20.5677C15.8546 20.2851 15.3899 19.8965 15.0498 19.402C14.713 18.9074 14.5413 18.3389 14.5348 17.6963H17.1583C17.168 18.0529 17.3008 18.3372 17.5567 18.5492C17.8158 18.7611 18.1235 18.8671 18.4797 18.8671C18.755 18.8671 18.998 18.8065 19.2085 18.6854C19.419 18.5609 19.5842 18.386 19.704 18.1606C19.8239 17.9352 19.8822 17.6728 19.8789 17.3734C19.8822 17.0706 19.8239 16.8082 19.704 16.5861C19.5842 16.3607 19.419 16.1875 19.2085 16.0664C18.998 15.9419 18.755 15.8797 18.4797 15.8797C18.1947 15.8797 17.9308 15.952 17.6878 16.0966C17.4482 16.2379 17.2716 16.4347 17.1583 16.6871L14.7874 16.2026L15.1761 10.5104H21.8611V12.7509H17.3915L17.1972 15.0319H17.2555C17.4109 14.719 17.6797 14.4599 18.0619 14.2547C18.4441 14.0462 18.8878 13.9419 19.3931 13.9419C19.9858 13.9419 20.5137 14.0848 20.9769 14.3708C21.4433 14.6568 21.8109 15.0521 22.0797 15.5567C22.3518 16.058 22.4862 16.6366 22.483 17.2926C22.4862 18.0126 22.321 18.6518 21.9874 19.2102C21.657 19.7653 21.1923 20.201 20.5931 20.5172C19.9939 20.8301 19.2895 20.9865 18.4797 20.9865Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {}
</script>
