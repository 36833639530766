<template>
  <svg viewBox="0 0 24 24" :fill="color" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.25 19V5C3.25 4.27065 3.53973 3.57118 4.05546 3.05546C4.57118 2.53973 5.27065 2.25 6 2.25H19.4C19.758 2.25 20.1014 2.39223 20.3546 2.64541C20.6078 2.89858 20.75 3.24196 20.75 3.6V16.714C20.75 16.7629 20.7453 16.8107 20.7364 16.857C20.7453 16.9033 20.75 16.9511 20.75 17C20.75 17.4142 20.4142 17.75 20 17.75H6C5.66848 17.75 5.35054 17.8817 5.11612 18.1161C4.8817 18.3505 4.75 18.6685 4.75 19C4.75 19.3315 4.8817 19.6495 5.11612 19.8839C5.35054 20.1183 5.66848 20.25 6 20.25H20C20.4142 20.25 20.75 20.5858 20.75 21C20.75 21.4142 20.4142 21.75 20 21.75H6C5.27065 21.75 4.57118 21.4603 4.05546 20.9445C3.53973 20.4288 3.25 19.7293 3.25 19ZM5.11612 4.11612C5.35054 3.8817 5.66848 3.75 6 3.75H19.25V16.25H6C5.56137 16.25 5.13355 16.3548 4.75 16.5505V5C4.75 4.66848 4.8817 4.35054 5.11612 4.11612Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.25 7C8.25 6.58579 8.58579 6.25 9 6.25H15C15.4142 6.25 15.75 6.58579 15.75 7C15.75 7.41421 15.4142 7.75 15 7.75H9C8.58579 7.75 8.25 7.41421 8.25 7Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'white'
    }
  }
}
</script>
