import { config } from '@/config'
// import eventSystem from '@/lib/event-system/index.js'

const cookiesDomain = config.baseUrl.replace('https://', '.')
const env = config.envFullName

export const actions = {
  async nuxtServerInit ({ state, commit, dispatch }, { app, req }) {
    const locale = state.i18n.locale
    const currentRouteName = app.context.route.name

    app.head.script.filter((script) => {
      if (script.hid === 'freshdesk-widget-init') {
        script.innerHTML = `window.fwSettings={ widget_id: 44000001365, locale: '${locale}' }; !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()`
      }
      return script
    })

    if (currentRouteName && !currentRouteName.includes('___' + locale)) {
      return
    }

    if (process.env.INSPECT_NODE && global.gc) {
      setTimeout(() => {
        console.log('Start force garbage collection for test on local dev (npm run dev-inspect):')
        global.gc()
      }, 20 * 1000)
    }

    // Set user current country
    if (req.headers && req.headers['cloudfront-viewer-country']) {
      const userCurrentLocation = req.headers['cloudfront-viewer-country']
      commit('auth/setCurrentCountry', userCurrentLocation)
    }

    // Check AWS status
    await dispatch('app/updateAppStatus')

    let idToken = null
    let accessToken = null
    let refreshToken = null

    idToken = app.$cookiz.get('RSS_IT')
    accessToken = app.$cookiz.get('RSS_AT')
    refreshToken = app.$cookiz.get('RSS_RT')

    const clearCookiesTokens = () => {
      app.$cookiz.remove('RSS_IT', {
        path: '/',
        secure: true,
        domain: cookiesDomain
      })
      app.$cookiz.remove('RSS_AT', {
        path: '/',
        secure: true,
        domain: cookiesDomain
      })
      app.$cookiz.remove('RSS_RT', {
        path: '/',
        secure: true,
        domain: cookiesDomain
      })
      app.$cookiz.remove('RSS_EXPIRED_IN')
    }

    // Check for existing tokens inside Cookies
    if (!idToken || !accessToken || !refreshToken || idToken === 'undefined' || accessToken === 'undefined' || refreshToken === 'undefined') {
      clearCookiesTokens()
      return
    }

    try {
      dispatch('auth/signInInitTokens', {
        idToken,
        accessToken,
        refreshToken
      })
    } catch (error) {
      console.log('Error with set tokens', error)
    }

    // Check for success response with existing tokens
    console.log('starting auth/gdprDataGet dispatch')
    try {
      const gdprDataGetResponse = await dispatch('auth/gdprDataGet', idToken)
      if (!gdprDataGetResponse) {
        clearCookiesTokens()
        return
      }
    } catch (error) {
      console.log('Error with set GDPR', error)
    }

    const userLanguage = await dispatch('auth/getUserLanguage')

    if (userLanguage) {
      commit('auth/setDashboardLanguage', userLanguage)
    }

    commit('auth/setAuthStateFromIdToken', idToken)
    commit('auth/setLoginStatus', true)
    dispatch('auth/setUserProfilePictureUrls')

    const userEmail = state.auth.email

    // Sentry user context
    if (userEmail) {
      app.$sentry.configureScope(function (scope) {
        scope.setUser({
          email: userEmail
        })
      })
    }

    // TODO: do we need this?
    await dispatch('podcasts/init')
  },

  nuxtClientInit ({ state, commit, dispatch }, { app, req }) {
    // registration of global setInterval
    setInterval(() => {
      dispatch('app/updateAppStatus', {}, { root: true })
    }, 1000 * 60) // 1 min

    const thirdPartyScriptsAccepted = app.$cookiz.get('RSS_GDPR_TPART')

    // eslint-disable-next-line
    window.dataLayer = window.dataLayer || []
    // eslint-disable-next-line
    window.dataLayer.push({
      env
    })

    // const userId = state.auth.userId
    const userEmail = state.auth.email

    if (thirdPartyScriptsAccepted !== 'false' && thirdPartyScriptsAccepted !== false) {
      app.$gtm.init()

      // if (userEmail && userId) {
      //   eventSystem.Mixpanel.setUser(userEmail, userId)
      //   eventSystem.Mixpanel.identify(userId)
      //   eventSystem.Drip.identify(userEmail, userId)
      // }
    }

    // ProfitWell event
    if (userEmail && thirdPartyScriptsAccepted !== 'false' && thirdPartyScriptsAccepted !== false) {
      app.$gtm.push({ event: 'start_pw', pw_user_email: userEmail })
    }
  }
}
