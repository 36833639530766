import { config } from '@/config'

const cookiesDomain = config.baseUrl.replace('https://', '.')

export default {
  setScrollPosition (context, position) {
    context.commit('setScrollPosition', position)
  },

  setScreen (context, width) {
    context.commit('setScreen', width)
  },

  checkCookie (context) {
    const cookieStatus = this.$cookiz.get('cookieClosed')
    if (cookieStatus) {
      return
    }
    context.commit('handleCookieAlertVisibility', true)
  },

  closeCookieAlert ({ commit }) {
    this.$cookiz.set('cookieClosed', true, {
      path: '/',
      secure: true,
      domain: cookiesDomain
    })
    commit('handleCookieAlertVisibility', false)
  }
}
