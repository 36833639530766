import { parseIdToken } from '@/lib/utils.js'

export default {
  setLoginStatus (state, payload) {
    state.isLoggedIn = payload
  },

  setToken (state, payload) {
    state.idToken = payload
  },

  setAccessToken (state, payload) {
    state.accessToken = payload
  },

  setRefreshToken (state, payload) {
    state.refreshToken = payload
  },

  setAvatarPhotoUrl (state, payload) {
    state.avatarPhotoUrl = {
      default: payload.default.toLowerCase(),
      retina: payload.retina.toLowerCase()
    }
  },

  setAuthStateFromIdToken (state, idToken) {
    const parsedIdToken = parseIdToken(idToken)
    state.userId = parsedIdToken['custom:mysql_id']
    state.userSub = parsedIdToken.sub
    state.email = parsedIdToken.email
    state.emailVerified = parsedIdToken.email_verified
  },

  setGdprData (state, gdprData) {
    state.gdprThirdParty = gdprData.gdpr_3rd_parties
    state.gdprMarketing = gdprData.gdpr_marketing
    state.gdprTerms = gdprData.gdpr_terms
  },

  setDashboardLanguage (state, lang) {
    state.dashboardLanguage = lang
  },

  setCurrentCountry (state, countryIsoCode) {
    state.currentCountry = countryIsoCode
  }
}
