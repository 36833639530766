import locale9ec66352 from '../../locales/en.json'

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: true,
}
export const options = {
  vueI18n: {"fallbackLocale":"en"},
  vueI18nLoader: false,
  locales: [{"territory":"US","name":"English","code":"en","iso":"en","file":"en.json"},{"territory":"ES","name":"Español","code":"es","iso":"es","file":"es.json"},{"territory":"IT","name":"Italiano","code":"it","iso":"it","file":"it.json"}],
  defaultLocale: "en",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "prefix_except_default",
  lazy: true,
  langDir: "/app/locales",
  rootRedirect: null,
  detectBrowserLanguage: false,
  differentDomains: false,
  seo: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncLocale":true,"syncMessages":true,"syncRouteParams":true},
  parsePages: false,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  beforeLanguageSwitch: () => null,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"territory":"US","name":"English","code":"en","iso":"en","file":"en.json"},{"territory":"ES","name":"Español","code":"es","iso":"es","file":"es.json"},{"territory":"IT","name":"Italiano","code":"it","iso":"it","file":"it.json"}],
  localeCodes: ["en","es","it"],
}

export const localeMessages = {
  'en.json': () => Promise.resolve(locale9ec66352),
  'es.json': () => import('../../locales/es.json' /* webpackChunkName: "lang-es.json" */),
  'it.json': () => import('../../locales/it.json' /* webpackChunkName: "lang-it.json" */),
}
