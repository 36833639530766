<template lang="pug">
.audio-player(
  :class="{'audio-player_opened': currentEpisode}"
)
  .audio-player__box
    .audio-player__container
      img.audio-player__cover(
        v-if="currentEpisode"
        :src="episodeCoverUrl.default"
        :srcset="episodeCoverUrl.retina + ' 2x'"
      )
      .audio-player__main(
        v-if="currentEpisode"
      )
        h3.audio-player__name
          span(v-html="episodeTitle")
          UiExplicitTag(
            v-if="episodeExplicit === 'yes'"
          )
        .audio-player__podcast
          img.audio-player__podcast-cover(
            :src="podcastCoverUrl.default"
            :srcset="podcastCoverUrl.retina + ' 2x'"
          )
          span.audio-player__podcast-name
            | <strong>{{ podcastTitle }}</strong>
            | by
            | <strong>{{ podcastAuthor }}</strong>
      .audio-player__controller
        .audio-player__controller-nav
          a.audio-player__controller-nav-prev(
            href="#"
            @click.prevent="nextEpisodeClickHandler"
            :class="{'audio-player__controller-nav_disabled': isLatestEpisodeInsideThePlaylist}"
          )
            PrevIcon
          a.audio-player__controller-nav-play(
            href="#"
            @click.prevent="playPausePlaying"
          )
            .audio-player__controller-loading(
              v-if="loading"
            )
              BLoading(
                :is-full-page="false"
                :active="loading"
              )

            PlayPauseIcon(
              v-else
              :isPlaying="status === 'playing'"
            )
          a.audio-player__controller-nav-next(
            href="#"
            @click.prevent="previousEpisodeClickHandler"
            :class="{'audio-player__controller-nav_disabled': isFirstEpisodeInsideThePlaylist}"
          )
            NextIcon
        .audio-player__controller-bar(
          ref="timelineControllerBar"
        )
          span {{ prettyAudioCurrentTime }}
          .audio-player-timeline(
            ref="timeline"
            @click="timelineClickHandler"
          )
            .audio-player-timeline__progress(
              ref="timelineProgress"
            )
          span {{ prettyAudioDurationTime }}

        .audio-player__controller-sound
          a.audio-player__controller-sound-btn(
            href="#"
            ref="soundBtn"
          )
            VolumeIcon
          .audio-player__controller-sound-slider(
            ref="soundSlider"
            :class="{'audio-player__controller-sound-slider_opened': mobileSoundControlSliderIsOpened}"
            @click="soundTimelineClickHandler"
          )
            .audio-player__controller-sound-slider-progress(
              ref="soundProgress"
            )
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

import toast from '@/lib/toast'
import { config } from '@/config'
import { getResizedImageUrl } from '@/lib/utils'

import PrevIcon from '@/components/icons/player/Prev'
import NextIcon from '@/components/icons/player/Next'
import VolumeIcon from '@/components/icons/player/Volume'
import PlayPauseIcon from '@/components/icons/player/PlayPause'

export default {
  components: {
    PrevIcon,
    NextIcon,
    VolumeIcon,
    PlayPauseIcon
  },

  data () {
    return {
      isFirstPlay: false,
      initialized: false,

      buffer: null,
      gainNode: null,
      sourceNode: null,
      audioContext: null,

      prettyAudioCurrentTime: '00:00',
      mobileSoundControlSliderIsOpened: false,

      audioTimelineEl: null,
      audioVolumeLineEl: null,
      audioVolumeButtonEl: null,
      audioProgressLineEl: null,
      audioTimelineWrapperEl: null,
      audioTimelineProgressEl: null,

      volume: 1,
      trackPausedAt: 0,
      trackStartedAt: 0
    }
  },

  computed: {
    ...mapState('player', [
      'status',
      'loading',
      'currentEpisode',
      'currentPodcast'
    ]),

    ...mapGetters('player', [
      'isFirstEpisodeInsideThePlaylist',
      'isLatestEpisodeInsideThePlaylist',
      'getNextEpisodeInsideThePlaylist',
      'getPreviousEpisodeInsideThePlaylist'
    ]),

    podcastSlug () {
      return this.$route.params.slug
    },

    episodeTitle () {
      if (!this.currentEpisode) {
        return ''
      }

      let episodeTitle = this.currentEpisode.title

      if (episodeTitle && episodeTitle.length > 70) {
        episodeTitle = episodeTitle.slice(0, 70) + '...'
      }

      if (!this.episodeNumbers) {
        return episodeTitle
      }

      return this.episodeNumbers + ' - ' + episodeTitle
    },

    episodeAudioUrl () {
      if (!this.currentEpisode || !this.currentEpisode.episode_asset) {
        return ''
      }
      return `${config.mediaUrl}/${this.podcastSlug}/${this.currentEpisode.episode_asset}`
    },

    episodeNumbers () {
      let season = this.currentEpisode.itunes_season
      let episode = this.currentEpisode.itunes_episode

      if (!parseInt(season) && !parseInt(episode)) {
        return ''
      }
      if (season > 0 && season < 10) { season = '0' + season.toString() }
      if (episode > 0 && episode < 10) { episode = '0' + episode.toString() }

      const seasonLetter = this.$t('single_words.season')[0]
      const episodeLetter = this.$t('single_words.episode')[0]

      if (!episode) {
        return `${seasonLetter}${season}`
      }

      if (!season) {
        return `${episodeLetter}${episode}`
      }

      return `${seasonLetter}${season} ${episodeLetter}${episode}`
    },

    episodeCoverUrl () {
      if (!this.currentEpisode.episode_cover) {
        if (this.podcastCoverUrl) {
          return this.podcastCoverUrl
        } else {
          return {
            default: '/images/no-cover.png',
            retina: '/images/no-cover.png'
          }
        }
      }

      const filename = this.currentEpisode.episode_cover

      if (filename === 'no-cover-1400.jpg') {
        return {
          default: this.currentEpisode.episode_cover,
          retina: this.currentEpisode.episode_cover
        }
      }

      const cover = getResizedImageUrl(64, this.podcastSlug, filename)

      return cover
    },

    episodeDuration () {
      if (!this.currentEpisode) {
        return 0
      }
      return this.currentEpisode.itunes_duration
    },

    podcastCoverUrl () {
      if (!this.currentPodcast.cover) {
        return {
          default: '/images/no-cover.png',
          retina: '/images/no-cover.png'
        }
      }

      const filename = this.currentPodcast.cover

      if (filename === 'no-cover-1400.jpg') {
        return {
          default: this.currentPodcast.cover,
          retina: this.currentPodcast.cover
        }
      }

      const cover = getResizedImageUrl(100, this.podcastSlug, filename)

      return cover
    },

    podcastTitle () {
      if (!this.currentPodcast) {
        return ''
      }

      let podcastTitle = this.currentPodcast.title

      if (podcastTitle && podcastTitle.length > 55) {
        podcastTitle = podcastTitle.slice(0, 55) + '...'
      }

      return podcastTitle
    },

    podcastAuthor () {
      return this.currentPodcast.author_name
    },

    prettyAudioDurationTime () {
      if (!this.buffer) {
        return '00:00'
      }
      const duration = Math.floor(this.buffer.duration)

      let result = ''

      let seconds = duration % 60
      let hours = Math.floor(duration / 60 / 60)
      let minutes = Math.floor(duration / 60) - (hours * 60)

      if (seconds > 0 && seconds < 10) {
        seconds = `0${seconds}`
      }

      if (minutes > 0 && minutes < 10) {
        minutes = `0${minutes}`
      }

      if (hours > 0 && hours < 10) {
        hours = `0${hours}`
      }

      if (hours) {
        result += hours + ':'
      }

      result += minutes + ':' + seconds

      return result
    }
  },

  watch: {
    async currentEpisode (episode, previousPlayedEpisode) {
      if (episode) {
        this.setPlayerStatus('stopped')

        this.setLoadingStatus(true)
        const episodeAssetUrl = `${config.mediaUrl}/${this.currentPodcast.slug}/${episode.episode_asset}`
        let response = null
        try {
          response = await this.$axios.get(episodeAssetUrl, { responseType: 'arraybuffer' })
        } catch (error) {
          toast(this.$t('toast_notifications.errors.audio_file__not_available'), 'danger')
        }

        if (!response) {
          this.setCurrentPlayingPodcast(null)
          this.setCurrentPlayingEpisode(null)
          this.setLoadingStatus(false)
          return
        }

        this.audioContext.decodeAudioData(
          response.data,

          (buffer) => {
            this.buffer = buffer
            this.setLoadingStatus(false)
            this.setPlayerStatus('playing')
          },

          (error) => {
            console.log('error', error)
            this.setLoadingStatus(false)
          })
      }
    },

    status (value) {
      this.$nextTick(() => {
        if (value === 'playing') {
          const offset = this.trackPausedAt

          this.gainNode = this.audioContext.createGain()
          this.gainNode.gain.value = this.volume
          this.gainNode.connect(this.audioContext.destination)

          this.sourceNode = this.audioContext.createBufferSource()
          this.sourceNode.connect(this.gainNode)
          this.sourceNode.buffer = this.buffer

          if (this.sourceNode.start) {
            this.sourceNode.start(0, offset)
          } else if (this.sourceNode.play) {
            this.sourceNode.play(0, offset)
          } else if (this.sourceNode.noteOn) {
            this.sourceNode.noteOn(0, offset)
          }

          this.sourceNode.addEventListener('ended', () => {
            const currentTrackTime = this.getTrackCurrentTime()
            if (currentTrackTime > this.buffer.duration) {
              this.setPlayerStatus('stopped')
              this.changeAudioProgressLine('0')
            }
          })

          this.trackStartedAt = this.audioContext.currentTime - offset
          this.trackPausedAt = 0
        }

        if (value === 'paused') {
          const elapsed = this.audioContext.currentTime - this.trackStartedAt

          if (this.sourceNode) {
            this.sourceNode.disconnect()
            this.sourceNode.stop(0)
            this.sourceNode = null
          }

          this.trackStartedAt = 0
          this.trackPausedAt = elapsed
        }

        if (value === 'stopped') {
          if (this.sourceNode) {
            this.sourceNode.disconnect()
            this.sourceNode.stop(0)
            this.sourceNode = null
          }

          this.trackPausedAt = 0
          this.trackStartedAt = 0
        }
      })
    }
  },

  mounted () {
    if (!this.initialized) {
      this.init()
      this.initialized = true
    }
  },

  beforeDestroy () {
    if (!this.mainPlayerInterval) {
      return
    }
    clearInterval(this.mainPlayerInterval)
  },

  methods: {
    ...mapActions('podcasts', ['getEpisodeById']),
    ...mapActions('player', [
      'setPlayerStatus',
      'setCountdown',
      'setLoadingStatus',
      'setCurrentPlayingPodcast',
      'setCurrentPlayingEpisode'
    ]),

    unlockAudioContext (audioCtx) {
      if (audioCtx.state !== 'suspended') {
        return
      }
      const b = document.body

      const clean = () => {
        events.forEach((e) => {
          b.removeEventListener(e, unlock)
        })
      }

      const unlock = () => {
        audioCtx.resume().then(clean)
      }

      const events = ['touchstart', 'touchend', 'mousedown', 'keydown']

      events.forEach((e) => {
        b.addEventListener(e, unlock, false)
      })
    },

    init () {
      this.audioContext = this.getAudioContext()
      this.unlockAudioContext(this.audioContext)

      this.audioTimelineEl = this.$refs.timeline
      this.audioVolumeButtonEl = this.$refs.soundBtn
      this.audioVolumeLineEl = this.$refs.soundSlider
      this.audioTimelineProgressEl = this.$refs.timelineProgress
      this.audioTimelineWrapperEl = this.$refs.timelineControllerBar

      // Sound volume - click on mobile
      this.audioVolumeButtonEl.addEventListener('click', (event) => {
        event.preventDefault()

        const windowWidth = window.innerWidth
        const controllerBarWidth = (parseInt(window.getComputedStyle(this.audioTimelineWrapperEl).width.replace('px', '')) - 16).toString() + 'px'

        if (windowWidth < 600) {
          if (this.mobileSoundControlSliderIsOpened) {
            this.audioVolumeLineEl.style.left = '0px'
            this.audioVolumeLineEl.style.width = '0px'
          } else {
            this.audioVolumeLineEl.style.left = '-' + controllerBarWidth.toString()
            this.audioVolumeLineEl.style.width = controllerBarWidth
          }

          this.mobileSoundControlSliderIsOpened = !this.mobileSoundControlSliderIsOpened
        }
      })

      const update = () => {
        window.requestAnimationFrame(update)
        this.changePrettyAudioCurrentTime()
        this.changeAudioProgressLine()
      }
      update()
    },

    getTrackCurrentTime () {
      if (this.trackPausedAt) {
        return this.trackPausedAt
      }
      if (this.trackStartedAt) {
        return this.audioContext.currentTime - this.trackStartedAt
      }
      return 0
    },

    changeAudioProgressLine (value) {
      if (value !== undefined) {
        this.audioTimelineProgressEl.style.width = value + '%'
        return
      }
      const currentTime = this.getTrackCurrentTime()
      if (this.buffer && this.audioTimelineProgressEl) {
        const audioDuration = this.buffer.duration

        if (currentTime && audioDuration) {
          this.audioTimelineProgressEl.style.width = parseFloat(currentTime / audioDuration) * 100 + '%'
        }
      }
    },

    getAudioContext () {
      // es-lint-disable-next-line
      window.AudioContext = window.AudioContext || window.webkitAudioContext
      const audioContent = new AudioContext()
      return audioContent
    },

    timelineClickHandler (event) {
      const timeline = this.audioTimelineEl
      const timelineWidth = window.getComputedStyle(timeline).width
      const timeToSeek = event.offsetX / parseInt(timelineWidth) * this.buffer.duration

      this.setPlayerStatus('paused')

      this.$nextTick(() => {
        this.$nextTick(() => {
          this.trackPausedAt = timeToSeek
          this.setPlayerStatus('playing')
        })
      })
    },

    playPausePlaying () {
      if (this.status === 'playing') {
        this.setPlayerStatus('paused')
      } else {
        this.setPlayerStatus('playing')
      }
    },

    changePrettyAudioCurrentTime () {
      if (!this.audioContext) {
        return '00:00'
      }

      const duration = this.getTrackCurrentTime()

      let result = ''

      let seconds = Math.round(duration % 60)
      let hours = Math.floor(duration / 60 / 60)
      let minutes = Math.floor(duration / 60) - (hours * 60)

      if (seconds >= 0 && seconds < 10) {
        seconds = `0${seconds}`
      }

      if (minutes >= 0 && minutes < 10) {
        minutes = `0${minutes}`
      }

      if (hours > 0 && hours < 10) {
        hours = `0${hours}`
      }

      if (hours) {
        result += hours + ':'
      }

      result += minutes + ':' + seconds

      this.prettyAudioCurrentTime = result
      this.setCountdown(this.prettyAudioCurrentTime)
    },

    changeCountdown (value) {
      const audio = this.$refs.audioElement

      if (!audio) {
        return '00:00'
      }

      let duration
      if (!value) {
        duration = parseInt(this.episodeDuration) - audio.currentTime
      } else {
        duration = value
      }

      let result = ''

      let seconds = Math.round(duration % 60)
      let hours = Math.floor(duration / 60 / 60)
      let minutes = Math.floor(duration / 60) - (hours * 60)

      if (seconds >= 0 && seconds < 10) {
        seconds = `0${seconds}`
      }

      if (minutes >= 0 && minutes < 10) {
        minutes = `0${minutes}`
      }

      if (hours > 0 && hours < 10) {
        hours = `0${hours}`
      }

      if (hours) {
        result += hours + ':'
      }

      result += minutes + ':' + seconds

      this.setCountdown(result)
    },

    soundTimelineClickHandler (event) {
      const volumeSlider = this.$refs.soundSlider
      const volumeSliderProgress = this.$refs.soundProgress
      const sliderWidth = window.getComputedStyle(volumeSlider).width
      const newVolume = event.offsetX / parseInt(sliderWidth)

      this.volume = newVolume
      if (this.gainNode) {
        this.gainNode.gain.value = newVolume
      }

      volumeSliderProgress.style.width = newVolume * 100 + '%'
    },

    async previousEpisodeClickHandler () {
      if (this.isFirstEpisodeInsideThePlaylist) {
        return
      }

      const prevEpisodeId = this.getPreviousEpisodeInsideThePlaylist()
      const prevEpisode = await this.getEpisodeById({
        eid: prevEpisodeId,
        slug: this.currentPodcast.slug
      })

      this.setCurrentPlayingEpisode(prevEpisode)
    },

    async nextEpisodeClickHandler () {
      if (this.isLatestEpisodeInsideThePlaylist) {
        return
      }

      const nextEpisodeId = this.getNextEpisodeInsideThePlaylist()
      const nextEpisode = await this.getEpisodeById({
        eid: nextEpisodeId,
        slug: this.currentPodcast.slug
      })

      this.setCurrentPlayingEpisode(nextEpisode)
    }
  }
}
</script>

<style lang="scss" scoped>
.audio-player {
  left: 0;
  bottom: 0;
  opacity: 0;
  width: 100%;
  position: fixed;
  z-index: 1000000;
  transform: translateY(200px);

  &_opened {
    opacity: 1;
    transform: translateY(0);
  }

  &__container {
    height: 100%;
    width: 1056px;
    display: grid;
    margin: 0 auto;
    grid-gap: $gap;
    padding: $gap 0;
    align-items: center;
    grid-template-columns: 64px 1fr 350px;

    @include display-less(big) {
      width: 100%;
      padding: $gap;
    }

    @include display-less(desktop) {
      grid-gap: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__box {
    width: 100%;
    height: 96px;
    position: relative;
    background-color: #6C26D6;

    @include display-less(big) {
      height: auto;
    }
  }

  &__cover {
    width: 64px;
    height: 64px;
    display: block;
    margin-right: $gap;
    border-radius: 8px;

    @include display-less(desktop) {
      display: none;
    }
  }

  &__main {
    display: flex;
    padding-right: $gap;
    flex-direction: column;
  }

  &__name {
    flex: 1;
    margin: 0;
    color: #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    position: relative;
    margin-bottom: $gap / 2;
    padding-bottom: $gap / 2;

    &:after {
      left: 0;
      z-index: 2;
      width: 32px;
      height: 1px;
      content: '';
      bottom: 0px;
      position: absolute;
      background-color: #fff;
    }

    span {
      font-size: 14px;
      font-weight: 700;
    }

    @include display-less(desktop) {
      padding-bottom: 0;
      &:after {
        display: none;
      }
    }
  }

  &__podcast {
    display: flex;
    align-items: center;

    @include display-less(desktop) {
      display: none;
    }

    &-cover {
      width: 20px;
      height: 20px;
      margin-right: $gap / 2;
    }

    &-name {
      color: #fff;
      font-size: 12px;
      font-weight: 500;

      strong {
        color: #fff;
        font-weight: 700;
      }
    }
  }

  &__controller {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-loading {
      margin: 2px;
      width: 28px;
      height: 28px;
      position: relative;
      border-radius: 28px;
      border: 1px solid #797088;

      /deep/ .loading-background {
        background: transparent;
      }

      /deep/ .loading-icon {
        &:after {
          width: 1em;
          height: 1em;
          top: calc(50% - .5em);
          left: calc(50% - .5em);
        }
      }
    }

    &-sound {
      z-index: 3;
      width: 40px;
      height: 24px;
      cursor: pointer;
      position: relative;
      padding-left: $gap;

      &-btn {
        width: 24px;
        height: 24px;
        outline: none;
      }

      &-slider {
        left: 0;
        width: 0;
        top: 50%;
        z-index: 2;
        height: 6px;
        margin-top: -10px;
        transition: .25s;
        position: absolute;
        box-sizing: content-box;
        background-color: #1C0C32;
        // box-shadow: 0 0 20px #000a;
        border-top: 7px solid #6C26D6;
        border-bottom: 7px solid #6C26D6;

        &-progress {
          width: 100%;
          height: 100%;
          background: #fff;
        }
      }

      @include display(desktop) {
        &:hover {
          .audio-player__controller-sound-slider {
            left: -186px;
            width: 186px;
          }
        }
      }
    }

    &-nav {
      display: flex;
      align-items: center;

      &-prev, &-next {
        width: 22px;
        height: 24px;
        outline: none;
      }

      &-play {
        width: 32px;
        height: 32px;
        outline: none;
        margin: 0 $gap;
      }

      &_disabled {
        opacity: .5;
      }
    }
  }

  &__controller-bar {
    width: 100%;
    display: flex;
    padding-left: $gap;
    align-items: center;

    span {
      color: #FFFFFF;
      line-height: 1;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: -0.3px;
    }
  }

  // Timeline bar
  &-timeline {
    height: 3px;
    width: 100%;
    margin: 0 6px;
    cursor: pointer;
    box-sizing: content-box;
    background-color: #1C0C32;
    border-top: 2px solid #6C26D6;
    border-bottom: 2px solid #6C26D6;

    &__progress {
      width: 0px;
      height: 3px;
      background-color: #fff;
    }
  }
}
</style>
