<template lang="pug">
  .audio-player(
    ref="htmlAudioPlayerContainer"
  )
    audio.audio-player__player(
      v-if="file || existingAudio"
      ref="htmlAudioPlayer"
      preload="none"
      controls
    )
      source(
        v-if="file"
        :src="fileUrl"
        :type="fileType"
      )
      source(
        v-else-if="existingAudio"
        :src="existingAudio"
        :type="existingAudioMimetype"
      )
</template>

<script>
export default {
  props: {
    existingAudio: {
      type: String,
      default: ''
    },

    file: {
      type: Object,
      default: () => null
    },

    newFileChangeIndicator: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      song: '/uploads/sandstorm.mp3'
    }
  },

  computed: {
    fileType () {
      if (!this.file) {
        return 'audio/mpeg'
      }

      // eslint-disable-next-line
      const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));
      const fileName = this.file.name
      const fileMimetype = this.file.type
      const fileIsM4A = fileMimetype.includes('m4a')
      const fileIsOGG = fileName.includes('.ogg') || fileName.includes('.OGG')

      if (isSafari && fileIsM4A) {
        return fileMimetype
      }

      if (isSafari && fileIsOGG) {
        return 'video/ogg'
      }

      return 'audio/mpeg'
    },
    existingAudioMimetype () {
      if (this.existingAudio) {
        const arrUrl = this.existingAudio.split('.')
        let ext = arrUrl[arrUrl.length - 1]
        if (ext === 'm4a' || ext === 'wma') {
          ext = 'x-' + ext
        }
        return 'audio/' + ext
      }
      return 'audio/mpeg'
    },

    fileUrl () {
      if (typeof this.file === 'string') {
        return this.file
      }
      if (typeof this.file === 'object') {
        return URL.createObjectURL(this.file)
      }
      return ''
    }
  },

  watch: {
    file: {
      deep: true,
      immediate: true,
      handler (val) {
        this.$nextTick(() => {
          const audioEl = this.$refs.htmlAudioPlayer
          const audioSourceEl = this.$refs.htmlAudioPlayer.children[0]

          if (!val) {
            if (this.existingAudio) {
              audioEl.load()
            }
            return
          }

          audioSourceEl.onerror = (event) => {
            this.$sentry.withScope((scope) => {
              scope.setExtra('File name', val.upload.filename)
              const message = 'Error with episode audio file selecting from dropzone'
              this.$sentry.captureException(new Error(message))
            })
            this.$emit('warning-message')
            this.$emit('remove-current-file')
          }

          audioEl.onloadedmetadata = (event) => {
            const duration = Math.ceil(event.target.duration) || null
            this.$emit('duration-given', duration)
          }

          audioEl.load()
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.audio-player {
  &__player {
    width: 100%;
    outline: none;
  }
}
</style>
