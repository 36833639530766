export default {
  podcasts: state => state.podcasts,

  isPodcastsLoading: state => state.isPodcastsLoading,

  podcastByPath: (state) => {
    return (path) => {
      return state.podcasts.find((podcast) => {
        return podcast.s3_path === path || podcast.slug === path
      })
    }
  },

  podcastByPid: (state) => {
    return (pid) => {
      return state.podcasts.find((podcast) => {
        return parseInt(podcast.id) === parseInt(pid)
      })
    }
  },

  podcastIsRedirected: (state) => {
    if (state.podcasts.length === 0) {
      return false
    }
    return state.podcasts[0].redirected === '1'
  }
}
