<template lang="pug">
  ul.ui-breadcrumbs
    template(
      v-for="(breadcrumb, index) in breadcrumbs"
    )
      li.ui-breadcrumbs__item(
        :key="index"
      )
        component(
          v-if="!breadcrumb.customEventOnClick"
          :is="!breadcrumb.current ? 'nuxt-link' : 'span'"
          :to="!breadcrumb.current ? breadcrumb.link : undefined"
          :class="!breadcrumb.current ? 'ui-breadcrumbs__link': 'ui-breadcrumbs__text'"
          v-html="prettifyText(breadcrumb.label)"
        )
        component(
          v-else
          :is="!breadcrumb.current ? 'span' : 'span'"
          :class="!breadcrumb.current ? 'ui-breadcrumbs__link': 'ui-breadcrumbs__text'"
          v-html="prettifyText(breadcrumb.label)"
          @click="$emit(breadcrumb.customEventOnClick)"
        )
</template>

<script>
export default {
  props: {
    breadcrumbs: {
      type: Array,
      required: true
    }
  },

  methods: {
    prettifyText (text) {
      let result = text || ''
      if (result && result.length >= 33) {
        result = text.slice(0, 33) + '...'
      }
      return result
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-breadcrumbs {
  display: inline-block;
  color: $colorTextAccent;

  &__item {
    position: relative;
    float: left;
  }

  &__link,
  &__text {
    color: #fff;
    display: block;
    cursor: pointer;
    font-size: 14px;
    font-weight: 600;
    line-height: 32px;
    background: #2e2042;
    padding: 0 $gap 0 $gap;

    &::after {
      top: 0;
      z-index: 1;
      content: '';
      width: 32px;
      right: -16px;
      height: 32px;
      position: absolute;
      background: #2e2042;
      transition: all 0.5s;
      border-radius: 50px 0 0 0;
      box-shadow: 3px 3px 0 0 #4d415e;
      transform: rotate(-45deg) scale(0.5) skew(-25deg, -25deg);
    }

    .ui-breadcrumbs__item:not(:first-child) & {
      padding-left: 23px;

      @include display-less(phablet) {
        padding-left: $gap;
      }
    }

    .ui-breadcrumbs__item:last-child & {
      &::after {
        box-shadow: none;
      }
    }

    @include display(tablet) {
      padding: 0 $gap 0 $gap;
      font-size: 14px;

      .ui-breadcrumbs__item:first-child & {
        padding-left: $gap;
      }
    }
  }

  &__link {
    text-decoration: none;
    transition: all 0.5s;

    &:hover,
    &:hover::after,
    .ui-breadcrumbs__item_active &,
    .ui-breadcrumbs__item_active &::after{
      background: $colorAccent;
      color: $colorLight;
    }
  }
}
</style>
