/*
 ** Core API endpoints:
 ** - GET /users/gdpr
 ** - GET /users/language
*/

import md5 from 'blueimp-md5'
import { config } from '@/config'

const cookiesDomain = config.baseUrl.replace('https://', '.')

export default {
  signInInitTokens ({ commit }, payload) {
    this.$cookiz.remove('RSS_IT')
    this.$cookiz.remove('RSS_AT')
    this.$cookiz.remove('RSS_EXPIRED_IN')

    this.$cookiz.set('RSS_IT', payload.idToken, {
      path: '/',
      secure: true,
      domain: cookiesDomain
    })
    this.$cookiz.set('RSS_AT', payload.accessToken, {
      path: '/',
      secure: true,
      domain: cookiesDomain
    })
    this.$cookiz.set('RSS_EXPIRED_IN', payload.expiredIn, {
      path: '/',
      secure: true,
      domain: cookiesDomain
    })

    commit('setToken', payload.idToken)
    commit('setAccessToken', payload.accessToken)

    if (payload.refreshToken && payload.refreshToken !== 'undefined') {
      // Remove cookie for old domain
      this.$cookiz.remove('RSS_RT')

      this.$cookiz.set('RSS_RT', payload.refreshToken, {
        path: '/',
        secure: true,
        domain: cookiesDomain
      })

      commit('setRefreshToken', payload.refreshToken)
    }
  },

  async gdprDataGet ({ state, commit, dispatch }, argIdToken) {
    const idToken = state.idToken || argIdToken
    let result

    try {
      result = await dispatch(
        'api/fetch',
        {
          token: idToken,
          endpoint: '/v2/users/gdpr'
        },
        { root: true }
      )
    } catch (err) {}

    if (!result) {
      return result
    }

    const gdprDataOfUser = result
    this.$cookiz.set('RSS_GDPR_TPART', gdprDataOfUser.gdpr_3rd_parties, { path: '/', secure: true })
    this.$cookiz.set('RSS_GDPR_MARKETING', gdprDataOfUser.gdpr_marketing, { path: '/', secure: true })
    this.$cookiz.set('RSS_GDPR_TERMS', gdprDataOfUser.gdpr_terms, { path: '/', secure: true })

    commit('setGdprData', gdprDataOfUser)
    return gdprDataOfUser
  },

  async getUserLanguage ({ state, dispatch }) {
    const idToken = state.idToken
    const result = await dispatch(
      'api/fetch',
      {
        token: idToken,
        endpoint: '/v2/users/language'
      },
      { root: true }
    )
    return result
  },

  async signOut ({ state, commit, dispatch }) {
    const idToken = state.idToken
    const requestData = {
      username: state.email
    }

    commit('setAuthLoadingStatus', true)

    let result

    try {
      await dispatch('api/fetch', {
        token: idToken,
        method: 'POST',
        data: requestData,
        endpoint: '/v2/auth/signout'
      }, { root: true })
      result = { success: true }
    } catch (error) {
      result = { success: false }
    }

    commit('setAuthLoadingStatus', false)

    this.$cookiz.remove('RSS_IT', {
      path: '/',
      secure: true,
      domain: cookiesDomain
    })
    this.$cookiz.remove('RSS_AT', {
      path: '/',
      secure: true,
      domain: cookiesDomain
    })
    this.$cookiz.remove('RSS_RT', {
      path: '/',
      secure: true,
      domain: cookiesDomain
    })

    commit('setToken', null)
    commit('setAccessToken', null)
    commit('setRefreshToken', null)

    commit('setLoginStatus', null)
    return result
  },

  /*
  ** Profile picture (Gravatar)
  */
  setUserProfilePictureUrls ({ state, commit }) {
    const email = state.email
    if (!email) { return }
    const emailHash = md5(email.toLowerCase().trim())

    const url = `https://www.gravatar.com/avatar/${emailHash}?d=${encodeURI('https://rss.com/images/no-avatar.png')}&s=72`
    const urlRetina = `https://www.gravatar.com/avatar/${emailHash}?d=${encodeURI('https://rss.com/images/no-avatar.png')}&s=144`

    commit('setAvatarPhotoUrl', {
      default: url,
      retina: urlRetina
    })
  }
}
