export default () => ({
  idToken: null,
  avatarPhotoUrl: {
    retina: '/images/no-avatar.png',
    default: '/images/no-avatar.png'
  },

  accessToken: null,
  refreshToken: null,
  isAuthLoading: false,
  isLoggedIn: false,
  userId: null,
  userSub: null,
  email: '',
  emailVerified: 0,
  gdprThirdParty: 0,
  gdprMarketing: 0,
  gdprTerms: 0,
  dashboardLanguage: null,
  currentCountry: null
})
