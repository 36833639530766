;(function (exports) {
  exports.config = {
    s3Url: process.env.VUE_AWS_S3_PATH || 'https://media-dev-rss-com.s3.amazonaws.com',
    baseUrl: process.env.VUE_APP_BASE_URL || 'https://rss.com',
    mediaUrl: process.env.VUE_AWS_CF_PATH || 'https://media.rss.com',
    staticUrl: process.env.VUE_APP_STATIC_URL || 'https://rss.com',
    apiUrl: process.env.VUE_APP_API_URL || 'https://api.rss.com',
    podvizUrl: process.env.VUE_APP_PODVIZ_URL || 'https://rss.com/podviz/',
    coreApiInternalUrl: process.env.VUE_APP_API_INTERNAL_URL || 'http://rss-core-api-prod.core-services-prod',
    imageResizerUrl: process.env.VUE_AWS_IMG_RESIZER_URL || 'https://img.rss.com',
    envName: process.env.VUE_ENV_NAME || 'undefined :(',
    envFullName: process.env.NODE_ENV_FULL || 'Production',
    supportLink: process.env.VUE_SUPPORT_LINK || 'https://help.rss.com/',
    mediaKitLink: process.env.VUE_MEDIA_KIT_LINK || 'https://mediakit.rss.com',
    googleTagManagerId: process.env.GOOGLE_TM_ID || 'GTM-MJMDPRR',
    googleRecaptchaApiKey: process.env.VUE_APP_RECAPTCHA_KEY || '',
    commitHash: process.env.COMMIT_HASH || null,
    podcornUrl: process.env.VUE_PODCORN_URL || 'https://podcorn.com/rss',
    analyticsUrl: process.env.VUE_ANALYTICS_URL || 'https://analytics.rss.com',
    dashboardAppUrl: process.env.DASHBOARD_APP_URL || 'https://dashboard.rss.com',
    publicApiUrl: process.env.PUBLIC_API_URL || 'https://apollo.rss.com',
    publicApiUrlInternal: process.env.PUBLIC_API_URL_INTERNAL || 'http://rss-public-api.core-services-prod',
    embeddedPlayerUrl: process.env.VUE_EMBEDDED_URL || 'https://player.rss.com',
    gtmEnvSettings: {
      auth: process.env.GTM_AUTH || 'eG1slwLxXT4Ot0vqraBpnw',
      preview: process.env.GTM_PREVIEW || 'env-62',
      cookies_win: process.env.GTM_COOKIES_WIN || 'x'
    },
    awsStatusUrl: process.env.AWS_STATUS_URL || 'https://s63ztm8kh7.execute-api.us-west-1.amazonaws.com',
    freshdeskSettings: {
      apiUrl: process.env.FRESHDESK_API_URL || 'https://podcastsupport.freshdesk.com',
      username: process.env.FRESHDESK_USERNAME || 'oLzJxDWGF2Uw20HTwBW9',
      password: process.env.FRESHDESK_PASSWORD || 'x'
    }
  }
  exports.variablesForEnv = {
    prod: {
      BUILD_ENV: 'prod',
      VUE_ENV_NAME: 'build',
      NODE_ENV: 'production',
      NODE_ENV_FULL: 'Production',
      VUE_AWS_S3_PATH: 'https://media-pro-rss-com.s3.amazonaws.com',
      VUE_AWS_CF_PATH: 'https://media.rss.com',
      VUE_APP_BASE_URL: 'https://rss.com',
      VUE_APP_STATIC_URL: 'https://rss.com',
      DASHBOARD_APP_URL: 'https://dashboard.rss.com',
      VUE_APP_API_URL: 'https://api.rss.com',
      VUE_APP_API_INTERNAL_URL: 'http://rss-core-api-prod.core-services-prod',
      VUE_APP_PODVIZ_URL: 'https://rss.com/podviz/',
      VUE_SUPPORT_LINK: 'https://help.rss.com',
      VUE_ANALYTICS_URL: 'https://stats-api.rss.com',
      VUE_AWS_IMG_RESIZER_URL: 'https://img.rss.com',
      VUE_PODCORN_URL: 'https://podcorn.com/rss',
      PUBLIC_API_URL: 'https://apollo.rss.com',
      PUBLIC_API_URL_INTERNAL: 'http://rss-public-api.core-services-prod',
      VUE_EMBEDDED_URL: 'https://player.rss.com',
      VUE_APP_RECAPTCHA_KEY: '6Lfn5rUUAAAAAD6QgOcdYwen-J6g95Zcx0-kS6Yx',
      GTM_AUTH: 'eG1slwLxXT4Ot0vqraBpnw',
      GTM_PREVIEW: 'env-62',
      GTM_COOKIES_WIN: 'x',
      AWS_STATUS_URL: 'https://s63ztm8kh7.execute-api.us-west-1.amazonaws.com',
      FRESHDESK_API_URL: 'https://podcastsupport.freshdesk.com'
    },
    dev: {
      BUILD_ENV: 'dev',
      NODE_ENV: 'production',
      NODE_ENV_FULL: 'Development',
      VUE_ENV_NAME: 'build-dev',
      VUE_AWS_S3_PATH: 'https://media-dev-rss-com.s3.amazonaws.com',
      VUE_AWS_CF_PATH: 'https://media.dev.rsspodcasting.com',
      VUE_APP_BASE_URL: 'https://dev.rsspodcasting.com',
      VUE_APP_STATIC_URL: 'https://dev.rsspodcasting.com',
      DASHBOARD_APP_URL: 'https://dashboard.dev.rsspodcasting.com',
      VUE_APP_API_URL: 'https://api.dev.rsspodcasting.com',
      VUE_APP_API_INTERNAL_URL: 'http://rss-core-api-dev.core-services-dev',
      VUE_SUPPORT_LINK: 'https://help.rss.com',
      VUE_ANALYTICS_URL: 'https://stats-api.dev.rsspodcasting.com',
      VUE_AWS_IMG_RESIZER_URL: 'https://images.dev.rsspodcasting.com',
      VUE_PODCORN_URL: 'https://marketing-staging.podcorn.fm/~~~~~~rss/',
      PUBLIC_API_URL: 'https://apollo.dev.rsspodcasting.com',
      PUBLIC_API_URL_INTERNAL: 'http://rss-public-api-dev.core-services-dev',
      VUE_EMBEDDED_URL: 'https://player.dev.rsspodcasting.com',
      VUE_APP_RECAPTCHA_KEY: '6LcM2AwbAAAAAKsSNpioKd8vCGsispqxWkcR5-w2',
      GTM_AUTH: 'xjxCyxSWpYIn1j0yIYPuhQ',
      GTM_PREVIEW: 'env-60',
      GTM_COOKIES_WIN: 'x',
      AWS_STATUS_URL: 'https://n7z4r5erb4.execute-api.us-west-1.amazonaws.com',
      FRESHDESK_API_URL: 'https://podcastsupport.freshdesk.com'
    },
    staging: {
      BUILD_ENV: 'staging',
      NODE_ENV: 'production',
      NODE_ENV_FULL: 'Staging',
      VUE_ENV_NAME: 'build-staging',
      VUE_AWS_S3_PATH: 'https://media-staging-rss-com.s3.amazonaws.com',
      VUE_AWS_CF_PATH: 'https://media.stg.rsspodcasting.com',
      VUE_APP_BASE_URL: 'https://stg.rsspodcasting.com',
      VUE_APP_STATIC_URL: 'https://stg.rsspodcasting.com',
      DASHBOARD_APP_URL: 'https://dashboard.stg.rsspodcasting.com',
      VUE_APP_API_URL: 'https://api.stg.rsspodcasting.com',
      VUE_APP_API_INTERNAL_URL: 'http://rss-core-api-stg.core-services-stg',
      VUE_SUPPORT_LINK: 'https://help.rss.com',
      VUE_ANALYTICS_URL: 'https://stats-api.stg.rsspodcasting.com',
      VUE_AWS_IMG_RESIZER_URL: 'https://images.stg.rsspodcasting.com',
      VUE_PODCORN_URL: 'https://marketing-staging.podcorn.fm/~~~~~~rss/',
      PUBLIC_API_URL: 'https://apollo.stg.rsspodcasting.com',
      PUBLIC_API_URL_INTERNAL: 'http://rss-public-api-stg.core-services-stg',
      VUE_EMBEDDED_URL: 'https://player.stg.rsspodcasting.com',
      VUE_APP_RECAPTCHA_KEY: '6LcM2AwbAAAAAKsSNpioKd8vCGsispqxWkcR5-w2',
      GTM_AUTH: '6_Fu1Qwj5fjEVPZsh_Ffww',
      GTM_PREVIEW: 'env-61',
      GTM_COOKIES_WIN: 'x',
      AWS_STATUS_URL: 'https://2lo9lymiy5.execute-api.us-west-1.amazonaws.com',
      FRESHDESK_API_URL: 'https://podcastsupport.freshdesk.com'
    }
  }
})(typeof exports === 'undefined' ? (this.utilities = {}) : exports)
