<template lang="pug">
  div(
    :class="avatarClass"
    :style="avatarStyles"
  )
    LazyLoadingImage(
      :rounded="true"
      :source="photo.default"
      :sourceset="photo.retina"
    )
</template>

<script>
import LazyLoadingImage from '@/components/ui/elements/LazyLoadingImage'

export default {
  components: {
    LazyLoadingImage
  },

  props: {
    photo: {
      type: Object,
      default: () => {}
    },
    name: {
      type: String,
      default: undefined
    },
    size: {
      type: [Number, Array],
      default: 32
    },
    border: {
      type: Number,
      default: null
    },
    clear: Boolean,
    rounded: Boolean,
    shadow: Boolean
  },

  computed: {
    avatarClass () {
      return {
        avatar: true
      }
    },

    avatarStyles () {
      let width = this.size
      let height = this.size
      let radius = '50%'

      if (typeof this.size === 'object') {
        width = this.size[0]
        height = this.size.length > 1 ? this.size[1] : width
      }

      if (this.rounded) {
        width >= height ? radius = width : radius = height
      }

      return {
        width: width + 'px',
        height: height + 'px',
        borderWidth: !this.border ? undefined : this.border + 'px',
        borderRadius: this.rounded ? radius + 'px' : undefined,
        boxShadow: this.shadow ? `0 ${width / 48}px ${width / 16}px rgba(0,0,0, 0.16)` : undefined
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.avatar {
  display: inline-block;
  overflow: hidden;
  position: relative;
  border-color: $colorLight;
  border-style: solid;
  border-radius: 5px;
  background: $colorBg;

  &__photo {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    vertical-align: top;
  }
}
</style>
