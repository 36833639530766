<template>
  <svg
    width="21"
    height="16"
    viewBox="0 0 21 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.72825 1.97625C9.7286 1.15838 8.80025 0.677002 8.13943 1.1589L1.979 5.65127V10.4203L8.14046 14.8565C8.80268 15.3333 9.72638 14.8545 9.72488 14.0385C9.70892 5.33898 9.72549 8.60364 9.72825 1.97625Z"
      fill="white"
    />
    <path
      d="M4.0775 4.10352H1C0.447715 4.10352 0 4.55123 0 5.10352V10.9008C0 11.4531 0.447715 11.9008 1 11.9008H4.0775V4.10352Z"
      fill="white"
    />
    <path
      d="M14.6887 15.806C14.4997 15.8025 14.3158 15.7444 14.1592 15.6386C14.0027 15.5328 13.8801 15.3839 13.8064 15.2098C13.7106 14.9788 13.7094 14.7194 13.8031 14.4874C13.8967 14.2555 14.0778 14.0696 14.3071 13.9699C15.0714 13.6416 15.7671 13.1725 16.3578 12.5869C16.9572 11.9983 17.4281 11.292 17.7408 10.5124C18.0946 9.71711 18.2735 8.85517 18.2654 7.98481C18.2783 7.12413 18.1076 6.27059 17.7647 5.48108C17.4519 4.70147 16.981 3.99512 16.3816 3.40657C15.7838 2.8185 15.0799 2.3492 14.3071 2.02356C14.076 1.91902 13.8946 1.72875 13.8012 1.49294C13.7077 1.25712 13.7096 0.994228 13.8064 0.759771C13.9109 0.528667 14.1012 0.34724 14.337 0.253805C14.5728 0.16037 14.8357 0.162242 15.0702 0.259025C17.0828 1.12903 18.6806 2.74401 19.5292 4.76573C19.9625 5.80073 20.1893 6.91047 20.1969 8.0325C20.1849 9.676 19.6934 11.2803 18.7827 12.6484C17.872 14.0166 16.5817 15.0891 15.0702 15.7344C14.9484 15.7813 14.8191 15.8055 14.6887 15.806Z"
      fill="white"
    />
    <path
      d="M12.8995 11.9237C12.7246 11.9258 12.5532 11.8744 12.4083 11.7763C12.2634 11.6783 12.1519 11.5384 12.0888 11.3752C12.042 11.2674 12.0172 11.1513 12.0159 11.0337C12.0146 10.9161 12.0369 10.7995 12.0813 10.6907C12.1257 10.5818 12.1915 10.4829 12.2746 10.3999C12.3578 10.3168 12.4568 10.2511 12.5657 10.2068C12.8563 10.0917 13.1171 9.91237 13.3287 9.68225C13.5646 9.4662 13.7446 9.1962 13.8533 8.89537C13.9891 8.59622 14.0543 8.26991 14.0441 7.94156C14.0258 7.67031 13.9613 7.40418 13.8533 7.15468C13.7382 6.86406 13.5588 6.60321 13.3287 6.39164C13.1171 6.16152 12.8563 5.98219 12.5657 5.86705C12.4568 5.82275 12.3578 5.75712 12.2746 5.67404C12.1915 5.59095 12.1257 5.49208 12.0813 5.38323C12.0369 5.27438 12.0146 5.15774 12.0159 5.04018C12.0172 4.92262 12.042 4.8065 12.0888 4.69864C12.1303 4.58758 12.1943 4.48631 12.2769 4.40123C12.3594 4.31614 12.4587 4.24908 12.5685 4.20428C12.6783 4.15947 12.7962 4.1379 12.9147 4.1409C13.0332 4.14391 13.1498 4.17143 13.2572 4.22174C13.7604 4.42766 14.2152 4.73627 14.5925 5.12785C14.9841 5.50512 15.2927 5.95991 15.4986 6.46317C15.7277 6.98098 15.8416 7.54235 15.8324 8.10848C15.842 8.68235 15.7282 9.25159 15.4986 9.77763C15.2927 10.2809 14.9841 10.7357 14.5925 11.113C14.2011 11.463 13.7484 11.7379 13.2572 11.9237H12.8995Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {}
</script>
