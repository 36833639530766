<template>
  <svg viewBox="0 0 32 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M28.5205 1.02263C29.8911 1.39379 30.9719 2.48079 31.3409 3.85941C32.0262 6.37805 31.9999 11.6274 31.9999 11.6274C31.9999 11.6274 31.9999 16.8503 31.3409 19.3689C30.9719 20.7476 29.8911 21.8346 28.5205 22.2057C26.0164 22.8685 15.9999 22.8685 15.9999 22.8685C15.9999 22.8685 6.00986 22.8685 3.47939 22.1792C2.10872 21.808 1.028 20.721 0.658976 19.3424C0 16.8503 0 11.6009 0 11.6009C0 11.6009 0 6.37805 0.658976 3.85941C1.028 2.48079 2.13508 1.36728 3.47939 0.996113C5.9835 0.333313 15.9999 0.333313 15.9999 0.333313C15.9999 0.333313 26.0164 0.333313 28.5205 1.02263ZM12.8099 6.7757L21.1394 11.6009L12.8099 16.4261V6.7757Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {}
</script>
