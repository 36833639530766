<template>
  <svg
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#fff"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.52 3.578A5.578 5.578 0 0 1 16.455 2a5.58 5.58 0 0 1 3.907 1.647A5.638 5.638 0 0 1 22 7.574a5.639 5.639 0 0 1-1.57 3.955l-.012.012-2.796 2.811a5.59 5.59 0 0 1-4.356 1.634 5.574 5.574 0 0 1-4.08-2.24.94.94 0 0 1 .188-1.313.93.93 0 0 1 1.305.19 3.735 3.735 0 0 0 2.72 1.493 3.713 3.713 0 0 0 2.904-1.089l2.79-2.805a3.759 3.759 0 0 0-.05-5.25 3.72 3.72 0 0 0-5.223-.05L12.22 6.52a.929.929 0 0 1-1.318-.004.94.94 0 0 1 .004-1.326l1.603-1.602.01-.01Z"
    />
    <path
      fill="#fff"
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.38 8.355a5.568 5.568 0 0 1 4.637.333c.704.387 1.317.921 1.797 1.567a.94.94 0 0 1-.188 1.312.93.93 0 0 1-1.305-.19 3.735 3.735 0 0 0-2.72-1.493 3.712 3.712 0 0 0-2.904 1.089l-2.79 2.805a3.76 3.76 0 0 0 .05 5.25 3.72 3.72 0 0 0 5.222.05l1.588-1.596a.93.93 0 0 1 1.319 0 .94.94 0 0 1 0 1.326l-1.594 1.602-.012.012A5.579 5.579 0 0 1 7.545 22a5.58 5.58 0 0 1-3.907-1.647A5.638 5.638 0 0 1 2 16.426a5.639 5.639 0 0 1 1.57-3.955l.012-.012 2.797-2.811a5.591 5.591 0 0 1 2-1.293Z"
    />
  </svg>
</template>

<script>
export default {}
</script>
