import Vue from 'vue'

import ArticleWrapper from './../components/ui/wrappers/Article.vue'

import Avatar from './../components/ui/elements/Avatar.vue'
import Progress from './../components/ui/elements/Progress.vue'
import Breadcrumbs from './../components/ui/elements/Breadcrumbs.vue'

import AudioPlayer from './../components/ui/controls/AudioPlayer.vue'

import ExplicitTag from './../components/ui/elements/tags/Explicit.vue'

// Registration of components

Vue.component('UiArticle', ArticleWrapper)

Vue.component('UiAvatar', Avatar)
Vue.component('UiProgress', Progress)
Vue.component('UiBreadcrumbs', Breadcrumbs)

Vue.component('UiAudioPlayer', AudioPlayer)

Vue.component('UiExplicitTag', ExplicitTag)
