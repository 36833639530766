<template>
  <component :is="icon" :class="classes" :component-id="componentId" />
</template>

<script>
import { v4 as uuidv4 } from 'uuid'

import Analytics from './icons/Analytics'
import AudioToVideo from './icons/AudioToVideo'
import Collaborators from './icons/Collaborators'
import Distribution from './icons/Distribution'
import EmbeddablePlayer from './icons/EmbeddablePlayer'
import Hosting from './icons/Hosting'
import Monetization from './icons/Monetization'
import Podcasting20 from './icons/Podcasting20'
import Privacy from './icons/Privacy'
import SocialMediaSharing from './icons/SocialMediaSharing'
import Transcription from './icons/Transcription'
import Website from './icons/Website'
import Arts from './icons/Arts'
import Comedy from './icons/Comedy'
import History from './icons/History'
import KidsAndFamily from './icons/KidsAndFamily'
import Leisure from './icons/Leisure'
import Music from './icons/Music'
import News from './icons/News'
import SocietyAndCulture from './icons/SocietyAndCulture'
import ArrowRight from './icons/ArrowRight'

export default {
  components: {
    Analytics,
    AudioToVideo,
    Collaborators,
    Distribution,
    EmbeddablePlayer,
    Hosting,
    Monetization,
    Podcasting20,
    Privacy,
    SocialMediaSharing,
    Transcription,
    Website,
    Arts,
    Comedy,
    History,
    KidsAndFamily,
    Leisure,
    Music,
    News,
    SocietyAndCulture,
    ArrowRight
  },

  props: {
    name: {
      type: String,
      required: true
    },
    size: {
      type: String,
      default: 'medium'
    }
  },

  computed: {
    icon () {
      return this.name.charAt(0).toUpperCase() + this.name.slice(1)
    },

    classes () {
      return ['commonIcon', `commonIcon_${this.size ? this.size : 'medium'}`]
    },

    componentId () {
      return uuidv4()
    }
  }
}
</script>

<style lang="scss" scoped>
.commonIcon {
  vertical-align: middle;

  &_medium {
    width: 24px;
    height: 24px;
  }

  &_large {
    width: 40px;
    height: 40px;
  }
}
</style>
