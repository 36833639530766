<template>
  <a
    :class="classes"
    :href="href"
    :target="target"
    :rel="rel"
    :disabled="disabled"
    @click="handleClick"
  >
    <slot />
  </a>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      default: null
    },
    target: {
      type: String,
      default: null
    },
    rel: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isUnstyled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classes () {
      return [
        { commonLink: !this.isUnstyled }
      ]
    }
  },

  methods: {
    handleClick (event) {
      this.$emit('click', event)
    }
  }
}
</script>

<style lang="scss" scoped>
.commonLink {
  color: $colorLightPurple;
  width: fit-content;

  &:hover {
    color: $colorLight;
    background-color: $colorLightPurple;
  }
}
</style>
