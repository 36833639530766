<template lang="pug">
.tooltip
  .tooltip-el(
    v-if="active"
    :class="['tooltip-el', `tooltip-el__p-${position}`, `tooltip-el__color-${color}`, `tooltip-el__align-${align}`]"
    @click="$emit('click')"
  )
    span.tooltip-el__text {{ content }}
  .tooltip-triangle(
    v-if="active"
    :class="['tooltip-triangle', `tooltip-triangle__p-${position}`, `tooltip-triangle__color-${color}`, `tooltip-triangle__align-${align}`]"
  )
</template>

<script>
export default {
  props: {
    content: {
      type: String,
      default: ''
    },
    active: {
      type: Boolean,
      default: false
    },
    align: {
      type: String,
      default: 'center',
      validator (value) {
        return ['center', 'right'].includes(value)
      }
    },
    color: {
      type: String,
      default: 'light',
      validator (value) {
        return ['light'].includes(value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.tooltip {
  z-index: 100;
  position: relative;

  &-el {
    color: #2D2D2D;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    bottom: 100%;
    margin-bottom: 30px;
    text-align: center;
    border-radius: 8px;
    position: absolute;
    padding: $gap * 0.75;
    left: 50%;

    &__color {
      &-light {
        background-color: #FFFFFF;
      }
    }

    &__align {
      &-right {
        margin-left: 100px;
        left: -367px;
        right: -10px;
      }

      &-center {
        left: 10px;
        right: 10px;
      }
    }
  }

  &-triangle {
    position: absolute;
    top: -30px;
    left: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 17px 12px 0 12px;
    &__color {
      &-light {
        border-color: #FFFFFF transparent transparent transparent;
      }
    }

    &__align {
      &-center {
        left: 50%;
      }
    }
  }
}
</style>
