<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.7778 7.11111V1.73768C12.7778 1.46546 13.107 1.32913 13.2994 1.52162L19.5896 7.81172C19.782 8.00421 19.6457 8.33333 19.3735 8.33333H14.0001C13.325 8.33333 12.7778 7.78613 12.7778 7.11111Z"
      :fill="`url(#${componentId}_paint0_linear_1972_21617)`"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 3.44444C3 2.09441 4.09441 1 5.44444 1H11.25C11.4188 1 11.5556 1.1368 11.5556 1.30556V7.11111C11.5556 8.46114 12.65 9.55556 14 9.55556H19.8056C19.9743 9.55556 20.1111 9.69236 20.1111 9.86111V20.5556C20.1111 21.9056 19.0167 23 17.6667 23H5.44445C4.09442 23 3 21.9056 3 20.5556V3.44444ZM6.66666 13.8333C6.66666 13.4958 6.94026 13.2222 7.27777 13.2222L14.6111 13.2222C14.9486 13.2222 15.2222 13.4958 15.2222 13.8333C15.2222 14.1708 14.9486 14.4445 14.6111 14.4445L7.27777 14.4445C6.94026 14.4445 6.66666 14.1708 6.66666 13.8333ZM7.27777 16.8889C6.94026 16.8889 6.66666 17.1625 6.66666 17.5C6.66666 17.8375 6.94026 18.1111 7.27777 18.1111L13.3889 18.1111C13.7264 18.1111 14 17.8375 14 17.5C14 17.1625 13.7264 16.8889 13.3889 16.8889L7.27777 16.8889Z"
      :fill="`url(#${componentId}_paint1_linear_1972_21617)`"
    />
    <defs>
      <linearGradient
        :id="`${componentId}_paint0_linear_1972_21617`"
        x1="26"
        y1="15.5"
        x2="14.1026"
        y2="-8.74885"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#4646FB" />
        <stop offset="0.5" stop-color="#BE4DFF" />
        <stop offset="1" stop-color="#BC9AFF" />
      </linearGradient>
      <linearGradient
        :id="`${componentId}_paint1_linear_1972_21617`"
        x1="20.5"
        y1="25"
        x2="2.5"
        y2="-3"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#4646FB" />
        <stop offset="0.5" stop-color="#BE4DFF" />
        <stop offset="1" stop-color="#BC9AFF" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    componentId: {
      type: String,
      default: ''
    }
  }
}
</script>
