export default function (ctx) {
  const i18nLocale = ctx.store.state.i18n.locale
  const appIsActive = ctx.store.state.app.active

  const locale = i18nLocale === 'en' ? '' : `/${i18nLocale}`

  if (
    !appIsActive &&
    !ctx.route.path.endsWith('/maintenance/') &&
    (
      !ctx.route.name ||
      (ctx.route.name && !ctx.route.name.startsWith('maintenance___'))
    )
  ) {
    return ctx.redirect(`${locale}/maintenance/`)
  }

  if (
    appIsActive &&
    ctx.route.path.endsWith('/maintenance/') &&
    ctx.route.name &&
    ctx.route.name.startsWith('maintenance___')
  ) {
    return ctx.redirect(`${locale}/`)
  }
}
