<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20 12C20 12.5523 19.5523 13 19 13L5 13C4.44772 13 4 12.5523 4 12C4 11.4477 4.44772 11 5 11L19 11C19.5523 11 20 11.4477 20 12Z"
      :fill="`url(#${componentId}_paint0_linear_2042_28040)`"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.2929 19.7071C10.9024 19.3166 10.9024 18.6834 11.2929 18.2929L17.5858 12L11.2929 5.70711C10.9024 5.31658 10.9024 4.68342 11.2929 4.29289C11.6834 3.90237 12.3166 3.90237 12.7071 4.29289L19.7071 11.2929C20.0976 11.6834 20.0976 12.3166 19.7071 12.7071L12.7071 19.7071C12.3166 20.0976 11.6834 20.0976 11.2929 19.7071Z"
      :fill="`url(#${componentId}_paint1_linear_2042_28040)`"
    />
    <defs>
      <linearGradient
        :id="`${componentId}_paint0_linear_2042_28040`"
        x1="31.1946"
        y1="7.30418"
        x2="11.5887"
        y2="-9.69268"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F87900" />
        <stop offset="0.51" stop-color="#FE4F45" />
        <stop offset="1" stop-color="#E419BA" />
      </linearGradient>
      <linearGradient
        :id="`${componentId}_paint1_linear_2042_28040`"
        x1="31.1946"
        y1="7.30418"
        x2="11.5887"
        y2="-9.69268"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F87900" />
        <stop offset="0.51" stop-color="#FE4F45" />
        <stop offset="1" stop-color="#E419BA" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    componentId: {
      type: String,
      default: ''
    }
  }
}
</script>
