<template>
  <div class="podcasts__container">
    <HeaderDropdown
      dropdown-id="podcasts"
      :left-offset="-100"
      :opened-dropdown-id="openedDropdownId"
      :trigger-text="$t('modules.navigation.podcasts')"
      @dropdownOpened="$emit('dropdownOpened', $event)"
    >
      <div class="podcasts__content-drop">
        <div class="podcasts__content-drop-links">
          <IconEntry
            icon-name="Music"
            :title="$t('modules.navigation.podcasts_dropdown.music')"
            :href="$locatedLink('/community/categories/music/')"
          />
          <IconEntry
            icon-name="Leisure"
            :title="$t('modules.navigation.podcasts_dropdown.leisure')"
            :href="$locatedLink('/community/categories/leisure/')"
          />
          <IconEntry
            icon-name="History"
            :title="$t('modules.navigation.podcasts_dropdown.history')"
            :href="$locatedLink('/community/categories/history/')"
          />
          <IconEntry
            icon-name="KidsAndFamily"
            :title="$t('modules.navigation.podcasts_dropdown.kids_and_family')"
            :href="$locatedLink('/community/categories/kids-and-family/')"
          />
          <IconEntry
            icon-name="Arts"
            :title="$t('modules.navigation.podcasts_dropdown.arts')"
            :href="$locatedLink('/community/categories/arts/')"
          />
          <IconEntry
            icon-name="SocietyAndCulture"
            :title="$t('modules.navigation.podcasts_dropdown.society_and_culture')"
            :href="$locatedLink('/community/categories/society-and-culture/')"
          />
          <IconEntry
            icon-name="News"
            :title="$t('modules.navigation.podcasts_dropdown.news')"
            :href="$locatedLink('/community/categories/news/')"
          />
          <IconEntry
            icon-name="Comedy"
            :title="$t('modules.navigation.podcasts_dropdown.comedy')"
            :href="$locatedLink('/community/categories/comedy/')"
          />
        </div>
        <div class="podcasts__content-drop-footer">
          <Link :href="$locatedLink('/community/')" class="podcasts__content-drop-footer-link" is-unstyled>
            {{ $t('modules.navigation.podcasts_dropdown.all_podcasts') }}
            <GradientIcon name="ArrowRight" />
          </Link>
        </div>
      </div>
    </HeaderDropdown>
  </div>
</template>

<script>
import IconEntry from '@/components/modules/header/items/IconEntry'
import HeaderDropdown from '@/components/modules/header/HeaderDropdown'
import Link from '@/components/common/Link/Link'
import GradientIcon from '@/components/common/GradientIcon/GradientIcon'

export default {
  components: {
    IconEntry,
    HeaderDropdown,
    Link,
    GradientIcon
  },

  props: {
    openedDropdownId: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.podcasts {
  &__content {
    &-drop {
      &-links {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: $gap;
        font-weight: 400;

        @include display-less(desktop) {
          grid-template-columns: 1fr;
          gap: 0;
        }
      }

      &-footer {
        margin-top: $gap;
        padding-top: $gap;
        border-top: 1px solid #422965;
        background: linear-gradient(135deg, rgba(248, 121, 0, 1) 0%, rgba(254, 79, 69, 1) 50%, rgba(228, 25, 186, 1) 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        @include display-less(desktop) {
          border-top: 1px solid #DFDFDF;
        }
        &-link {
          display: flex;
          flex-direction: row;
          align-items: center;
          text-decoration: none;
          font-size: 16px;
          line-height: 24px;
          font-weight: 700;
          gap: $gap * 0.5;
        }
      }
    }
  }
}
</style>
