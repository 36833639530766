<template lang="pug">
.scrolling(
  ref="scrollingArea"
)
  .scrolling__row(
    v-for="index in marqueeNeeded ? 2:1"
    ref="scrollingText"
    :key="index"
    :class="{'marquee-content': marqueeNeeded}"
    :style="{ '--space-between-marquee': `${spaceBetweenMarquee}px`, '--num-chars': rawValue.length}"
  )
    slot
</template>

<script>
export default {
  props: {
    rawValue: {
      type: String,
      required: true
    },
    isFullScreen: {
      type: Boolean,
      default: false
    },
    isChaptersViewOpen: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      marqueeNeeded: false,
      spaceBetweenMarquee: 50 // px
    }
  },

  watch: {
    rawValue () {
      this.$nextTick(() => {
        this.checkMarqueeNeeded()
      })
    },

    isFullScreen () {
      this.$nextTick(() => {
        this.checkMarqueeNeeded()
      })
    },

    isChaptersViewOpen () {
      this.$nextTick(() => {
        this.checkMarqueeNeeded()
      })
    }
  },

  mounted () {
    this.checkMarqueeNeeded()
    window.addEventListener('resize', this.checkMarqueeNeeded)
  },

  beforeDestroy () {
    window.addEventListener('resize', this.checkMarqueeNeeded)
  },

  methods: {
    checkMarqueeNeeded () {
      const scrollAreaWidth = this.$refs.scrollingArea ? this.$refs.scrollingArea.clientWidth : 1
      const scrollTextWidth = this.$refs.scrollingText ? this.$refs.scrollingText[0]?.clientWidth : 0

      if (scrollAreaWidth === 0 || scrollTextWidth === 0) {
        return
      }

      this.marqueeNeeded = scrollAreaWidth <= scrollTextWidth - this.spaceBetweenMarquee
    }
  }
}
</script>

<style lang="scss" scoped>
.marquee-content {
  animation: marquee calc(0.3s * var(--num-chars)) linear infinite;
  @keyframes marquee {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
}

.scrolling {
  display: flex;
  flex-direction: row;
  position: relative;
  overflow: hidden;

  &__row {
    padding-right: var(--space-between-marquee);
    text-decoration: none;
    display: inline-block;
    white-space: nowrap;
  }
}
</style>
