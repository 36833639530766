import { ToastProgrammatic as Toast } from 'buefy'

// types: white, black, light, dark, primary, info, success, warning, danger
// positions: top-right, top, top-left, bottom-right, bottom, bottom-left

export default function toast (message, type = 'info', position = 'bottom', duration = 4000) {
  Toast.open({
    duration,
    message,
    position: 'is-' + position,
    type: 'is-' + (type !== 'error' ? type : 'danger')
  })
}
