<template>
  <svg viewBox="0 0 24 24" :fill="color" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.3973 22.875C16.0188 22.875 16.5223 22.3715 16.5223 21.75L16.5223 16.5L21.75 16.5C22.3715 16.5 22.875 15.9965 22.875 15.375C22.875 14.7535 22.3715 14.25 21.75 14.25L15.3973 14.25C14.7759 14.25 14.2723 14.7535 14.2723 15.375L14.2723 21.75C14.2723 22.3715 14.7759 22.875 15.3973 22.875Z"
    />
    <path
      d="M8.64014 9.75L2.25012 9.75C1.62866 9.75 1.12512 9.24646 1.12512 8.625C1.12512 8.00354 1.62866 7.5 2.25012 7.5L7.51514 7.5L7.51514 2.25C7.51514 1.62854 8.01868 1.125 8.64014 1.125C9.26159 1.125 9.76514 1.62854 9.76514 2.25L9.76514 8.625C9.76514 9.24646 9.26159 9.75 8.64014 9.75Z"
    />
    <path
      d="M21.75 9.75L15.3973 9.75C14.7759 9.75 14.2723 9.24646 14.2723 8.625L14.2723 2.25C14.2723 1.62854 14.7759 1.125 15.3973 1.125C16.0188 1.125 16.5223 1.62854 16.5223 2.25L16.5223 7.5L21.75 7.5C22.3715 7.5 22.875 8.00354 22.875 8.625C22.875 9.24646 22.3715 9.75 21.75 9.75Z"
    />
    <path
      d="M7.51514 16.5L2.25012 16.5C1.62867 16.5 1.12512 15.9965 1.12512 15.375C1.12512 14.7535 1.62867 14.25 2.25012 14.25L8.64014 14.25C9.26159 14.25 9.76514 14.7535 9.76514 15.375L9.76514 21.75C9.76514 22.3715 9.26159 22.875 8.64014 22.875C8.01868 22.875 7.51514 22.3715 7.51514 21.75L7.51514 16.5Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'white'
    }
  }
}
</script>
