<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.7819 5.35388C1.2597 4.86903 1 4.26888 1 3.55481C1 2.84074 1.26108 2.21419 1.7819 1.72796C2.3041 1.24312 2.97629 1 3.79987 1C4.62345 1 5.26926 1.24312 5.79008 1.72796C6.31228 2.2128 6.57197 2.82268 6.57197 3.55481C6.57197 4.28694 6.31089 4.86903 5.79008 5.35388C5.26787 5.83872 4.60539 6.08184 3.79987 6.08184C2.99435 6.08184 2.3041 5.83872 1.7819 5.35388ZM6.13311 8.13513V23H1.43747V8.13513H6.13311Z"
      fill="white"
    />
    <path
      d="M21.7642 9.60355C22.7877 10.7149 23.2988 12.2403 23.2988 14.1825V22.7374H18.8393V14.7854C18.8393 13.806 18.5852 13.0447 18.0782 12.5029C17.5713 11.9611 16.888 11.6888 16.0325 11.6888C15.177 11.6888 14.4936 11.9597 13.9867 12.5029C13.4798 13.0447 13.2256 13.806 13.2256 14.7854V22.7374H8.73975V8.09345H13.2256V10.0356C13.6798 9.38822 14.2923 8.87698 15.0617 8.50049C15.8311 8.12401 16.6964 7.93646 17.6588 7.93646C19.3726 7.93646 20.742 8.49216 21.7642 9.60355Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {}
</script>
