<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g :clip-path="`url(#${componentId}_clip0_2042_27987)`">
      <path
        d="M13.3854 5.96191H10.6146L4 16.3714V22H20V16.3714L13.3854 5.96191Z"
        :fill="`url(#${componentId}_paint0_linear_2042_27987)`"
      />
      <path
        d="M22 20H2V24H22V20Z"
        :fill="`url(#${componentId}_paint1_linear_2042_27987)`"
        fill-opacity="0.8"
      />
      <g :filter="`url(#${componentId}_filter0_bi_2042_27987)`">
        <path
          d="M12 8C14.2091 8 16 6.20914 16 4C16 1.79086 14.2091 0 12 0C9.79086 0 8 1.79086 8 4C8 6.20914 9.79086 8 12 8Z"
          :fill="`url(#${componentId}_paint2_linear_2042_27987)`"
          fill-opacity="0.8"
        />
      </g>
    </g>
    <defs>
      <filter
        :id="`${componentId}_filter0_bi_2042_27987`"
        x="7.47778"
        y="-0.52222"
        width="9.04444"
        height="9.04444"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.26111" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_2042_27987"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_2042_27987"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.047683" dy="0.0238415" />
        <feGaussianBlur stdDeviation="0.119208" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2042_27987"
        />
      </filter>
      <linearGradient
        :id="`${componentId}_paint0_linear_2042_27987`"
        x1="20.3636"
        y1="22.3256"
        x2="9.99482"
        y2="0.205412"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#4646FB" />
        <stop offset="0.5" stop-color="#BE4DFF" />
        <stop offset="1" stop-color="#BC9AFF" />
      </linearGradient>
      <linearGradient
        :id="`${componentId}_paint1_linear_2042_27987`"
        x1="22.4545"
        y1="24.3636"
        x2="21.7422"
        y2="17.2401"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#4646FB" />
        <stop offset="0.5" stop-color="#BE4DFF" />
        <stop offset="1" stop-color="#BC9AFF" />
      </linearGradient>
      <linearGradient
        :id="`${componentId}_paint2_linear_2042_27987`"
        x1="16.1818"
        y1="8.72727"
        x2="10.426"
        y2="-2.78442"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#4646FB" />
        <stop offset="0.5" stop-color="#BE4DFF" />
        <stop offset="1" stop-color="#BC9AFF" />
      </linearGradient>
      <clipPath :id="`${componentId}_clip0_2042_27987`">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    componentId: {
      type: String,
      default: ''
    }
  }
}
</script>
