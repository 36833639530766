<template lang="pug">
.tooltip
  .tooltip__screen(
    v-if="isRendered"
    @click="contentClicked"
    :class="{'only-mobile': onlyMobile, 'only-desktop': onlyDesktop}"
  )
  .tooltip__container
    FingerClick.tooltip__container-animation(
      v-if="isRendered"
      :class="{'only-mobile': onlyMobile, 'only-desktop': onlyDesktop}"
    )
    Tooltip.tooltip__container-el(
      :active="isRendered"
      :content="label"
      :color="color"
      :align="align"
      :class="{'only-mobile': onlyMobile, 'only-desktop': onlyDesktop}"
    )
    .tooltip__container-content(
      @click="contentClicked"
      :class="{'tooltip__container-content-render': isRendered}"
    )
      slot
</template>

<script>
import { mapActions } from 'vuex'
import Cookies from 'js-cookie'
import Tooltip from '@/components/ui/elements/Tooltip.vue'
import FingerClick from '@/components/animations/finger-click/FingerClick.vue'

export default {
  components: {
    Tooltip,
    FingerClick
  },
  props: {
    label: {
      type: String,
      required: true
    },
    tutorialId: {
      type: String,
      required: true
    },
    align: {
      type: String,
      default: 'center',
      validator (value) {
        return ['center', 'right'].includes(value)
      }
    },
    color: {
      type: String,
      default: 'light',
      validator (value) {
        return ['light'].includes(value)
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    onlyMobile: {
      type: Boolean,
      default: false
    },
    onlyDesktop: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      active: true
    }
  },

  computed: {
    isRendered () {
      return this.active && !this.disabled
    }
  },

  mounted () {
    if (!this.disabled) {
      const cookie = Cookies.get(this.tutorialId)
      this.active = !cookie
    }

    if (this.isRendered) {
      this.addTooltipId(this.tutorialId)
    }
  },

  methods: {
    ...mapActions('player', ['addTooltipId', 'removeTooltipId']),
    contentClicked () {
      if (this.active && !this.disabled) {
        Cookies.set(this.tutorialId, true)
        this.removeTooltipId(this.tutorialId)
        this.active = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.only-mobile {
  display: none;
  @include display-less(phablet) {
    display: block;
  }
}

.only-desktop {
  display: block;
  @include display-less(phablet) {
    display: none;
  }
}

.tooltip {
  position: relative;
  &__screen {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
  }

  &__container {
    &-animation {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 186px;
      height: 186px;
      pointer-events: none;
      z-index: 110;
    }

    &-el {
      position: relative;
    }

    &-content {
      position: relative;
      width: 100%;

      &-render {
        z-index: 110;
      }
    }
  }

}
</style>
