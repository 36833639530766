<template>
  <svg
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.6704 11.7162L0.529785 0.533203C0.529785 17.4245 0.529785 9.14576 0.529785 22.9325L19.6704 11.7162Z"
      fill="white"
    />
    <path
      d="M22.9296 0.533691H19.3337V22.933H22.9296V0.533691Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {}
</script>
