<template>
  <svg
    class="spinner"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22 44C34.1503 44 44 34.1503 44 22C44 9.84974 34.1503 0 22 0C9.84974 0 0 9.84974 0 22C0 34.1503 9.84974 44 22 44ZM22 41.9571C33.022 41.9571 41.9571 33.022 41.9571 22C41.9571 10.978 33.022 2.04286 22 2.04286C10.978 2.04286 2.04286 10.978 2.04286 22C2.04286 33.022 10.978 41.9571 22 41.9571Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.2528 11.7175C22.2411 11.322 24.302 11.5249 26.175 12.3007C28.0479 13.0765 29.6487 14.3903 30.775 16.0759C31.9013 17.7615 32.5024 19.7433 32.5024 21.7705C32.5024 22.4609 31.9428 23.0205 31.2524 23.0205C30.5621 23.0205 30.0024 22.4609 30.0024 21.7705C30.0024 20.2377 29.5479 18.7393 28.6963 17.4648C27.8448 16.1904 26.6344 15.197 25.2182 14.6104C23.8021 14.0239 22.2438 13.8704 20.7405 14.1694C19.2371 14.4685 17.8562 15.2066 16.7724 16.2904C15.6885 17.3743 14.9504 18.7552 14.6514 20.2586C14.3523 21.7619 14.5058 23.3202 15.0924 24.7363C15.679 26.1524 16.6723 27.3628 17.9468 28.2144C19.2213 29.066 20.7196 29.5205 22.2524 29.5205C22.9428 29.5205 23.5024 30.0802 23.5024 30.7705C23.5024 31.4609 22.9428 32.0205 22.2524 32.0205C20.2252 32.0205 18.2435 31.4194 16.5578 30.2931C14.8722 29.1668 13.5585 27.566 12.7827 25.693C12.0069 23.8201 11.8039 21.7591 12.1994 19.7708C12.5949 17.7825 13.5711 15.9562 15.0046 14.5227C16.4381 13.0892 18.2645 12.113 20.2528 11.7175Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.spinner{
  animation: loading-spinner 1s linear infinite;

  @keyframes loading-spinner{
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}
</style>
