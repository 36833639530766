<template lang="pug">
  .layout
    IeUsersMessage

    main.layout__main
      nuxt

    Footer

    Cookies

    NoConnectionNotification

    BLoading(
      :active="isAuthLoading"
      :can-cancel="false"
      :is-full-page="true"
    )
</template>

<script>
import { mapState } from 'vuex'

import Cookies from '@/components/shared/Cookies'
import Header from '@/components/modules/header'
import Footer from '@/components/modules/footer'
import IeUsersMessage from '@/components/shared/IeUsersMessage'
import NoConnectionNotification from '@/components/shared/content/no-connection-notification'

export default {
  components: {
    Cookies,
    Header,
    Footer,
    IeUsersMessage,
    NoConnectionNotification
  },

  data () {
    return {
      scrollPosition: 0
    }
  },

  computed: {
    ...mapState('auth', ['isAuthLoading'])
  },

  mounted () {
    this.handleResize()

    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleResize)

    this.handleScroll()
  },

  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleResize)

    this.$store.dispatch('window/setScrollPosition', 0)
  },

  methods: {
    handleScroll () {
      this.scrollPosition = window.scrollY
      this.$store.dispatch('window/setScrollPosition', this.scrollPosition)
    },

    handleResize () {
      this.$store.dispatch('window/setScreen', window.innerWidth)
    }
  }
}
</script>

<style lang="scss">
@import '@rss/front-end_ui/dist/style/main.css';
@import '~/assets/styles/_bulma.scss';
@import '~/assets/styles/_base.scss';
@import '~/assets/styles/_content.scss';
</style>

<style lang="scss" scoped>
.layout {
  display: flex;
  min-height: 100vh;
  background: #1c0c32;
  flex-direction: column;
  padding-top: $headerHeightMobile;

  @include display(desktop) {
    padding-top: $headerHeight;
  }

  &__main {
    flex: 1;
    display: flex;
    background: #1c0c32;
    flex-direction: column;
    justify-content: center;
  }
}
</style>
