export default () => ({
  currentPodcast: null,
  currentEpisode: null,
  currentEpisodeId: null,
  chapters: [],
  currentTime: null,
  skipTo: null,
  saveCurrentTime: false,
  countdown: '',
  status: 'stopped',
  playlist: [],
  tooltipsId: [],
  loading: false,
  volume: 1
})
