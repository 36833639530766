<template lang="pug">
  div(
    :class="['ui-progress', { 'ui-progress_rounded': rounded }]"
  )
    .ui-progress__line(
      :style="background"
    )
      .ui-progress__now(
        :style="lineStyles"
      )
</template>

<script>
import { hexToRgb } from '@/lib/utils'
import { getGlobalColor } from '@/lib/vars'

export default {
  props: {
    value: {
      type: [Number, String],
      default: 0
    },
    percent: {
      type: [Number, String],
      default: 0
    },
    color: {
      type: String,
      default: getGlobalColor('primary')
    },
    rainbow: Boolean,
    rounded: Boolean
  },

  computed: {
    lineStyles () {
      return {
        width: (this.value || this.percent) + '%',
        background: !this.rainbow ? this.color : 'linear-gradient(90deg, #f8b700 0%, #f88e00 100%)'
      }
    },

    background () {
      return {
        background: !this.rainbow ? hexToRgb(this.color, 0.2) : 'transparent'
      }
    }
  }
}
</script>

<style lang="scss">
.ui-progress {
  color: $colorTextSecondary;
  font-size: $fontSizeSmall;

  &__line {
    overflow: hidden;
    position: relative;
    height: 4px;
    background: $colorBorder;

    .ui-progress_rounded & {
      border-radius: 8px;
    }
  }

  &__now {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border-radius: inherit;
    transition: width .2s ease-in-out;
  }
}
</style>
