<template lang="pug">
.full-player
  .full-player__blur(
    v-if="isBlurRendered"
  )
  .full-player__container(
    :class="{'full-player__container-chapters-open': isChaptersViewOpen}"
  )
    .full-player__container-close(@click="$emit('fullScreenChange', false)")
      CaretDownIcon
    .full-player__container-image(
      :class="{'full-player__container-image-small': isChaptersViewOpen}"
    )
      img(
        :src="chapterCoverUrl.default"
        :srcset="chapterCoverUrl.retina + ' 2x'"
      )
    Chapters.full-player__container-chapters(
      v-if="episodeHasChapters"
      :isChaptersViewOpen="isChaptersViewOpen"
    )

  Player.full-player__player(
    :isFullScreen="isFullScreen"
    :isChaptersViewOpen="isChaptersViewOpen"
    :forceShowPlayer="isBlurRendered"
    @chaptersViewChange="(value) => isChaptersViewOpen = value"
    @fullScreenChange="(value) => $emit('fullScreenChange', value)"
  )
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { getResizedImageUrl } from '@/lib/utils'

import CaretDownIcon from '@/components/icons/dashboard/CaretDown.vue'
import Chapters from '@/components/modules/audio-player/full-screen-player/Chapters.vue'
import Player from '@/components/modules/audio-player/full-screen-player/Player.vue'

export default {
  components: {
    Chapters,
    Player,
    CaretDownIcon
  },

  props: {
    isFullScreen: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isChaptersViewOpen: false
    }
  },

  computed: {
    ...mapState('player', ['currentEpisode', 'currentPodcast', 'currentTime', 'chapters', 'tooltipsId']),
    ...mapGetters('player', ['getCurrentChapter']),

    podcastSlug () {
      return this.currentPodcast.slug.toLowerCase()
    },

    podcastCoverUrl () {
      if (!this.currentPodcast.cover) {
        return {
          default: '/images/no-cover.png',
          retina: '/images/no-cover.png'
        }
      }

      const filename = this.currentPodcast.cover
      if (filename === 'no-cover-1400.jpg') {
        return {
          default: this.currentPodcast.cover,
          retina: this.currentPodcast.cover
        }
      }

      const cover = getResizedImageUrl(1000, this.podcastSlug, filename)
      return cover
    },

    episodeCoverUrl () {
      if (!this.currentEpisode.episode_cover) {
        if (this.podcastCoverUrl) {
          return this.podcastCoverUrl
        } else {
          return {
            default: '/images/no-cover.png',
            retina: '/images/no-cover.png'
          }
        }
      }
      const filename = this.currentEpisode.episode_cover
      if (filename === 'no-cover-1400.jpg') {
        return {
          default: this.currentEpisode.episode_cover,
          retina: this.currentEpisode.episode_cover
        }
      }

      const cover = getResizedImageUrl(1000, this.podcastSlug, filename)
      return cover
    },

    chapterCoverUrl () {
      const chapter = this.getCurrentChapter()
      if (!chapter || !chapter.img) {
        if (this.episodeCoverUrl) {
          return this.episodeCoverUrl
        } else {
          return {
            default: '/images/no-cover.png',
            retina: '/images/no-cover.png'
          }
        }
      }
      const slicedUrl = chapter.img.split('/')
      const filename = slicedUrl[slicedUrl.length - 1]
      if (filename === 'no-cover-1400.jpg') {
        return {
          default: this.currentEpisode.episode_cover,
          retina: this.currentEpisode.episode_cover
        }
      }

      const cover = getResizedImageUrl(1000, this.podcastSlug, filename)
      return cover
    },

    episodeHasChapters () {
      return this.chapters.length > 0
    },

    isBlurRendered () {
      return this.tooltipsId.includes('tutorialPlayerChapters')
    }
  },

  watch: {
    chapters (value) {
      if (value.length <= 0) {
        this.isChaptersViewOpen = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.full-player {
  color: #FFFFFF;
  background: rgb(124, 72, 215);
  background: linear-gradient(180deg, #7c48d7 0%, #1d0d33 100%);
  height: 100%;
  width: 100%;

  @include display-less(phablet) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__blur {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(3px);
  }

  &__container {
    display: flex;
    flex-direction: row;
    gap: $gap * 2.5;
    padding: $gap * 2.5;
    height: 100%;

    @include display-less(phablet) {
      flex-direction: column;
      gap: $gap * 1.5;
      padding: $gap * 1.5 $gap;
      overflow: auto;
      padding-bottom: 0;
    }

    &-chapters-open {
      @include display-less(phablet) {
        padding-bottom: 0;
      }
    }

    &-close {
      display: none;
      @include display-less(phablet) {
        display: block;
        width: 24px;
        height: 24px;
        cursor: pointer;
      }
    }
    &-image {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: calc(100vh - 80px);
      width: 100%;
      height: 100%;
      overflow: hidden;

      > img {
        border-radius: 4px;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
      }

      @include display-less(phablet) {
        max-width: 100%;
      }

      &-small {
        @include display-less(phablet) {
          max-width: 68px;
          max-height: 68px;
        }
      }
    }
    &-chapters {
      flex-shrink: 3;
      min-width: 400px;
      overflow: hidden;
      max-height: calc(100vh - 185px);
      @include display-less(desktop) {
        min-width: 250px;
      }

      @include display-less(phablet) {
        min-width: unset;
      }
    }
  }

  &__player {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 100;

    @include display-less(phablet) {
      position: static;
    }
  }
}
</style>
