<template lang="pug">
.player
  .player__container(
    :class="{'player__container-hidden': isHidden, 'player__container-chapters-open': isChaptersViewOpen}"
  )
    .player__container-background
    Info(
      :isChaptersViewOpen="isChaptersViewOpen"
      :isFullScreen="isFullScreen"
    )

    Controller.player__container-controller(
      :isFullScreen="isFullScreen"
      @playPauseClick="playPause"
    )

    Settings.player__container-settings(
      :isChaptersViewOpen="isChaptersViewOpen"
      :isFullScreen="isFullScreen"
      @chaptersViewChange="(value) => $emit('chaptersViewChange', value)"
      @fullScreenChange="(value) => {$emit('fullScreenChange', value)}"
    )
</template>

<script>
import { mapState, mapActions } from 'vuex'

import Info from '@/components/modules/audio-player/Info.vue'
import Controller from '@/components/modules/audio-player/Controller.vue'
import Settings from '@/components/modules/audio-player/Settings.vue'

export default {
  components: {
    Info,
    Controller,
    Settings
  },
  props: {
    isFullScreen: {
      type: Boolean,
      default: false
    },
    isChaptersViewOpen: {
      type: Boolean,
      default: false
    },
    forceShowPlayer: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isMouseMoving: true,
      timeout: null
    }
  },

  computed: {
    ...mapState('player', ['status']),

    isHidden () {
      return !this.isMouseMoving & !this.forceShowPlayer
    }
  },

  mounted () {
    window.addEventListener('mousemove', this.hideWhenMouseStops)
  },

  beforeDestroy () {
    window.removeEventListener('mousemove', this.hideWhenMouseStops)
  },

  methods: {
    ...mapActions('player', ['setPlayerStatus']),

    playPause () {
      if (this.status === 'paused') {
        this.setPlayerStatus('playing')
      } else {
        this.setPlayerStatus('paused')
      }
    },

    hideWhenMouseStops () {
      this.isMouseMoving = true
      if (this.timeout) {
        clearTimeout(this.timeout)
      }

      this.timeout = setTimeout(() => {
        this.isMouseMoving = false
      }, 1500)
    }
  }
}
</script>

<style lang="scss" scoped>
.player {
  &__container {
    height: 105px;
    background-color: rgba(59, 59, 59, 0.3);
    transition: transform 0.5s ease;
    padding: $gap * 0.5 $gap * 2.5 $gap $gap * 2.5;
    display: flex;
    flex-direction: column;
    gap: $gap * 0.5;
    position: relative;

    @include display-less(phablet) {
      height: fit-content;
      background-color: transparent;
      backdrop-filter: none;
      gap: 0;
      padding: $gap * 1.5 $gap $gap * 2 $gap;
      transition: none;
    }

    &-hidden:not(:hover) {
      transform: translate(0, 150px);
      @include display-less(phablet) {
        transform: none;
        transition: none;
      }
    }

    &-chapters-open {
      @include display-less(phablet) {
        padding-top: 0;
      }
    }

    &-background {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      backdrop-filter: blur(6px);
      z-index: -5;
    }

    &-controller {
      @include display-less(phablet) {
        margin-top: $gap * 2;
        margin-bottom: $gap * 1.5;
      }
    }

    &-settings {
      position: absolute;
      bottom: $gap;
      right: $gap * 2.5;

      @include display-less(phablet) {
        position: static;
        margin-left: auto;
      }
    }
  }
}
</style>
