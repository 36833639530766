<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g :clip-path="`url(#${componentId}_clip0_1972_21660)`">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 4.35019C0 2.6105 1.4103 1.2002 3.15 1.2002H20.85C22.5897 1.2002 24 2.6105 24 4.3502V19.2002C24 20.9399 22.5897 22.3502 20.85 22.3502H3.15C1.4103 22.3502 0 20.9399 0 19.2002V4.35019ZM6 5.7002C6 6.52862 5.32843 7.2002 4.5 7.2002C3.67157 7.2002 3 6.52862 3 5.7002C3 4.87177 3.67157 4.2002 4.5 4.2002C5.32843 4.2002 6 4.87177 6 5.7002ZM8.39998 4.65018C7.82008 4.65018 7.34998 5.12028 7.34998 5.70018C7.34998 6.28008 7.82008 6.75018 8.39998 6.75018H19.95C20.5299 6.75018 21 6.28008 21 5.70018C21 5.12028 20.5299 4.65018 19.95 4.65018H8.39998Z"
        :fill="`url(#${componentId}_paint0_linear_1972_21660)`"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0 4.3502C0 2.6105 1.4103 1.2002 3.15 1.2002H20.85C22.5897 1.2002 24 2.6105 24 4.3502V10.2002H0V4.3502ZM6 5.7002C6 6.52862 5.32843 7.2002 4.5 7.2002C3.67157 7.2002 3 6.52862 3 5.7002C3 4.87177 3.67157 4.2002 4.5 4.2002C5.32843 4.2002 6 4.87177 6 5.7002ZM8.39998 4.65018C7.82008 4.65018 7.34998 5.12028 7.34998 5.70018C7.34998 6.28008 7.82008 6.75018 8.39998 6.75018H19.95C20.5299 6.75018 21 6.28008 21 5.70018C21 5.12028 20.5299 4.65018 19.95 4.65018H8.39998Z"
        :fill="`url(#${componentId}_paint1_linear_1972_21660)`"
        fill-opacity="0.6"
      />
    </g>
    <defs>
      <linearGradient
        :id="`${componentId}_paint0_linear_1972_21660`"
        x1="0"
        y1="22.3502"
        x2="20.9821"
        y2="-1.45931"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFB199" />
        <stop offset="1" stop-color="#FF0844" />
      </linearGradient>
      <linearGradient
        :id="`${componentId}_paint1_linear_1972_21660`"
        x1="12"
        y1="0.000182514"
        x2="20.9172"
        y2="7.78251"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFB199" />
        <stop offset="1" stop-color="#FF0844" />
      </linearGradient>
      <clipPath :id="`${componentId}_clip0_1972_21660`">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    componentId: {
      type: String,
      default: ''
    }
  }
}
</script>
