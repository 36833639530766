<template>
  <svg
    viewBox="0 0 30 30"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27.272 2.57839V6.91845L26.7332 6.2001C25.1009 4.02366 22.8842 2.33014 20.3184 1.33176C16.9728 0.0299906 13.266 0.000430256 9.90015 1.24868C6.53427 2.49693 3.74308 4.93629 2.05531 8.10468C0.367539 11.2731 -0.0995911 14.9504 0.74241 18.4402C1.58441 21.9299 3.67706 24.9897 6.62395 27.0398C9.57084 29.09 13.1673 29.9881 16.7321 29.5642C20.2968 29.1402 23.5823 27.4235 25.9661 24.7394C28.2836 22.1299 29.5925 18.7822 29.6635 15.2995L27.2764 15.3003L27.2737 15.3069C27.2014 18.2022 26.1074 20.9826 24.1799 23.153C22.1843 25.3999 19.434 26.8369 16.4499 27.1918C13.4658 27.5467 10.4552 26.7949 7.98831 25.0787C5.52143 23.3625 3.76966 20.8011 3.06481 17.8798C2.35996 14.9585 2.751 11.8802 4.16385 9.22788C5.5767 6.57558 7.91323 4.53357 10.7308 3.48865C13.5485 2.44372 16.6515 2.46847 19.4521 3.55819C21.7789 4.46358 23.7627 6.05186 25.1532 8.09717L25.4711 8.56478L21.2856 8.56478V10.9593H29.6666V2.57839H27.272Z"
      fill="white"
    />
    <path
      d="M29.6635 15.2995L29.6656 15.2988L29.6644 15.2967L29.6636 15.2935L29.6635 15.2995Z"
      fill="white"
    />
    <path
      d="M10.1085 20.8489C9.31578 20.8489 8.61147 20.713 7.99561 20.4411C7.38303 20.1659 6.90148 19.7876 6.55096 19.306C6.20044 18.8245 6.02355 18.2709 6.02027 17.6452H8.77199C8.77526 17.8384 8.83423 18.0121 8.94888 18.166C9.06682 18.3167 9.22733 18.4346 9.43043 18.5198C9.63354 18.605 9.86612 18.6476 10.1282 18.6476C10.3804 18.6476 10.6032 18.6033 10.7965 18.5149C10.9897 18.4232 11.1404 18.2971 11.2485 18.1365C11.3566 17.976 11.409 17.7926 11.4058 17.5862C11.409 17.3831 11.3468 17.2029 11.2191 17.0457C11.0946 16.8884 10.9193 16.7656 10.6933 16.6771C10.4672 16.5887 10.2068 16.5445 9.91198 16.5445H8.88992V14.6576H9.91198C10.1839 14.6576 10.423 14.6134 10.6294 14.5249C10.8391 14.4365 11.0012 14.3136 11.1159 14.1564C11.2338 13.9991 11.2911 13.819 11.2878 13.6159C11.2911 13.4193 11.2436 13.2457 11.1453 13.095C11.0471 12.9443 10.9095 12.8264 10.7326 12.7412C10.559 12.656 10.3575 12.6135 10.1282 12.6135C9.87923 12.6135 9.65647 12.6577 9.45992 12.7461C9.26664 12.8346 9.11431 12.9574 9.00294 13.1147C8.89156 13.2719 8.83423 13.4521 8.83095 13.6552H6.21682C6.2201 13.0393 6.3888 12.4955 6.72294 12.0238C7.06035 11.5521 7.52225 11.1819 8.10863 10.9133C8.69501 10.6447 9.36819 10.5104 10.1282 10.5104C10.8718 10.5104 11.5286 10.6365 12.0986 10.8887C12.6719 11.141 13.119 11.4882 13.4401 11.9304C13.7644 12.3694 13.9249 12.8722 13.9216 13.439C13.9282 14.0024 13.7349 14.4643 13.3418 14.8247C12.952 15.185 12.4573 15.3979 11.8578 15.4634V15.5421C12.6703 15.6305 13.2812 15.8795 13.6907 16.289C14.1002 16.6952 14.3016 17.2062 14.2951 17.8221C14.2984 18.4117 14.1215 18.9342 13.7644 19.3896C13.4106 19.8449 12.9176 20.202 12.2853 20.4608C11.6564 20.7196 10.9308 20.8489 10.1085 20.8489Z"
      fill="white"
    />
    <path
      d="M19.4419 20.9865C18.5279 20.9833 17.7385 20.772 17.0735 20.3527C16.4085 19.9333 15.8958 19.329 15.5355 18.5395C15.1751 17.75 14.9966 16.8033 14.9999 15.6993C15.0031 14.5921 15.1833 13.6519 15.5404 12.8788C15.9007 12.1057 16.4117 11.5177 17.0735 11.1148C17.7385 10.7118 18.5279 10.5104 19.4419 10.5104C20.3559 10.5104 21.1454 10.7135 21.8103 11.1197C22.4753 11.5226 22.988 12.1106 23.3484 12.8837C23.7087 13.6568 23.8872 14.5953 23.884 15.6993C23.884 16.8098 23.7038 17.7598 23.3434 18.5493C22.9831 19.3388 22.4704 19.9432 21.8054 20.3625C21.1437 20.7785 20.3559 20.9865 19.4419 20.9865ZM19.4419 18.7655C19.9136 18.7655 20.3002 18.5198 20.6016 18.0284C20.9062 17.5338 21.0569 16.7574 21.0536 15.6993C21.0536 15.0081 20.9848 14.443 20.8473 14.0041C20.7097 13.5651 20.5197 13.2408 20.2773 13.0311C20.0348 12.8182 19.7564 12.7117 19.4419 12.7117C18.9702 12.7117 18.5853 12.9509 18.2872 13.4291C17.9891 13.9074 17.8367 14.6641 17.8302 15.6993C17.8269 16.4036 17.8941 16.9834 18.0317 17.4388C18.1692 17.8909 18.3592 18.225 18.6017 18.4412C18.8473 18.6574 19.1274 18.7655 19.4419 18.7655Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {}
</script>
