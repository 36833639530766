<template>
  <svg
    width="12"
    height="12"
    fill="none"
    viewBox="0 0 12 12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m5 2 4 4-4 4M9 6H8"
      stroke="#fff"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {}
</script>
