import { SCREEN_MAX_SIZES } from './variables'

const MAX_SIZE = 'qhd'

export default {
  setScrollPosition (state, position) {
    state.scrollPosition = position
  },

  setScreen (state, width) {
    const sizes = Object.values(SCREEN_MAX_SIZES)
    sizes.forEach((item, index) => {
      if (width <= item && width > sizes[index - 1]) {
        state.screen = Object.keys(SCREEN_MAX_SIZES)[index]
      }
      if (width <= sizes[0]) {
        state.screen = Object.keys(SCREEN_MAX_SIZES)[0]
      }
      if (width > sizes[sizes.length - 1]) {
        state.screen = MAX_SIZE
      }
    })
    state.isMobile = width <= SCREEN_MAX_SIZES.tablet
  },

  handleCookieAlertVisibility (state, payload) {
    state.isCookieAlertVisible = payload
  }
}
