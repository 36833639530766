<template>
  <svg
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.78912 11.7162L22.9297 0.533203C22.9297 17.4245 22.9297 9.14576 22.9297 22.9325L3.78912 11.7162Z"
      fill="white"
    />
    <path
      d="M0.529851 0.533691H4.12573V22.933H0.529851V0.533691Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {}
</script>
