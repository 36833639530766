<template>
  <svg width="1614" height="335" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g opacity=".26">
      <ellipse
        cx="807"
        cy="167.5"
        rx="812"
        ry="139.5"
        transform="rotate(-6.533 807 167.5)"
        fill="#3D244C"
      />
      <mask
        id="mask0_356_2190"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="395"
        y="123"
        width="144"
        height="45"
      >
        <ellipse cx="466.886" cy="145.985" rx="71.886" ry="22.006" fill="#291752" />
      </mask>
      <g mask="url(#mask0_356_2190)">
        <ellipse cx="466.886" cy="145.985" rx="71.886" ry="22.006" fill="#291752" />
        <ellipse cx="464.371" cy="149.758" rx="70.629" ry="18.653" fill="#1B1134" />
      </g>
      <mask
        id="mask1_356_2190"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="1148"
        y="103"
        width="145"
        height="25"
      >
        <ellipse cx="1220.4" cy="115.843" rx="71.886" ry="11.926" fill="#291752" />
      </mask>
      <g mask="url(#mask1_356_2190)">
        <ellipse cx="1220.4" cy="115.843" rx="71.886" ry="11.926" fill="#291752" />
        <ellipse cx="1217.89" cy="117.887" rx="70.629" ry="10.108" fill="#1B1134" />
      </g>
      <mask
        id="mask2_356_2190"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="824"
        y="67"
        width="121"
        height="15"
      >
        <ellipse cx="884.952" cy="74.288" rx="59.96" ry="7.288" fill="#291752" />
      </mask>
      <g mask="url(#mask2_356_2190)">
        <ellipse cx="884.952" cy="74.288" rx="59.96" ry="7.288" fill="#291752" />
        <ellipse cx="882.855" cy="75.537" rx="58.911" ry="6.177" fill="#1B1134" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {}
</script>
