export default function (ctx) {
  const apiDashboardLanguage = ctx.store.state.auth.dashboardLanguage
  const i18nLanguage = ctx.store.state.i18n.locale
  const path = ctx.route.fullPath

  if (!apiDashboardLanguage) {
    return
  }

  if (apiDashboardLanguage !== i18nLanguage) {
    let newPath = path.replace('/' + i18nLanguage + '/', '/')
    if (apiDashboardLanguage !== 'en') {
      newPath = '/' + apiDashboardLanguage + newPath
    }
    return ctx.redirect(newPath)
  }
}
