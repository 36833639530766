import { extend, configure } from 'vee-validate'

import { required, email } from 'vee-validate/dist/rules'

import { urlRegExp, phoneRegExp, emailNoAliasExp } from '../lib/vars.js'
import { testStringForRegExp } from '../lib/utils.js'

extend('email', email)
extend('required', required)

extend('email_no_alias', {
  validate: (value) => {
    return testStringForRegExp(emailNoAliasExp, value)
  }
})

extend('recipient_different_email', {
  params: ['target'],
  validate (value, { target }) {
    return value !== target
  }
})

extend('url', {
  validate (value) {
    return testStringForRegExp(urlRegExp, value)
  }
})

extend('phone', {
  validate (value) {
    return testStringForRegExp(phoneRegExp, value)
  }
})

export default function VeeValidatePlugin ({ app }) {
  configure({
    defaultMessage: (_, values) =>
      app.i18n.t(`ssrFormFieldsErrors.${values._rule_}`, values)
  })
}
