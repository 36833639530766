export default function (ctx) {
  const verificationCode = ctx.route.query.code

  if (!ctx.store.state.auth.idToken && verificationCode) {
    ctx.app.$cookiz.set('RSS_EVC', verificationCode)
  }

  // If the user is not authenticated
  if (
    !ctx.store.state.auth.idToken ||
    !ctx.store.state.auth.accessToken ||
    !ctx.store.state.auth.refreshToken ||
    ctx.store.state.auth.idToken === 'undefined' ||
    ctx.store.state.auth.accessToken === 'undefined' ||
    ctx.store.state.auth.refreshToken === 'undefined'
  ) {
    return ctx.redirect('/auth/sign-in/')
  }
}
