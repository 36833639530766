<template>
  <div class="features__container">
    <HeaderDropdown
      dropdown-id="features"
      :left-offset="200"
      :opened-dropdown-id="openedDropdownId"
      :trigger-text="$t('modules.navigation.features')"
      @dropdownOpened="$emit('dropdownOpened', $event)"
    >
      <div class="features__content-drop">
        <div class="features__content-drop-links">
          <div class="features__content-drop-links-col">
            <IconEntry
              icon-name="Hosting"
              :title="$t('modules.navigation.features_dropdown.hosting.title')"
              :description="$t('modules.navigation.features_dropdown.hosting.description')"
              :href="$locatedLink('/features/podcast-hosting')"
            />
            <IconEntry
              icon-name="Analytics"
              :title="$t('modules.navigation.features_dropdown.analytics.title')"
              :description="$t('modules.navigation.features_dropdown.analytics.description')"
              :href="$locatedLink('/features/podcast-analytics')"
            />
            <IconEntry
              icon-name="Distribution"
              :title="$t('modules.navigation.features_dropdown.distribution.title')"
              :description="$t('modules.navigation.features_dropdown.distribution.description')"
              :href="$locatedLink('/features/podcast-distribution')"
            />
            <IconEntry
              icon-name="Monetization"
              :title="$t('modules.navigation.features_dropdown.monetization.title')"
              :description="$t('modules.navigation.features_dropdown.monetization.description')"
              :href="$locatedLink('/features/podcast-monetization')"
            />
            <IconEntry
              icon-name="Transcription"
              :title="$t('modules.navigation.features_dropdown.transcription.title')"
              :description="$t('modules.navigation.features_dropdown.transcription.description')"
              :href="$locatedLink('/features/episode-transcripts')"
            />
            <IconEntry
              icon-name="Collaborators"
              :title="$t('modules.navigation.features_dropdown.collaborators.title')"
              :description="$t('modules.navigation.features_dropdown.collaborators.description')"
              :href="$locatedLink('/features/collaborators')"
            />
          </div>
          <div class="features__content-drop-links-col">
            <IconEntry
              icon-name="Website"
              :title="$t('modules.navigation.features_dropdown.website.title')"
              :description="$t('modules.navigation.features_dropdown.website.description')"
              :href="$locatedLink('/features/podcast-website')"
            />
            <IconEntry
              icon-name="Podcasting20"
              :title="$t('modules.navigation.features_dropdown.podcasting_2.title')"
              :description="$t('modules.navigation.features_dropdown.podcasting_2.description')"
              :href="$locatedLink('/features/podcasting2')"
            />
            <IconEntry
              icon-name="EmbeddablePlayer"
              :title="$t('modules.navigation.features_dropdown.embeddable_player.title')"
              :description="$t('modules.navigation.features_dropdown.embeddable_player.description')"
              :href="$locatedLink('/features/embeddable-player')"
            />
            <IconEntry
              icon-name="Privacy"
              :title="$t('modules.navigation.features_dropdown.privacy.title')"
              :description="$t('modules.navigation.features_dropdown.privacy.description')"
              :href="$locatedLink('/features/privacy')"
            />
            <IconEntry
              icon-name="SocialMediaSharing"
              :title="$t('modules.navigation.features_dropdown.social_media_sharing.title')"
              :description="$t('modules.navigation.features_dropdown.social_media_sharing.description')"
              :href="$locatedLink('/features/social-media-sharing')"
            />
            <IconEntry
              icon-name="AudioToVideo"
              :title="$t('modules.navigation.features_dropdown.audio_to_video.title')"
              :description="$t('modules.navigation.features_dropdown.audio_to_video.description')"
              :href="$locatedLink('/features/audio-to-video')"
            />
          </div>
        </div>
        <div class="features__content-drop-footer">
          <Link :href="$locatedLink('/features/')" class="features__content-drop-footer-link" is-unstyled>
            {{ $t('modules.navigation.features_dropdown.all_features') }}
            <GradientIcon name="ArrowRight" />
          </Link>
        </div>
      </div>
    </HeaderDropdown>
  </div>
</template>

<script>
import Link from '@/components/common/Link/Link'
import GradientIcon from '@/components/common/GradientIcon/GradientIcon'
import IconEntry from '@/components/modules/header/items/IconEntry'
import HeaderDropdown from '@/components/modules/header/HeaderDropdown'
export default {
  components: {
    IconEntry,
    HeaderDropdown,
    Link,
    GradientIcon
  },

  props: {
    openedDropdownId: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.features {
  &__content {
    &-drop {
      &-links {
        display: flex;
        flex-direction: row;
        font-weight: 400;

        @include display-less(desktop) {
          flex-direction: column;
        }
        &-col {
          flex: 1;
          display: flex;
          flex-direction: column;
          gap: $gap * 0.5;

          @include display-less(desktop) {
            gap: 0;
          }
        }
      }

      &-footer {
        margin-top: $gap;
        padding-top: $gap;
        border-top: 1px solid #422965;
        background: linear-gradient(135deg, rgba(248, 121, 0, 1) 0%, rgba(254, 79, 69, 1) 50%, rgba(228, 25, 186, 1) 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;

        @include display-less(desktop) {
          border-top: 1px solid #DFDFDF;
        }
        &-link {
          display: flex;
          flex-direction: row;
          align-items: center;
          text-decoration: none;
          font-size: 16px;
          line-height: 24px;
          font-weight: 700;
          gap: $gap * 0.5;
        }
      }
    }
  }
}
</style>
