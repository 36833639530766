<template lang="pug">
.settings(:class="isFullScreen ? 'settings-full-screen': 'settings-default'")
  .settings__sound(
    v-if="!mobileOperatingSystem"
  )
    .settings__sound-slider
      input.settings__sound-slider-input(
        type="range"
        ref="soundSlider"
        v-model="volumeInput"
        @input="onSoundSliderChange"
        @change="onSoundSliderChange"
      )
      .settings__sound-slider-background
        .settings__sound-slider-progress(
          :style="`width: ${volumeInput}%;`"
        )
          .settings__sound-slider-progress-head
    a.settings__sound-btn(
      href="#"
    )
      VolumeIcon
  TutorialTooltip(
    v-if="isFullScreen && chapters && chapters.length > 0" @click="chaptersViewChange"
    :label="$t('tutorials.player.chapters')"
    color="light"
    align="right"
    tutorialId="tutorialPlayerChapters"
  )
    .settings__chapters(
      @click="chaptersViewChange"
    )
      OpenChaptersIcon(v-if="!isChaptersViewOpen")
      CloseChaptersIcon(v-else)
  TutorialTooltip.settings__full-screen(
    :class="isFullScreen ? 'settings__full-screen-full-screen': 'settings__full-screen-default'"
    :disabled="isFullScreen"
    :label="$t('tutorials.player.full_screen')"
    color="light"
    align="right"
    tutorialId="tutorialPlayerFullScreenDesktop"
  )
    .settings__full-screen-icons(
      @click="fullScreenChange"
    )
      FullScreenIcon(v-if="!isFullScreen")
      ExitFullScreenIcon(v-else)
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getMobileOperatingSystem } from '@/lib/utils'

import VolumeIcon from '@/components/icons/player/Volume'
import OpenChaptersIcon from '@/components/icons/player/OpenChapters'
import CloseChaptersIcon from '@/components/icons/player/CloseChapters'
import FullScreenIcon from '@/components/icons/player/FullScreen'
import ExitFullScreenIcon from '@/components/icons/player/ExitFullScreen'
import TutorialTooltip from '@/components/shared/elements/TutorialTooltip'

export default {
  components: {
    VolumeIcon,
    FullScreenIcon,
    ExitFullScreenIcon,
    OpenChaptersIcon,
    CloseChaptersIcon,
    TutorialTooltip
  },

  props: {
    isFullScreen: {
      type: Boolean,
      default: false
    },
    isChaptersViewOpen: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      soundSliderDragging: false,
      volumeInput: null
    }
  },

  computed: {
    ...mapState('player', ['chapters', 'volume']),

    mobileOperatingSystem () {
      return getMobileOperatingSystem()
    }
  },

  watch: {
    volume () {
      this.volumeInput = this.volume * 100
    }
  },

  mounted () {
    this.volumeInput = this.volume * 100
  },

  methods: {
    ...mapActions('player', ['setVolume']),

    onSoundSliderChange () {
      this.setVolume(this.volumeInput / 100)
    },

    fullScreenChange () {
      this.$emit('chaptersViewChange', false)
      this.$emit('fullScreenChange', !this.isFullScreen)
    },

    chaptersViewChange () {
      this.$emit('chaptersViewChange', !this.isChaptersViewOpen)
    }
  }
}
</script>

<style lang="scss" scoped>
.settings {
  display: flex;
  flex-direction: row;
  gap: $gap * 1.5;
  justify-content: flex-end;

  &-full-screen {
    width: fit-content;
  }
  &-default {
    width: 100%;
    @include display-less(phablet) {
      display: none;
    }
  }

  &__sound {
    z-index: 3;
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    width: fit-content;

    &:hover {
      .settings__sound-slider {
        width: 186px;
      }
      .settings__sound-slider-progress-head {
        width: 10px;
      }
    }

    @include display-less(phablet) {
      display: none;
    }

    &-btn {
      margin-top: 5px;
      margin-left: $gap;
      width: 24px;
      outline: none;
    }
    &-slider {
      width: 0;
      right: 35px;
      z-index: 2;
      height: 6px;
      transition: 0.25s;
      position: absolute;

      &-input {
        width: 100%;
        width: 100%;
        -webkit-appearance: none;
        height: 16px;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 40;
        cursor: pointer;

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          width: 8px;
          height: 8px;
        }

        &:focus {
          outline: none;
        }
      }

      &-background {
        width: 100%;
        background-color: #1c0c32;
        border-radius: 2px;
      }

      &-progress {
        width: 0px;
        height: 6px;
        background-color: #fff;
        position: relative;
        top: 0;
        border-radius: 2px;

        &-head {
          width: 0px;
          height: 10px;
          border-radius: 50%;
          background-color: #fff;
          position: absolute;
          right: -2px;
          top: -2.5px;
          transition: all 0.2s ease-in-out;
          pointer-events: none;
        }
      }
    }
  }

  &__chapters {
    cursor: pointer;
    width: 24px;
  }

  &__full-screen {
    &-full-screen {
      @include display-less(phablet) {
        display: none;
      }
    }

    &-icons {
      cursor: pointer;
      width: 24px;
    }
  }
}
</style>
