<template>
  <svg viewBox="0 0 12 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M7.69349 4.95728V8.05895H11.4773L10.8781 12.2371H7.69349V21.8633C7.05499 21.9531 6.40174 22 5.73864 22C4.97322 22 4.22158 21.9381 3.48962 21.8184V12.2371H0V8.05895H3.48962V4.26392C3.48962 1.90949 5.37167 0 7.69448 0V0.00199495C7.70137 0.00199495 7.70727 0 7.71416 0H11.4783V3.61346H9.0187C8.28772 3.61346 7.69448 4.21504 7.69448 4.95628L7.69349 4.95728Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {}
</script>
