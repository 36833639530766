export default {
  async searchPodcastsByTerm ({ commit, rootState, dispatch }, query) {
    let podcasts = []

    try {
      podcasts = await dispatch('api/fetch', {
        public: true,
        method: 'POST',
        endpoint: '/search/podcast-index/byterm',
        data: {
          q: query
        }
      }, { root: true })
    } catch (error) {
      console.log(error)
    }

    return podcasts
  }
}
