<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.0002 8.6016C20.3537 9.02594 20.2963 9.65649 19.8719 10.01L12.6372 16.0366C12.2663 16.3456 11.7276 16.3455 11.3568 16.0364L4.12799 10.01C3.70378 9.65638 3.64658 9.0258 4.00022 8.60159C4.35387 8.17739 4.98444 8.12018 5.40865 8.47383L11.3573 13.4329C11.7281 13.7421 12.2667 13.7422 12.6377 13.4332L18.5918 8.47329C19.0162 8.11981 19.6467 8.17725 20.0002 8.6016Z"
      fill="white"
    />
  </svg>
</template>

<script>
export default {}
</script>
