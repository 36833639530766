<template>
  <svg
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22 44C34.1503 44 44 34.1503 44 22C44 9.84974 34.1503 0 22 0C9.84974 0 0 9.84974 0 22C0 34.1503 9.84974 44 22 44ZM22 41.9571C33.022 41.9571 41.957 33.022 41.9571 22C41.9571 10.978 33.022 2.04286 22 2.04286C10.978 2.04286 2.04286 10.978 2.04286 22C2.04286 33.022 10.978 41.9571 22 41.9571Z"
      fill="white"
    />
    <g v-if="isPlaying">
      <path
        d="M14.1 14.9262C14.1 13.769 15.0381 12.8309 16.1953 12.8309H17.505C18.6622 12.8309 19.6003 13.769 19.6003 14.9262V29.069C19.6003 30.2262 18.6622 31.1643 17.505 31.1643H16.1953C15.0381 31.1643 14.1 30.2262 14.1 29.069V14.9262Z"
        fill="white"
      />
      <path
        d="M27.1949 12.8309C26.0377 12.8309 25.0996 13.769 25.0996 14.9262V29.069C25.0996 30.2262 26.0377 31.1643 27.1949 31.1643H28.5046C29.6618 31.1643 30.5999 30.2262 30.5999 29.069V14.9262C30.5999 13.769 29.6618 12.8309 28.5046 12.8309H27.1949Z"
        fill="white"
      />
    </g>
    <g v-else>
      <path
        d="M31.6527 20.9326C32.4092 21.4286 32.41 22.5374 31.6541 23.0344L18.4477 31.7181C17.6118 32.2677 16.4999 31.6663 16.4999 30.6658V13.3416C16.4999 12.3419 17.6102 11.7267 18.4463 12.2748L31.6527 20.9326Z"
        fill="white"
      />
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    isPlaying: {
      type: Boolean,
      default: false
    }
  }
}
</script>
