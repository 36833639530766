<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.99399 4.25488V20.0416H7.98747C7.98747 20.4134 7.92876 20.7983 7.79829 21.1767C7.17204 22.9967 5.18239 23.9687 3.36236 23.3424C1.5358 22.7162 0.563805 20.7265 1.19005 18.9C1.79021 17.1778 3.60372 16.2123 5.33896 16.6494V4.87461L7.99399 4.25488Z"
      fill="black"
    />
    <path
      d="M7.99399 4.25488V20.0416H7.98747C7.98747 20.4134 7.92876 20.7983 7.79829 21.1767C7.17204 22.9967 5.18239 23.9687 3.36236 23.3424C1.5358 22.7162 0.563805 20.7265 1.19005 18.9C1.79021 17.1778 3.60372 16.2123 5.33896 16.6494V4.87461L7.99399 4.25488Z"
      :fill="`url(#${componentId}_paint0_linear_2042_27982)`"
    />
    <path
      d="M21.9676 1V17.7652H21.9611C21.9611 18.1436 21.9024 18.5219 21.7719 18.9003C21.1457 20.7269 19.1626 21.6988 17.336 21.0661C15.5094 20.4398 14.5374 18.4567 15.1637 16.6301C15.7638 14.9014 17.5774 13.9425 19.3126 14.3796V1.61973L21.9676 1Z"
      fill="black"
    />
    <path
      d="M21.9676 1V17.7652H21.9611C21.9611 18.1436 21.9024 18.5219 21.7719 18.9003C21.1457 20.7269 19.1626 21.6988 17.336 21.0661C15.5094 20.4398 14.5374 18.4567 15.1637 16.6301C15.7638 14.9014 17.5774 13.9425 19.3126 14.3796V1.61973L21.9676 1Z"
      :fill="`url(#${componentId}_paint1_linear_2042_27982)`"
    />
    <path
      d="M21.9671 1V5.61859L5.33887 9.48698V4.87492L21.9671 1Z"
      :fill="`url(#${componentId}_paint2_linear_2042_27982)`"
      fill-opacity="0.8"
    />
    <defs>
      <linearGradient
        :id="`${componentId}_paint0_linear_2042_27982`"
        x1="-3.89344"
        y1="19.5515"
        x2="9.76652"
        y2="23.8479"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F8B700" />
        <stop offset="0.51" stop-color="#F87900" />
        <stop offset="1" stop-color="#AE35DD" />
      </linearGradient>
      <linearGradient
        :id="`${componentId}_paint1_linear_2042_27982`"
        x1="10.0802"
        y1="17.0756"
        x2="23.8574"
        y2="21.1989"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F8B700" />
        <stop offset="0.51" stop-color="#F87900" />
        <stop offset="1" stop-color="#AE35DD" />
      </linearGradient>
      <linearGradient
        :id="`${componentId}_paint2_linear_2042_27982`"
        x1="5.33887"
        y1="9.48698"
        x2="21.9671"
        y2="9.48698"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F87900" />
        <stop offset="0.510667" stop-color="#FE4F45" />
        <stop offset="1" stop-color="#E419BA" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    componentId: {
      type: String,
      default: ''
    }
  }
}
</script>
