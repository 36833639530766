<template>
  <svg width="82" height="141" fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <path d="M74.934 46.741v61.629H5.926V46.741a34.504 34.504 0 0 1 69.008 0Z" fill="#95BCF4" />
    <path d="M45.857 61.423c0 2.592-2.814 4.694-6.283 4.694-3.468 0-6.286-2.102-6.286-4.694" fill="#001666" />
    <path d="M39.75 57.61c6.745 0 12.213-5.468 12.213-12.213 0-6.745-5.468-12.213-12.213-12.213-6.745 0-12.213 5.468-12.213 12.213 0 6.745 5.468 12.212 12.213 12.212Z" fill="#E2E2E2" />
    <path d="M39.75 53.35a7.954 7.954 0 1 0 0-15.907 7.954 7.954 0 0 0 0 15.908Z" fill="#4931BC" />
    <path d="M23.749 28.328 14.652 5.2a3.314 3.314 0 1 0-3.594 1.312l9.133 23.21 3.558-1.394Z" fill="url(#paint0_linear_471_4645)" />
    <path d="M70.038 5.142a3.4 3.4 0 1 0-6.322 1.725l-11.465 24.2 3.447 1.635L67.167 8.496a3.396 3.396 0 0 0 2.871-3.354Z" fill="url(#paint1_linear_471_4645)" />
    <path d="M36.419 46.483a4.554 4.554 0 1 0 0-9.108 4.554 4.554 0 0 0 0 9.108Z" fill="#E2E2E2" />
    <path d="M22.333 62.131c4.321 0 7.824-1.337 7.824-2.986 0-1.65-3.503-2.987-7.824-2.987-4.322 0-7.825 1.337-7.825 2.986 0 1.65 3.503 2.987 7.825 2.987ZM56.477 62.131c4.322 0 7.825-1.337 7.825-2.986 0-1.65-3.503-2.987-7.825-2.987-4.321 0-7.824 1.337-7.824 2.986 0 1.65 3.503 2.987 7.824 2.987Z" fill="#684BE0" />
    <path d="M19.203 28.842a1.851 1.851 0 1 0 0-3.702 1.851 1.851 0 0 0 0 3.702ZM25.18 22.581a1.28 1.28 0 1 0 0-2.559 1.28 1.28 0 0 0 0 2.559Z" fill="#fff" />
    <path d="M40.423 12.23c-2.686 0-5.362.31-7.976.927a34.504 34.504 0 0 1 28.524 33.994v61.219h13.963V46.741A34.505 34.505 0 0 0 40.423 12.23Z" fill="#B6B8D1" style="mix-blend-mode:multiply" opacity=".31" />
    <path d="m44.627 139.147.619-.309M5.013 53.308c-2.089 4.108-3.929 8.841-2.516 13.223 1.797 5.588 8.115 8.352 11.36 13.244 6.686 10.064-1.692 24.534 3.498 35.453 3.903 8.205 14.614 12.31 16.601 21.18M75.286 52.75c2.4 2.125 4.184 5.032 4.579 8.21.79 6.397-3.864 11.982-7.368 17.392-2.911 4.496-5.226 9.618-5.129 14.973.104 5.75 2.933 11.735.64 17.004-1.194 2.742-3.594 4.741-5.905 6.635l-10.46 8.55" stroke="#fff" stroke-width="3" stroke-miterlimit="10" />
    <path d="M5.76 46.767c-1.495 0-2.702 1.595-2.702 3.558 0 1.962 1.207 3.558 2.702 3.558M74.905 46.767c1.491 0 2.703 1.595 2.703 3.558 0 1.962-1.212 3.558-2.703 3.558" fill="#6949DB" />
    <g opacity=".12">
      <mask
        id="mask0_471_4645"
        style="mask-type:alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="82"
        height="141"
      >
        <path d="M74.934 46.741v61.629H5.926V46.741a34.504 34.504 0 0 1 69.008 0Z" fill="#95BCF4" />
        <path d="M45.857 61.423c0 2.592-2.814 4.694-6.283 4.694-3.468 0-6.286-2.102-6.286-4.694" fill="#001666" />
        <path d="M39.75 57.61c6.745 0 12.213-5.468 12.213-12.213 0-6.745-5.468-12.213-12.213-12.213-6.745 0-12.213 5.468-12.213 12.213 0 6.745 5.468 12.212 12.213 12.212Z" fill="#E2E2E2" />
        <path d="M39.75 53.35a7.954 7.954 0 1 0 0-15.907 7.954 7.954 0 0 0 0 15.908Z" fill="#4931BC" />
        <path d="M23.749 28.328 14.652 5.2a3.314 3.314 0 1 0-3.594 1.312l9.133 23.21 3.558-1.394Z" fill="url(#paint2_linear_471_4645)" />
        <path d="M70.038 5.142a3.4 3.4 0 1 0-6.322 1.725l-11.465 24.2 3.447 1.635L67.167 8.496a3.396 3.396 0 0 0 2.871-3.354Z" fill="url(#paint3_linear_471_4645)" />
        <path d="M36.419 46.483a4.554 4.554 0 1 0 0-9.108 4.554 4.554 0 0 0 0 9.108Z" fill="#E2E2E2" />
        <path d="M22.333 62.131c4.321 0 7.824-1.337 7.824-2.986 0-1.65-3.503-2.987-7.824-2.987-4.322 0-7.825 1.337-7.825 2.986 0 1.65 3.503 2.987 7.825 2.987ZM56.477 62.131c4.322 0 7.825-1.337 7.825-2.986 0-1.65-3.503-2.987-7.825-2.987-4.321 0-7.824 1.337-7.824 2.986 0 1.65 3.503 2.987 7.824 2.987Z" fill="#684BE0" />
        <path d="M19.203 28.842a1.851 1.851 0 1 0 0-3.702 1.851 1.851 0 0 0 0 3.702ZM25.18 22.581a1.28 1.28 0 1 0 0-2.559 1.28 1.28 0 0 0 0 2.559Z" fill="#fff" />
        <path d="M40.423 12.23c-2.686 0-5.362.31-7.976.927a34.504 34.504 0 0 1 28.524 33.994v61.219h13.963V46.741A34.505 34.505 0 0 0 40.423 12.23Z" fill="#B6B8D1" style="mix-blend-mode:multiply" opacity=".31" />
        <path d="m44.627 139.147.619-.309M5.013 53.308c-2.089 4.108-3.929 8.841-2.516 13.223 1.797 5.588 8.115 8.352 11.36 13.244 6.686 10.064-1.692 24.534 3.498 35.453 3.903 8.205 14.614 12.31 16.601 21.18M75.286 52.75c2.4 2.125 4.184 5.032 4.579 8.21.79 6.397-3.864 11.982-7.368 17.392-2.911 4.496-5.226 9.618-5.129 14.973.104 5.75 2.933 11.735.64 17.004-1.194 2.742-3.594 4.741-5.905 6.635l-10.46 8.55" stroke="#fff" stroke-width="3" stroke-miterlimit="10" />
        <path d="M5.76 46.767c-1.495 0-2.702 1.595-2.702 3.558 0 1.962 1.207 3.558 2.702 3.558M74.905 46.767c1.491 0 2.703 1.595 2.703 3.558 0 1.962-1.212 3.558-2.703 3.558" fill="#6949DB" />
      </mask>
      <g mask="url(#mask0_471_4645)">
        <path fill="url(#pattern0)" d="M-51-22h184v184H-51z" />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_471_4645"
        x1="11.367"
        y1="1.889"
        x2="22.239"
        y2="29.557"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#fff" />
        <stop offset=".1" stop-color="#E4EEFC" />
        <stop offset=".25" stop-color="#C8DCF9" />
        <stop offset=".4" stop-color="#B1CEF7" />
        <stop offset=".56" stop-color="#A1C4F5" />
        <stop offset=".75" stop-color="#98BEF4" />
        <stop offset="1" stop-color="#95BCF4" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_471_4645"
        x1="66.631"
        y1="3.802"
        x2="55.748"
        y2="32.785"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#fff" />
        <stop offset=".03" stop-color="#F9FBFE" />
        <stop offset=".21" stop-color="#D5E5FB" />
        <stop offset=".4" stop-color="#B9D3F8" />
        <stop offset=".59" stop-color="#A5C6F6" />
        <stop offset=".78" stop-color="#99BFF4" />
        <stop offset=".97" stop-color="#95BCF4" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_471_4645"
        x1="11.367"
        y1="1.889"
        x2="22.239"
        y2="29.557"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#fff" />
        <stop offset=".1" stop-color="#E4EEFC" />
        <stop offset=".25" stop-color="#C8DCF9" />
        <stop offset=".4" stop-color="#B1CEF7" />
        <stop offset=".56" stop-color="#A1C4F5" />
        <stop offset=".75" stop-color="#98BEF4" />
        <stop offset="1" stop-color="#95BCF4" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_471_4645"
        x1="66.631"
        y1="3.802"
        x2="55.748"
        y2="32.785"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#fff" />
        <stop offset=".03" stop-color="#F9FBFE" />
        <stop offset=".21" stop-color="#D5E5FB" />
        <stop offset=".4" stop-color="#B9D3F8" />
        <stop offset=".59" stop-color="#A5C6F6" />
        <stop offset=".78" stop-color="#99BFF4" />
        <stop offset=".97" stop-color="#95BCF4" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {}
</script>
