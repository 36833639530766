<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.0584 17.0457C11.1915 18.3726 10.7522 19.7454 9.7405 20.7571C8.4384 22.0592 4.52293 22.8019 3.12913 22.9761C3.05577 22.9853 2.96407 22.9945 2.88155 22.9945V23.0037C2.37721 23.0037 1.90955 22.8111 1.55194 22.4443C1.1393 22.0317 0.937563 21.454 1.01092 20.8671C1.18515 19.4733 1.92789 15.5578 3.22999 14.2557C4.10112 13.3846 5.24733 12.9078 6.48525 12.9078C6.64408 12.9078 6.8014 12.9156 6.95682 12.9312L18.0391 1.84888C18.5893 1.29869 19.3137 0.996094 20.0931 0.996094C20.8725 0.996094 21.597 1.29869 22.1471 1.84888C23.2842 2.97675 23.2842 4.81987 22.1471 5.95692L11.0584 17.0457Z"
      :fill="`url(#${componentId}_paint0_linear_2042_28002)`"
    />
    <g :filter="`url(#${componentId}_filter0_bi_2042_28002)`">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.2746 21.4836C18.2191 22.428 19.5029 22.9599 20.8417 22.9599H22.9599V20.8417C22.9599 19.5029 22.428 18.2191 21.4836 17.2746L8.34675 4.13785L4.13785 8.34675L17.2746 21.4836ZM2.72363 6.93254L6.93254 2.72363L6.07841 1.86951C4.91386 0.704955 3.03407 0.704955 1.86951 1.86951C0.704955 3.03407 0.704955 4.91386 1.86951 6.07841L2.72363 6.93254Z"
        :fill="`url(#${componentId}_paint1_linear_2042_28002)`"
        fill-opacity="0.4"
      />
    </g>
    <defs>
      <filter
        :id="`${componentId}_filter0_bi_2042_28002`"
        x="0.158822"
        y="0.158822"
        width="23.6384"
        height="23.6384"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.418636" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_2042_28002"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_2042_28002"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.0418636" dy="0.0209318" />
        <feGaussianBlur stdDeviation="0.104659" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2042_28002"
        />
      </filter>
      <linearGradient
        :id="`${componentId}_paint0_linear_2042_28002`"
        x1="23.9204"
        y1="0.537606"
        x2="0.0942506"
        y2="23.495"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EEF9FF" />
        <stop offset="0.510667" stop-color="#13E5D5" />
        <stop offset="1" stop-color="#1D52A0" />
      </linearGradient>
      <linearGradient
        :id="`${componentId}_paint1_linear_2042_28002`"
        x1="27.1846"
        y1="-1.70361"
        x2="5.59173"
        y2="23.6856"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EEF9FF" />
        <stop offset="0.510667" stop-color="#13E5D5" />
        <stop offset="1" stop-color="#1D52A0" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    componentId: {
      type: String,
      default: ''
    }
  }
}
</script>
