export function getGlobalColor (color) {
  const colors = {
    primary: '#8d51bb',
    accent: '#6c26d6',
    warning: '#ffd974',
    danger: '#d50000',
    success: '#00bfa5',
    info: '#0091ea',
    link: '#2962ff',
    text: '#2D2D2D',
    textSecondary: '#6d7278',
    textHint: '#b0bec5',
    border: '#9e9e9e',
    borderDark: '#616161',
    bg: '#fafafa'
  }

  return colors[color] || colors.text
}

export function getGlobalFontSize (size, measure) {
  const sizes = {
    tiny: 12,
    small: 14,
    base: 16,
    big: 18,
    large: 22,
    title: 28,
    headline: 36
  }

  let result = sizes[size] || sizes.base
  result = measure ? result + measure : result

  return result
}

export const noCoverImageName = 'no-cover-1400.jpg'

// eslint-disable-next-line
export const emailRegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
// eslint-disable-next-line
export const phoneRegExp = /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,3})|(\(?\d{2,3}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/
// eslint-disable-next-line
export const urlRegExp = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)$/
// eslint-disable-next-line
export const emailNoAliasExp = /^[\w-\.]+@/
