/*
 ** Core API endpoints:
 ** - GET /podcasts
*/

export default {
  async init ({ commit, dispatch, getters, state, rootState }) {
    commit('setPodcastsLoadingStatus', true)

    await dispatch('fetchPodcasts')
    const podcasts = getters.podcasts

    let updatedPodcasts = await Promise.all(podcasts.map(async (podcast) => {
      const result = await dispatch('get', podcast.s3_path)
      return result
    }))

    updatedPodcasts = updatedPodcasts.filter((p) => {
      return p !== null
    })

    const activePodcastSlug = this.$cookiz.get('current_podcast_slug')
    let podcastIndex = updatedPodcasts.findIndex((p) => {
      return p.slug === activePodcastSlug
    })

    if (podcastIndex < 0) {
      podcastIndex = 0
    }

    commit('setPodcasts', updatedPodcasts)
    commit('setPodcastsLoadingStatus', false)
  },

  async get ({ commit, dispatch }, slug) {
    commit('setPodcastsLoadingStatus', true)
    let podcast = null

    try {
      podcast = await dispatch('api/fetch', {
        endpoint: `/podcasts/${slug}`,
        public: true
      }, { root: true })
    } catch (error) {
      console.error(error)
    }

    commit('setPodcastsLoadingStatus', false)
    return podcast
  },

  async getPlaylist ({ commit, dispatch }, slug) {
    commit('setPodcastsLoadingStatus', true)
    let playlist = null

    try {
      playlist = await dispatch('api/fetch', {
        endpoint: `/podcasts/${slug}/playlist`,
        public: true
      }, { root: true })
    } catch (error) {}

    commit('setPodcastsLoadingStatus', false)
    return playlist
  },

  async getEpisodes ({ commit, dispatch }, { slug, limit, page }) {
    let episodes = []

    commit('setPodcastsLoadingStatus', true)

    try {
      const getEpisodesResponse = await dispatch('api/fetch', {
        endpoint: `/podcasts/${slug}/episodes?limit=${limit}&page=${page}`,
        public: true
      }, { root: true })

      if (getEpisodesResponse && getEpisodesResponse.episodes) {
        episodes = getEpisodesResponse.episodes
      }
    } catch (error) {}

    commit('setPodcastsLoadingStatus', false)
    return episodes
  },

  async getEpisodeById ({ commit, dispatch }, { slug, eid }) {
    commit('setPodcastsLoadingStatus', true)
    let episode = null

    try {
      episode = await dispatch('api/fetch', {
        endpoint: `/podcasts/${slug}/episodes/${eid}`,
        public: true
      }, { root: true })
    } catch (error) {}

    commit('setPodcastsLoadingStatus', false)
    return episode
  },

  async fetchPodcasts ({ commit, dispatch, state, rootState }) {
    let podcasts = []

    const idToken = rootState.auth.idToken
    try {
      podcasts = await dispatch('api/fetch', {
        endpoint: '/v2/podcasts',
        token: idToken
      }, { root: true })
    } catch (error) {
      podcasts = []
      console.error(error)
    }

    commit('setPodcasts', podcasts)
    return podcasts
  },

  /*
  ** Episode's Chapters & Soundbites
  */
  async getEpisodeSoundbitesByEid ({ commit, dispatch, state, rootState }, eid) {
    let soundbites = []
    let result

    try {
      result = await dispatch('api/fetch', {
        endpoint: `/soundbites/${eid}`,
        public: true
      }, { root: true })
    } catch (err) {
      result = null
    }

    if (result && result.length) {
      soundbites = result
    }
    return soundbites
  },

  async getEpisodeChaptersByEid ({ commit, dispatch, state, rootState }, eid) {
    let chapters = []
    let result

    try {
      result = await dispatch('api/fetch', {
        endpoint: `/chapters/${eid}`,
        public: true
      }, { root: true })
    } catch (err) {
      result = null
    }

    if (result && result.chapters && result.chapters.length) {
      chapters = result.chapters
    }

    return chapters
  },

  /*
  ** Episode's transcriptions
  */
  async getEpisodeTranscriptionByEid ({ commit, dispatch, state, rootState }, eid) {
    let transcriptions = []
    let result

    try {
      result = await dispatch('api/fetch', {
        endpoint: `/transcriptions/${eid}`,
        public: true
      }, { root: true })
    } catch (err) {
      result = null
    }

    if (result) {
      transcriptions = result
    }

    return transcriptions
  },

  /*
  ** Import podcast to RSS
  */
  async importPodcastToRss ({ commit, dispatch, state, rootState }, { email, feedUrl, recaptchaToken, language }) {
    let result

    try {
      await dispatch('api/fetch', {
        endpoint: '/podcast/import',
        method: 'POST',
        public: true,
        data: {
          email,
          feed_url: feedUrl,
          recaptchaToken,
          language
        }
      }, { root: true })
      result = true
    } catch (err) {
      result = null
    }

    return result
  }
}
