<template lang="pug">
.no-connection
  .notices.is-bottom
    .toast.is-danger.is-bottom(
      v-if="visible"
    )
      NoConnectionIcon.no-connection__icon(
        type="fa"
        name="wifi-slash"
      )
      span.no-connection__text {{ $t('toast_notifications.errors.offline') }}
</template>

<script>
import NoConnectionIcon from '@/components/icons/dashboard/NoConnection.vue'

export default {
  components: {
    NoConnectionIcon
  },

  data () {
    return {
      visible: false
    }
  },

  computed: {
    isOnline () {
      return this.$nuxt.isOnline
    }
  },

  watch: {
    isOnline (isOnline) {
      if (!isOnline) {
        this.visible = true
      } else {
        this.visible = false
      }
    }
  },

  beforeMount () {
    if (!this.isOnline) {
      this.visible = true
    }
  }
}
</script>

<style lang="scss" scoped>
.no-connection {
  left: $gap;
  bottom: $gap;
  z-index: 1000;
  position: fixed;

  &__text {
    line-height: 1;
    margin-top: 1px;
    font-size: 14px;
    font-weight: bold;
    margin-left: $gap / 2;
  }

  &__icon {
    width: 24px;
  }

  .toast {
    display: flex;
    align-items: center;
  }
}
</style>
