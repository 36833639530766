<template>
  <div ref="lottieContainer" />
</template>

<script>
import lottie from 'lottie-web'

export default {
  props: {
    animationPath: {
      type: String,
      required: true
    }
  },
  mounted () {
    this.initLottie()
  },
  methods: {
    initLottie () {
      lottie.loadAnimation({
        container: this.$refs.lottieContainer,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        path: this.animationPath
      })
    }
  }
}
</script>
