<template>
  <nav
    ref="nav"
    :class="['nav', { 'nav_opened': opened }]"
    role="navigation"
  >
    <ul class="nav__menu">
      <li class="nav__item">
        <Features
          :opened-dropdown-id="openedDropdownId"
          @dropdownOpened="setOpenDropdownId"
        />
      </li>

      <li class="nav__item">
        <Resources
          :opened-dropdown-id="openedDropdownId"
          @dropdownOpened="setOpenDropdownId"
        />
      </li>

      <li class="nav__item">
        <Link
          class="nav__item-link"
          :href="$locatedLink('/pricing/')"
          is-unstyled
        >
          {{ $t('modules.navigation.pricing') }}
        </Link>
      </li>

      <li class="nav__item nav__item-separator">
        <Podcasts
          :opened-dropdown-id="openedDropdownId"
          @dropdownOpened="setOpenDropdownId"
        />
      </li>

      <li v-if="isLoggedIn" class="nav__item">
        <Account
          :opened-dropdown-id="openedDropdownId"
          @dropdownOpened="setOpenDropdownId"
        />
      </li>

      <li v-else class="nav__item">
        <SignButtons />
      </li>
    </ul>
    <MobileFooter class="nav__footer" />
  </nav>
</template>

<script>
import { mapState } from 'vuex'

import { config } from '@/config'

import Features from '@/components/modules/header/items/Features'
import Resources from '@/components/modules/header/items/Resources'
import Podcasts from '@/components/modules/header/items/Podcasts'
import Account from '@/components/modules/header/items/Account'
import SignButtons from '@/components/modules/header/items/SignButtons'
import Link from '@/components/common/Link/Link.vue'
import MobileFooter from '@/components/modules/header/items/MobileFooter'

export default {
  components: {
    Features,
    Resources,
    Podcasts,
    Account,
    SignButtons,
    Link,
    MobileFooter
  },

  props: {
    opened: Boolean
  },

  data () {
    return {
      openedDropdownId: null
    }
  },

  computed: {
    ...mapState('auth', ['avatarPhotoUrl', 'isLoggedIn']),

    dashboardAppUrl () {
      return config.dashboardAppUrl
    }
  },

  watch: {
    opened (value) {
      if (value) {
        document.documentElement.classList.add('nav-open')
      } else {
        document.documentElement.classList.remove('nav-open')
      }
    }
  },

  methods: {
    setOpenDropdownId (dropdownId) {
      this.openedDropdownId = dropdownId
    }
  }
}
</script>

<style lang="scss" scoped>
.nav {
  @include display-less(desktop) {
    display: flex;
    flex-direction: column;
    height: 100%;
    &__menu {
      display: flex;
      flex-direction: column;
      overflow-x: hidden;
      overflow-y: auto;
      gap: $gap * 2;
      height: 100%;
      padding: 0 $gap;
    }

    &__item {
      opacity: 0;
      text-align: left;
      font-size: 16px;

      &-link {
        color: #2D2D2D;
        text-decoration: none;
        font-weight: 700;
      }

      .nav_opened & {
        opacity: 1;
        transition: opacity $speed $speed ease-out;
      }

      .nav-closing & {
        opacity: 0;
      }
    }
  }

  @include display(desktop) {
    &__menu {
      display: flex;
      align-items: center;
      gap: $gap * 2;
    }

    &__item {
      font-weight: 700;

      &-link {
        color: #ffffff;
        text-decoration: none;
        font-weight: 700;
      }

      &-separator {
        border-right: 1px solid #DFDFDF;
        padding-right: $gap * 2;
      }
    }

    &__footer {
      display: none;
    }
  }
}

</style>

<style lang="scss">
.nav {
  @include display-less(desktop) {
    .dropdown {
      width: 100%;
      &-trigger {
        width: 100%;
      }
    }
  }

  @include display(desktop) {
    .dropdown {
      height: 24px;
    }

    .dropdown.is-bottom-left .dropdown-menu {
      margin-top: 50px;

      &::before {
        box-shadow: -2px -2px 13px -1px rgba(14,8,60,0.12);
      }
    }

    .dropdown-content {
      position: relative;
      margin-top: 2px;
      border: none;
      box-shadow: 0 4px 13px -1px rgba(14,8,60,0.12);
      background: #fff;

      & > div {
        position: relative;
        background: #fff;
        z-index: 21;
      }
    }
  }
}
</style>
