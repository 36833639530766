<template lang="pug">
  .page-404
    IeUsersMessage

    .page-404__container
      img.page-404__img(
        alt="404 Image illustration"
        src="/images/404.svg"
      )
      h1.page-404__text(
        v-if="error.statusCode === 404"
      ) {{ $t('pages.404.title') }}
      h1.page-404__text(
        v-else
      ) An error occurred
      RssUiButton.page-404__button(
        rainbow
        :to="$locatedLink('/')"
      ) {{ $t('form.buttons.global__go_to_the_home_page') }}
</template>

<script>
import getMeta from '@/lib/meta'

import IeUsersMessage from '@/components/shared/IeUsersMessage'

export default {
  components: {
    IeUsersMessage
  },

  layout: 'simple',

  props: {
    error: {
      type: Object,
      required: true
    }
  },

  head () {
    return getMeta({
      title: this.meta[this.$i18n.locale].title,
      route: this.$route,
      i18nLocales: this.$i18n.locales,
      i18nSeo: this.$nuxtI18nSeo()
    })
  },

  computed: {
    meta () {
      // @TODO: Change meta data
      return {
        en: {
          title: 'Page not found'
        },
        es: {
          title: 'Página no encontrada'
        },
        it: {
          title: 'Pagina non trovata'
        }
      }
    },
    locale () {
      return this.$i18n.locale
    }
  }
}
</script>

<style lang="scss">
@import '~/assets/styles/_bulma.scss';
@import '~/assets/styles/_base.scss';
@import '~/assets/styles/_content.scss';
</style>

<style lang="scss" scoped>
.page-404 {
  @extend %inner;

  margin: auto;
  position: static;
  text-align: center;

  &__container {
    top: 0;
    left: 0;
    z-index: 2;
    width: 100vw;
    height: 100vh;
    display: flex;
    padding-top: 72px;
    position: absolute;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    // background-color: $colorDarkBackground;

    @include display-less(desktop) {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  &__img {
    height: 55%;
    margin-bottom: 24px;

    @include display-less(desktop) {
      height: auto;
      width: 100%;
    }
  }

  &__text {
    width: 35%;
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    text-align: center;
    margin-bottom: 24px;

    @include display-less (desktop) {
      width: 60;
    }

    @include display-less (tablet) {
      width: 100%;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
</style>
