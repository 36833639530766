<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.0877 6.6875L1.91234 6.6875C1.40851 6.6875 1 7.09601 1 7.59984V18.0872C1 18.591 1.40851 18.9995 1.91234 18.9995H10.7294C10.9949 19.6259 11.6145 20.0684 12.343 20.0684C13.0715 20.0684 13.6911 19.6259 13.9566 18.9995L22.0877 18.9995C22.5915 18.9995 23 18.591 23 18.0872V7.59984C23 7.09601 22.5915 6.6875 22.0877 6.6875Z"
      :fill="`url(#${componentId}_paint0_linear_2042_27992)`"
    />
    <g :filter="`url(#${componentId}_filter0_bi_2042_27992)`">
      <path
        d="M12 17.9998C10.8692 17.5488 9.4144 17.0908 7.69508 16.8132C6.02865 16.5426 4.54739 16.5218 3.35048 16.5912C3.15871 16.605 3 16.4454 3 16.2442V3.4345C3 3.25408 3.12564 3.10142 3.29758 3.0806C5.38722 2.89324 7.97943 2.99733 10.7237 3.98269C11.1734 4.14229 11.5966 4.31577 12 4.50313V17.9998Z"
        :fill="`url(#${componentId}_paint1_linear_2042_27992)`"
        fill-opacity="0.6"
      />
    </g>
    <g :filter="`url(#${componentId}_filter1_bi_2042_27992)`">
      <path
        d="M12 18C13.1308 17.549 14.5856 17.091 16.3049 16.8134C17.9713 16.5428 19.4526 16.522 20.6495 16.5913C20.8413 16.6052 21 16.4456 21 16.2444V3.43469C21 3.25427 20.8744 3.10161 20.7024 3.08079C18.6128 2.8865 16.0206 2.99059 13.2763 3.97595C12.8266 4.13555 12.4034 4.30903 12 4.49638V17.9931V18Z"
        :fill="`url(#${componentId}_paint2_linear_2042_27992)`"
        fill-opacity="0.6"
      />
    </g>
    <defs>
      <filter
        :id="`${componentId}_filter0_bi_2042_27992`"
        x="2.47778"
        y="2.48169"
        width="10.0444"
        height="16.0405"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.26111" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_2042_27992"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_2042_27992"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.047683" dy="0.0238415" />
        <feGaussianBlur stdDeviation="0.119208" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2042_27992"
        />
      </filter>
      <filter
        :id="`${componentId}_filter1_bi_2042_27992`"
        x="11.4778"
        y="2.47778"
        width="10.0444"
        height="16.0444"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.26111" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_2042_27992"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_2042_27992"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.047683" dy="0.0238415" />
        <feGaussianBlur stdDeviation="0.119208" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2042_27992"
        />
      </filter>
      <linearGradient
        :id="`${componentId}_paint0_linear_2042_27992`"
        x1="5.5"
        y1="17.4995"
        x2="28.7788"
        y2="-6.42077"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F2994A" />
        <stop offset="0.5" stop-color="#F2C94C" />
        <stop offset="0.985" stop-color="#FFEBAE" />
      </linearGradient>
      <linearGradient
        :id="`${componentId}_paint1_linear_2042_27992`"
        x1="19"
        y1="8.5"
        x2="8.50665"
        y2="2.43399"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F2994A" />
        <stop offset="0.5" stop-color="#F2C94C" />
        <stop offset="0.985" stop-color="#FFEBAE" />
      </linearGradient>
      <linearGradient
        :id="`${componentId}_paint2_linear_2042_27992`"
        x1="10"
        y1="10"
        x2="21.5"
        y2="10"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F2994A" />
        <stop offset="0.5" stop-color="#F2C94C" />
        <stop offset="0.985" stop-color="#FFEBAE" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    componentId: {
      type: String,
      default: ''
    }
  }
}
</script>
