<template>
  <div class="account__container">
    <div class="account__desktop">
      <BDropdown
        class="account__content-drop"
        aria-role="list"
        position="is-bottom-left"
        animation="none"
        :mobile-modal="false"
        expanded
        @active-change="$emit('change-dropdown-active', $event)"
      >
        <button slot="trigger" class="account__content-drop-trigger">
          <span class="account__content-drop-trigger-text">{{ $t('modules.navigation.account') }}</span>
          <div class="account__content-drop-trigger-avatar">
            <UiAvatar class="account__content-drop-trigger-avatar-image" :size="48" :border="3" :photo="avatarPhotoUrl" />
          </div>
        </button>

        <BDropdownItem class="account__content-drop-dashboard" has-link aria-role="listitem">
          <a class="account__content-drop-dashboard-link" :href="$locatedLink('/', dashboardAppUrl)">
            {{ $t('modules.navigation.dashboard') }}
          </a>
        </BDropdownItem>

        <BDropdownItem class="account__content-drop-subnav" has-link aria-role="listitem">
          <div class="account__content-drop-subnav-trigger" @click.stop="onClickSettingsDropdownItem">
            {{ $t('modules.navigation.account') }}
            <Icon
              name="CaretLeft"
              :class="{ 'account__content-drop-subnav-trigger-icon': true, 'account__content-drop-subnav-trigger-icon-active': isSettingsSubnavOpen }"
            />
          </div>

          <div v-show="isSettingsSubnavOpen" class="account__content-drop-subnav-items">
            <a class="account__content-drop-subnav-item" :href="$locatedLink('/account/', dashboardAppUrl)">
              {{ $t('modules.navigation.my_profile') }}
            </a>
            <a class="account__content-drop-subnav-item" :href="$locatedLink('/subscription/', dashboardAppUrl)">
              {{ $t('modules.navigation.subscription') }}
            </a>
            <a class="account__content-drop-subnav-item" :href="$locatedLink('/privacy/', dashboardAppUrl)">
              {{ $t('modules.navigation.privacy') }}
            </a>
          </div>
        </BDropdownItem>

        <BDropdownItem class="account__content-drop-log-out" has-link aria-role="listitem">
          <a class="account__content-drop-log-out-link" @click.prevent="logOut">
            {{ $t('log_out') }}
          </a>
        </BDropdownItem>
      </BDropdown>
    </div>
    <div class="account__mobile">
      <a class="account__content-drop-dashboard-link" :href="$locatedLink('/', dashboardAppUrl)">
        {{ $t('modules.navigation.dashboard') }}
      </a>
      <div class="account__content-drop-subnav-trigger" @click.stop="onClickSettingsDropdownItem">
        {{ $t('modules.navigation.account') }}
        <Icon
          name="CaretLeft"
          :class="{ 'account__content-drop-subnav-trigger-icon': true, 'account__content-drop-subnav-trigger-icon-active': isSettingsSubnavOpen }"
        />
      </div>

      <div v-show="isSettingsSubnavOpen" class="account__content-drop-subnav-items">
        <a class="account__content-drop-subnav-item" :href="$locatedLink('/account/', dashboardAppUrl)">
          {{ $t('modules.navigation.my_profile') }}
        </a>
        <a class="account__content-drop-subnav-item" :href="$locatedLink('/subscription/', dashboardAppUrl)">
          {{ $t('modules.navigation.subscription') }}
        </a>
        <a class="account__content-drop-subnav-item" :href="$locatedLink('/privacy/', dashboardAppUrl)">
          {{ $t('modules.navigation.privacy') }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { config } from '@/config'

import Icon from '@/components/common/Icon/Icon'

export default {
  components: {
    Icon
  },

  props: {
    openedDropdownId: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      isSettingsSubnavOpen: false,
      dropdownId: 'account'
    }
  },

  computed: {
    ...mapState('auth', ['avatarPhotoUrl']),

    dashboardAppUrl () {
      return config.dashboardAppUrl
    }
  },

  watch: {
    openedDropdownId (openedDropdownId) {
      if (openedDropdownId !== this.dropdownId) {
        this.isSettingsSubnavOpen = false
      }
    }
  },

  methods: {
    ...mapActions('auth', ['signOut']),

    onClickSettingsDropdownItem (e) {
      e.preventDefault()
      this.isSettingsSubnavOpen = !this.isSettingsSubnavOpen
      this.$emit('dropdownOpened', this.dropdownId)
    },

    async logOut () {
      const result = await this.signOut()
      if (result.success) {
        // eslint-disable-next-line
        window.location = this.$locatedLink('/auth/sign-in/', this.dashboardAppUrl)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .dropdown {
  width: 100% !important;
}

/deep/ .dropdown-content {
  top: -20px;
  padding: $gap * 1.5 !important;
  width: max-content !important;
  left: $gap * -4;
  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 8px;
    width: 32px;
    height: 32px;
    border-radius: 4px;
    background: #fff;
    transform: rotate(45deg);
  }
}

.account {
  &__desktop {
    @include display-less(desktop) {
      display: none;
    }
  }
  &__mobile {
    display: none;
    @include display-less(desktop) {
      display: block;
    }
  }
  &__content {
    &-drop {
      &-trigger {
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        border-radius: 50%;
        overflow: hidden;
        transform: translateY(-10px);
        &-text {
          display: none;
        }
        &-avatar {
          border-radius: 50%;
          &-image {
            vertical-align: bottom;
          }
        }
      }

      &-dashboard {
        margin-bottom: $gap * 1.5;
        &-link {
          color: #2d2d2d !important;
          font-weight: 700;
          text-decoration: none;
          padding: 0 !important;
          margin-bottom: $gap * 1.5;
          &:hover {
            background-color: transparent !important;
          }

          @include display-less(desktop) {
            display: inline-block;
          }
        }
      }

      &-subnav {
        &-trigger {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          min-width: 210px;
          font-size: 14px;
          color: #2d2d2d;
          font-weight: 700;
          text-decoration: none;
          cursor: pointer;

          &:hover {
            color: $colorPurple !important;
            @include display-less(desktop) {
              color: inherit !important;
            }
            .account__content-drop-subnav-trigger-icon {
              color: $colorPurple !important;
              @include display-less(desktop) {
                color: #797088 !important;
              }
            }
          }

          @include display-less(desktop) {
            font-size: 16px;
          }

          &-icon {
            color: #797088;
            transform: rotate(180deg);
            transition: transform $speed ease;

            @include display-less(desktop) {
              transform: rotate(270deg);
            }
            &-active {
              transform: rotate(90deg);
            }
          }
        }
        &-items {
          padding-top: $gap * 1.25;
          padding-left: $gap;
          @include display-less(desktop) {
            display: flex;
            flex-direction: column;
          }
        }
        &-item {
          font-size: 16px !important;
          text-decoration: none;
          color: #2d2d2d !important;
          margin-bottom: $gap * 1.5;
          padding: 0 !important;
          font-weight: 400;
          &:hover {
            background-color: transparent !important;
          }
        }
      }

      &-log-out {
        margin-top: $gap * 1.5;
        &-link {
          font-size: 14px;
          color: #2d2d2d !important;
          font-weight: 700;
          text-decoration: none;
          padding: 0 !important;
          &:hover {
            background-color: transparent !important;
          }
        }
      }
    }
  }
}
</style>
