export default {
  async updateAppStatus ({ state, commit, dispatch }) {
    let result

    const previousActiveStatus = state.active

    try {
      result = await dispatch(
        'api/fetch',
        {
          awsStatus: true,
          endpoint: '/v1/status'
        },
        {
          root: true
        }
      )
    } catch (e) {
      return
    }

    const { landing } = result

    if (!landing) {
      return landing
    }

    if (previousActiveStatus !== landing.active) {
      this.$router.push({ query: { changed: true } })
    }

    commit('updateMessage', landing.message)
    commit('updateActiveStatus', landing.active)

    return landing
  }
}
