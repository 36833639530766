<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 256 256"
  >
    <path
      fill="#fff"
      d="M245.675 125.072a8.406 8.406 0 0 1-7.249-4.137c-22.947-38.942-65.247-63.13-110.39-63.13-45.141 0-87.444 24.19-110.394 63.13a8.4 8.4 0 0 1-11.506 2.971C2.14 121.551.81 116.4 3.165 112.401 29.122 68.358 76.97 41 128.036 41c51.066 0 98.913 27.358 124.87 71.401a8.403 8.403 0 0 1-2.974 11.505 8.37 8.37 0 0 1-4.257 1.166Z"
    />
    <path
      fill="#fff"
      d="M205.114 145.926a8.393 8.393 0 0 1-7.249-4.137c-14.513-24.633-41.272-39.933-69.829-39.933-28.557 0-55.313 15.3-69.83 39.936-2.355 4-7.506 5.325-11.503 2.975a8.404 8.404 0 0 1-2.974-11.506c17.525-29.739 49.829-48.21 84.307-48.21s66.782 18.471 84.31 48.207a8.406 8.406 0 0 1-7.232 12.668Z"
    />
    <path
      fill="#fff"
      d="M164.549 166.781a8.394 8.394 0 0 1-7.248-4.137c-6.086-10.327-17.301-16.741-29.266-16.741-11.968 0-23.182 6.414-29.268 16.741a8.403 8.403 0 0 1-14.477-8.531c9.09-15.43 25.854-25.014 43.745-25.014 17.892 0 34.652 9.584 43.746 25.014a8.404 8.404 0 0 1-7.232 12.668Z"
    />
    <rect
      width="48.16"
      height="11.237"
      x="114.982"
      y="172"
      fill="#fff"
      rx="5.619"
      transform="rotate(45 114.982 172)"
    />
    <rect
      width="48.16"
      height="11.237"
      x="107.035"
      y="206.054"
      fill="#fff"
      rx="5.619"
      transform="rotate(-45 107.035 206.054)"
    />
  </svg>
</template>

<script>
export default {}
</script>
