import { config } from '~/config'

export default {
  async fetchGifts ({ dispatch }) {
    let gifts = []
    try {
      gifts = await dispatch(
        'api/fetch',
        {
          endpoint: '/gifts',
          public: true
        },
        { root: true }
      )
    } catch (error) {
      console.log(error)
    }

    return gifts
  },

  async fetchFaqs () {
    let faqs = []
    const endpoint = '/api/v2/solutions/folders/44001241196/articles'
    if (!config.freshdeskSettings.username || !config.freshdeskSettings.password) {
      return faqs
    }

    try {
      const response = await this.$axios.get(`${config.freshdeskSettings.apiUrl}${endpoint}/${this.$i18n.locale}`, {
        auth: {
          username: config.freshdeskSettings.username,
          password: config.freshdeskSettings.password
        }
      })

      faqs = response.data
    } catch (error) {
      console.log(error)
    }

    return faqs
  },

  async createStripeGiftCheckoutSession ({ dispatch }, stripePayload) {
    try {
      const { url } = await dispatch('api/fetch', {
        method: 'POST',
        endpoint: '/v3/gifts/checkout-session',
        public: false,
        data: {
          coupon: stripePayload.coupon,
          gift_price_id: stripePayload.giftPriceId,
          language: stripePayload.language,
          price: stripePayload.price,
          sender_name: stripePayload.senderName,
          sender_email: stripePayload.senderAddress,
          receiver_name: stripePayload.receiverName,
          receiver_email: stripePayload.receiverAddress,
          message: stripePayload.message,
          timestamp_scheduled: stripePayload.timestampScheduled,
          success_url: stripePayload.successUrl,
          cancel_url: stripePayload.cancelUrl
        }
      }, { root: true })

      return url
    } catch (error) {
      console.error(error)
    }
  }
}
