<template>
  <div class="sign-buttons__container">
    <div class="sign-buttons__content">
      <RssUiButton
        rainbow
        class="sign-buttons__content-button"
        :blank="false"
        :text-size="16"
        text-color="#fff"
        data-test-id="headerSignUpButton"
        :href="$locatedLink('/auth/sign-up/', dashboardAppUrl)"
      >
        <span class="sign-buttons__content-button-text">{{ $t('sign_up_free') }}</span>
      </RssUiButton>

      <Link
        is-unstyled
        class="sign-buttons__content-link"
        data-testid="headerSignInButton"
        :href="$locatedLink('/auth/sign-in/', dashboardAppUrl)"
      >
        {{ $t('sign_in') }}
      </Link>
    </div>
  </div>
</template>

<script>
import { config } from '@/config'
import Link from '@/components/common/Link/Link'

export default {
  components: {
    Link
  },
  computed: {
    dashboardAppUrl () {
      return config.dashboardAppUrl
    }
  }

}
</script>

<style lang="scss" scoped>
.sign-buttons {
  &__container {
    @include display-less(desktop) {
      display: none;
    }
  }
  &__content {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $gap;
    &-button {
      flex-shrink: 0;
      &-text {
        font-weight: 700;
      }
    }
    &-link {
      font-weight: 700;
      text-decoration: none;
      color: #ffffff;
      font-size: 14px;
    }
  }
}
</style>
