<template>
  <div class="mobile-footer__container">
    <div class="mobile-footer__content">
      <a
        v-if="isLoggedIn"
        class="mobile-footer__white-button"
        @click.prevent="logOut"
      >

        {{ $t('log_out') }}
      </a>
      <a
        v-if="!isLoggedIn"
        :href="$locatedLink('/auth/sign-in/', dashboardAppUrl)"
        class="mobile-footer__white-button"
      >
        {{ $t('sign_in') }}
      </a>
      <a
        v-if="!isLoggedIn"
        :href="$locatedLink('/auth/sign-up/', dashboardAppUrl)"
        class="mobile-footer__rainbow-button"
      >
        {{ $t("sign_up_for_free") }}
      </a>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { config } from '@/config'

export default {
  computed: {
    ...mapState('auth', ['isLoggedIn']),

    dashboardAppUrl () {
      return config.dashboardAppUrl
    }
  },

  methods: {
    ...mapActions('auth', ['signOut']),

    async logOut () {
      const result = await this.signOut()
      if (result.success) {
        // eslint-disable-next-line
        window.location = this.$locatedLink('/auth/sign-in/', this.dashboardAppUrl)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.mobile-footer {
  &__container {
    padding: $gap;
    background-color: #F7F7F7;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: $gap;
  }

  &__white-button {
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    color: #2D2D2D;
    padding: $gap * 0.5 $gap;
    border: 1px solid #DFDFDF;
    background-color: #FFFFFF;
    border-radius: 4px;
  }

  &__rainbow-button {
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: 700;
    text-decoration: none;
    color: #FFFFFF;
    padding: $gap * 0.5 $gap;
    border-radius: 4px;
    background: linear-gradient(135deg, #F87900, #FE4F45, #E419BA);
  }
}
</style>
