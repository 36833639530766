<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.53571 3C2.58299 3 1 4.58299 1 6.53571V13.6071C1 15.5599 2.58299 17.1429 4.53571 17.1429V18.7812C4.53571 19.7399 5.61926 20.2975 6.39932 19.7403L10.0357 17.1429H16.3214C18.2742 17.1429 19.8571 15.5599 19.8571 13.6071V6.53571C19.8571 4.58299 18.2742 3 16.3214 3H4.53571Z"
      :fill="`url(#${componentId}_paint0_linear_1972_21680)`"
    />
    <g :filter="`url(#${componentId}_filter0_bi_1972_21680)`">
      <path
        d="M20.3482 9.28613C21.8128 9.28613 23 10.4594 23 11.9067V17.1477C23 18.595 21.8128 19.7682 20.3482 19.7682V20.9825C20.3482 21.693 19.5356 22.1063 18.9505 21.6933L16.2232 19.7682H11.5089C10.0444 19.7682 8.85714 18.595 8.85714 17.1477V11.9067C8.85714 10.4594 10.0444 9.28613 11.5089 9.28613H20.3482Z"
        :fill="`url(#${componentId}_paint1_linear_1972_21680)`"
        fill-opacity="0.6"
      />
    </g>
    <defs>
      <filter
        :id="`${componentId}_filter0_bi_1972_21680`"
        x="7.94385"
        y="8.37305"
        width="15.9692"
        height="14.3975"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.456541" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_1972_21680"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1972_21680"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.0456541" dy="0.0228271" />
        <feGaussianBlur stdDeviation="0.114135" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_1972_21680"
        />
      </filter>
      <linearGradient
        :id="`${componentId}_paint0_linear_1972_21680`"
        x1="23.4843"
        y1="0.915108"
        x2="7.17826"
        y2="22.2303"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EEF9FF" />
        <stop offset="0.510667" stop-color="#13E5D5" />
        <stop offset="1" stop-color="#1D52A0" />
      </linearGradient>
      <linearGradient
        :id="`${componentId}_paint1_linear_1972_21680`"
        x1="6.13674"
        y1="7.7409"
        x2="18.1839"
        y2="23.6767"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#EEF9FF" />
        <stop offset="0.510667" stop-color="#13E5D5" />
        <stop offset="1" stop-color="#1D52A0" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    componentId: {
      type: String,
      default: ''
    }
  }
}
</script>
