<template lang="pug">
  .layout.layout_inner
    IeUsersMessage
    Header(
      public
      :scroll-position="scrollPosition"
    )

    main.layout__main
      nuxt

    Footer(
      dashboard
    )

    template(
      v-if="audioPlayerComponent"
    )
      component(
        :is="audioPlayerComponent"
      )

    Cookies

    NoConnectionNotification
</template>

<script>
import { mapGetters } from 'vuex'

import Cookies from '@/components/shared/Cookies'
import Header from '@/components/modules/header'
import Footer from '@/components/modules/footer'
import IeUsersMessage from '@/components/shared/IeUsersMessage'
import NoConnectionNotification from '@/components/shared/content/no-connection-notification'

import AudioPlayer from '@/components/modules/audio-player/AudioPlayer'
import AudioPlayerApi from '@/components/modules/audio-player/AudioPlayerAPI'

export default {
  components: {
    Cookies,
    Header,
    Footer,
    AudioPlayer,
    AudioPlayerApi,
    IeUsersMessage,
    NoConnectionNotification
  },

  data () {
    return {
      scrollPosition: 0,
      audioPlayerComponent: 'AudioPlayer'
    }
  },

  computed: {
    ...mapGetters('auth', ['isLoggedIn'])
  },

  mounted () {
    this.handleResize()

    window.addEventListener('scroll', this.handleScroll)
    window.addEventListener('resize', this.handleResize)

    this.handleScroll()
  },

  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('resize', this.handleResize)

    this.$store.dispatch('window/setScrollPosition', 0)
  },

  methods: {
    handleScroll () {
      this.scrollPosition = window.scrollY
      this.$store.dispatch('window/setScrollPosition', this.scrollPosition)
    },

    handleResize () {
      this.$store.dispatch('window/setScreen', window.innerWidth)
    }
  }
}
</script>

<style lang="scss">
@import '@rss/front-end_ui/dist/style/main.css';
@import '~/assets/styles/_bulma.scss';
@import '~/assets/styles/_base.scss';
@import '~/assets/styles/_content.scss';
</style>

<style lang="scss" scoped>
.layout {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: #1C0C32;
  padding-top: $headerHeight;

  @include display-less(desktop) {
    padding-top: $headerHeightMobile;
  }

  &__main {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
</style>
