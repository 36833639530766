<template lang="pug">
  header(
    :class="classes"
    role="banner"
  )
    transition(
      animation="vuejs-fade-animation"
    )
      .header__top-bg(
        v-if="accountDropdownIsOpened"
      )
    .header__container
      nuxt-link.header__brand(
        data-testid="headerLogoLink"
        :to="$locatedLink('/')"
      )
        RssUiLogo.header__logo(
          :inverse="((scrollPosition > 0 || light) && !public) || (isNavigationOpen && isMobile)"
          :simple="light || dashboard"
        )
          meta(itemprop="name" content="RSS.com")
          meta(itemprop="url" content="https://rss.com")

      // Mobile sign-up button
      .header__mobile-sign-up(v-if="!isLoggedIn && renderNavigation")
        RssUiButton.nav__link.header__mobile-sign-up-button(
          rainbow
          :blank="false"
          :text-size="16"
          text-color="#fff"
          data-test-id="headerSignUpButtonMobile"
          :href="$locatedLink('/auth/sign-up/', dashboardAppUrl)"
        ) {{ $t("sign_up_free") }}

      .header__burger(
        v-if="!light && renderNavigation"
      )
        Burger.burger(
          v-if="isMobile"
          @click="isNavigationOpen = !isNavigationOpen"
          :inverse="isNavigationOpen || (scrollPosition > 0 && !public)"
          :active="isNavigationOpen"
        )
      .header__panel(v-if="!light && renderNavigation")
        .nav(:class="{open: isNavigationOpen}")
          Navigation(
            :dashboard="dashboard"
            :opened="isNavigationOpen"
            @select="closeNavigation"
            @change-dropdown-active="changeDropdownActiveState"
            @close="isNavigationOpen = false"
          )
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import Navigation from './Navigation'
import Burger from '@/components/shared/elements/Burger'

import { config } from '@/config'

export default {
  components: {
    Burger,
    Navigation
  },

  props: {
    light: Boolean,
    dashboard: Boolean,
    public: {
      type: Boolean,
      default: false
    },
    scrollPosition: {
      type: Number,
      default: 0
    },
    renderNavigation: {
      type: Boolean,
      default: true
    }
  },

  data () {
    return {
      mobileTimeout: null,
      isNavigationOpen: false,
      isMenuVisibleOnMobiles: false,
      accountDropdownIsOpened: false
    }
  },

  computed: {
    ...mapState('auth', ['isLoggedIn']),

    ...mapGetters({
      isMobile: 'window/screenType'
    }),

    classes () {
      return [
        'header',
        { header_public: this.public },
        { header_fixed: this.scrollPosition > 0 },
        { header_open: this.isNavigationOpen },
        { header_light: this.light },
        { header_inner: this.dashboard },
        { 'header_account-menu-opened': this.accountDropdownIsOpened }
      ]
    },

    isActiveOnMobiles () {
      if (!this.isMobile || !this.isNavigationOpen) {
        return
      }
      return true
    },

    dashboardAppUrl () {
      return config.dashboardAppUrl
    }
  },

  watch: {
    '$route' () {
      this.isNavigationOpen = false
    },

    isActiveOnMobiles (val) {
      this.mobileTimeout = setTimeout(() => {
        this.isMenuVisibleOnMobiles = val
      }, 200)
    }
  },

  beforeDestory () {
    clearTimeout(this.mobileTimeout)
  },

  methods: {
    closeNavigation () {
      this.isNavigationOpen = false
    },
    changeDropdownActiveState (value) {
      this.accountDropdownIsOpened = value
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  top: 0;
  left: 0;
  z-index: 30;
  width: 100%;
  height: $headerHeight;
  position: fixed;
  background: #1C0C32;

  @include display-less(desktop) {
    height: $headerHeightMobile;
  }

  &__container {
    height: 88px;

    @media screen and (max-width: 1360px) {
      padding: 0 $gap;
    }

    @include display-less(desktop) {
      height: $headerHeightMobile;
    }
  }

  &_public {
    background-color: $colorDarkPrimary;

    .header__container {
      @media screen and (max-width: 1360px) {
        padding: 0 $gap;
      }
    }

    /deep/ .burger.burger_active .burger__icon > span {
      background-color: #000;
    }
  }

  /deep/ .burger {
    @include display-less(desktop) {
      width: 24px;
      height: 24px;

      .burger__icon {
        width: 24px;
        height: 24px;
        flex: 0 0 24px;
        margin-top: 2px;
        margin-left: 2px;

        span {
          height: 2px;
          width: 20px;
          border-radius: 0;
          margin-top: calc((100% / 4) - 2px);

          &.line-3 {
            margin-top: -2px;
          }
        }
      }
    }
  }

  &_open {
    @include display-less(desktop) {
      right: 0;
      bottom: 0;
      height: 100vh;

      &.header_public {
        background: $colorLight!important;
      }

      .header__mobile-sign-up {
        display: none;
      }
    }
  }

  &_fixed {
    background: $colorLight;
    box-shadow: 0 2px 8px rgba(#000, .15);

    &.header_public {
      background: #1C0C32;
    }
  }

  &_light,
  &_inner {
    background: none;

    @include display-less(desktop) {
      position: absolute;
    }
  }

  &_light {
    @include display-less(desktop) {
      position: relative;
      .header__container {
        height: auto;
      }
    }
  }

  &_inner {
    border-bottom: 1px solid #dfdfe1;
    background: $colorLight;
  }

  &__container {
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    max-width: 1900px + $gap * 4;
    height: $headerHeight;
    transition: height $speed;
    padding-left: $gap * 2;
    padding-right: $gap * 2;

    @include display-less(desktop) {
      padding-left: $gap;
      padding-right: $gap;
      height: $headerHeightMobile;
    }

    .header_inner & {
      height: $headerHeight;

      @include display-less(desktop) {
        height: $headerHeightMobile;
      }
    }
  }

  &__brand {
    position: relative;
    text-decoration: none;
    z-index: 200;
  }

  &__logo {
    @include display-less(desktop) {
      width: 140px;
      margin-left: 3px;
    }

    @media screen and (max-width: 350px) {
      width: 110px;
    }
  }

  &__burger {
    top: 27px;
    right: $gap;
    z-index: 210;
    display: none;
    position: absolute;

    @include display-less(desktop) {
      top: 21px;
      right: 20px;
      width: 24px;
      height: 24px;
      display: block;
    }
  }

  &__panel {
    @include display-less(desktop) {
      padding-top: $gap * 6;
      overflow: hidden;
      position: fixed;
      z-index: 100;
      top: 0;
      right: 0;
      left: 100%;
      display: flex;
      height: 100dvh;
      align-items: flex-start;
      justify-content: center;

      .header_open & {
        left: 0;
      }
    }
  }

  &_open {
    @include display-less(desktop) {
      background-color: #fff;
    }
  }

  @include display-less(desktop) {
    &_account-menu-opened {
      &:after {
        top: 0;
        left: 0;
        right: 0;
        content: '';
        z-index: 250;
        height: 88px;
        cursor: pointer;
        position: absolute;
        background-color: rgba(0, 0, 0, .5);
      }

      .header__panel {
        &:before {
          background-color: rgba(0, 0, 0, .5);
        }
      }
    }
  }

  // Mobile Sign-Up button
  &__mobile-sign-up {
    margin-right: 44px;

    @include display(desktop) {
      display: none;
    }

    &-button {
      height: 36px;
      min-height: 36px;
      max-height: 36px;
      border-radius: 8px;
      padding-left: 12px;
      padding-right: 12px;

      ::v-deep .rss-ui-button__text {
        font-weight: 700;
        font-size: 14px!important;
      }
    }
  }
}
.nav {
  @include display-less(desktop) {
    width: 100%;
    height: 100%;
    text-align: left;
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #888;
      border-radius: 5px;
    }
  }
}

</style>
