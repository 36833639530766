<template>
  <div class="feature-item__container">
    <a :href="href" class="feature-item__content">
      <div>
        <GradientIcon :name="iconName" />
      </div>
      <div class="feature-item__content-text">
        <span class="feature-item__content-text-title">{{ title }}</span>
        <span
          v-if="description"
          class="feature-item__content-text-description"
        >
          {{ description }}
        </span>
      </div>
    </a>
  </div>
</template>

<script>
import GradientIcon from '@/components/common/GradientIcon/GradientIcon'
export default {
  components: {
    GradientIcon
  },
  props: {
    iconName: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      default: null
    },
    href: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.feature-item {
  &__content {
    display: flex;
    flex-direction: row;
    gap: $gap * 0.5;
    align-items: center;
    padding: $gap * 0.75;
    text-decoration: none;

    &:hover {
      @include display(desktop) {
        background-color: #1C0C32;
        border-radius: 4px;
      }
    }

    &-text {
      color: #FFFFFF;
      display: flex;
      flex-direction: column;
      gap: $gap * 0.25;
      white-space: nowrap;

      @include display-less(desktop) {
        color: #2D2D2D;
        white-space: normal;
      }

      &-title {
        font-size: 16px;
      }

      &-description {
        width: fit-content;
        font-size: 12px;
      }
    }
  }
}
</style>
