const middleware = {}

middleware['authenticated'] = require('../middleware/authenticated.js')
middleware['authenticated'] = middleware['authenticated'].default || middleware['authenticated']

middleware['languageRedirect'] = require('../middleware/languageRedirect.js')
middleware['languageRedirect'] = middleware['languageRedirect'].default || middleware['languageRedirect']

middleware['maintenance'] = require('../middleware/maintenance.js')
middleware['maintenance'] = middleware['maintenance'].default || middleware['maintenance']

middleware['notAuthenticated'] = require('../middleware/notAuthenticated.js')
middleware['notAuthenticated'] = middleware['notAuthenticated'].default || middleware['notAuthenticated']

middleware['setXFrameOptionsHeader'] = require('../middleware/setXFrameOptionsHeader.js')
middleware['setXFrameOptionsHeader'] = middleware['setXFrameOptionsHeader'].default || middleware['setXFrameOptionsHeader']

export default middleware
