export default function (ctx, initialized) {
  const locale = ctx.app.i18n.locale
  const widgets = ctx.app.i18n.messages[locale].widgets

  if (window.FreshworksWidget) {
    window.FreshworksWidget('setLabels', {
      [locale]: widgets.freshdesk
    })

    window.FreshworksWidget('hide', 'launcher')
  }
}
