<template lang="pug">
  figure.image__wrapper(
    :class="{'image__wrapper_rounded': rounded}"
  )
    .image__spinner(
      v-if="loading"
    )
      RssUiSpinner(
        :size="20"
      )
    img.image__item.lazy-loading-img(
      v-lazy="source"
      :data-srcset="sourceset"
    )
</template>

<script>
export default {
  props: {
    alt: {
      type: String,
      default: ''
    },

    source: {
      type: String,
      required: true
    },

    sourceset: {
      type: String,
      default: ''
    },

    rounded: {
      type: Boolean,
      default: false
    },

    loading: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss" scoped>
.image {
  &__wrapper {
    margin: 0;

    &_rounded {
      width: 100%;
      height: 100%;

      .image__item {
        opacity: 0;
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }

    &_loaded {
      .image__spinner {
        display: none;
      }
      .image__item {
        opacity: 1;
      }
    }
  }

  &__spinner {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
  }

  &__item {
    width: 100%;
    heigh: auto;
    float: left;
    z-index: 3;
    position: relative;
  }
}
</style>
