<template>
  <div>
    <LottieAnimation animation-path="/animations/finger-click.json" />
  </div>
</template>

<script>
import LottieAnimation from '@/components/animations/LottieAnimation'

export default {
  components: {
    LottieAnimation
  }
}
</script>
