<template>
  <div class="dropdown__container">
    <div class="dropdown__content-desktop">
      <BDropdown
        :triggers="['hover']"
        :mobile-modal="false"
        :style="cssVariables"
        aria-role="list"
        @active-change="handleActiveChange"
      >
        <template #trigger>
          <div class="dropdown__content-trigger">
            <Heading size="h6">
              {{ triggerText }}
            </Heading>
            <Icon name="CaretLeft" :class="{ 'dropdown__content-trigger-icon': true, 'dropdown__content-trigger-icon-active': isDesktopActive }" />
          </div>
        </template>
        <BDropdownItem custom paddingless aria-role="listitem">
          <div class="dropdown__content-drop">
            <slot />
          </div>
        </BDropdownItem>
      </BDropdown>
    </div>
    <div class="dropdown__content-mobile">
      <div class="dropdown__content-trigger" @click.prevent="handleMobileClick">
        <Heading size="h6" color="black">
          {{ triggerText }}
        </Heading>
        <Icon name="CaretLeft" color="grey" :class="{ 'dropdown__content-trigger-icon': true, 'dropdown__content-trigger-icon-active': isMobileActive }" />
      </div>
      <div v-show="isMobileActive" class="dropdown__content-drop">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import Heading from '@/components/common/Heading/Heading'
import Icon from '@/components/common/Icon/Icon'

export default {
  components: {
    Heading,
    Icon
  },

  props: {
    triggerText: {
      type: String,
      required: true
    },
    dropdownId: {
      type: String,
      default: null
    },
    openedDropdownId: {
      type: String,
      default: null
    },
    leftOffset: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      isDesktopActive: false,
      isMobileActive: false
    }
  },

  computed: {
    cssVariables () {
      return {
        '--left-offset': `${this.leftOffset}px`
      }
    }
  },

  watch: {
    openedDropdownId (openedDropdownId) {
      if (openedDropdownId !== this.dropdownId) {
        this.isMobileActive = false
      }
    }
  },

  methods: {
    handleActiveChange (isActive) {
      this.isDesktopActive = isActive
    },
    handleMobileClick () {
      this.isMobileActive = !this.isMobileActive
      this.$emit('dropdownOpened', this.dropdownId)
    }
  }
}
</script>

<style lang="scss" scoped>
/deep/ .dropdown-menu {
  transform: translate(-50%, 0);
  left: var(--left-offset);
  top: 22px;
  width: max-content !important;
}

.dropdown-item {
  background: unset !important;
}

/deep/ .dropdown-content {
  top: 20px;
  background: unset !important;
}

.dropdown {
  &__content {
    &-desktop {
      @include display-less(desktop) {
        display: none;
      }
    }

    &-mobile {
      display: none;
      @include display-less(desktop) {
        display: block;
      }
    }
    &-trigger {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: $gap * 0.5;
      cursor: pointer;
      @include display-less(desktop) {
        width: 100%;
        justify-content: space-between;
      }

      &-icon {
        transform: rotate(270deg);
        transition: transform $speed ease;

        &-active {
          transform: rotate(90deg);
        }
      }
    }
    &-drop {
      margin: 0;
      padding: $gap * 1.5;
      background-color: #25163a;
      border-radius: 0 0 24px 24px;

      @include display-less(desktop) {
        padding: 0;
        margin-top: $gap;
        background-color: unset;
        border-radius: unset;
      }
    }
  }
}
</style>
