<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g :clip-path="`url(#${componentId}_clip0_1972_21629)`">
      <path
        d="M10.5189 19.2502C10.7335 15.0135 12.7877 11 17.25 11C21.7123 11 23.7665 15.0135 23.9811 19.2502C24.0021 19.6639 23.6642 20 23.25 20H11.25C10.8358 20 10.4979 19.6639 10.5189 19.2502Z"
        :fill="`url(#${componentId}_paint0_linear_1972_21629)`"
      />
      <path
        d="M0.0188933 19.2502C0.23351 15.0135 2.28769 11 6.75 11C11.2123 11 13.2665 15.0135 13.4811 19.2502C13.5021 19.6639 13.1642 20 12.75 20H0.75C0.335786 20 -0.00206247 19.6639 0.0188933 19.2502Z"
        :fill="`url(#${componentId}_paint1_linear_1972_21629)`"
      />
      <circle
        cx="17.25"
        cy="5.75"
        r="3.75"
        :fill="`url(#${componentId}_paint2_linear_1972_21629)`"
      />
      <circle
        cx="17.25"
        cy="5.75"
        r="3.75"
        :fill="`url(#${componentId}_paint3_linear_1972_21629)`"
      />
      <circle
        cx="3.75"
        cy="3.75"
        r="3.75"
        transform="matrix(-1 0 0 1 10.5 2)"
        :fill="`url(#${componentId}_paint4_linear_1972_21629)`"
      />
      <g filter="url(#filter0_bi_1972_21629)">
        <path
          d="M4.52099 20.7503C4.75943 16.5136 7.04185 12.5 12 12.5C16.9582 12.5 19.2406 16.5136 19.479 20.7503C19.5023 21.1639 19.1642 21.5 18.75 21.5H5.25C4.83579 21.5 4.49771 21.1639 4.52099 20.7503Z"
          :fill="`url(#${componentId}_paint5_linear_1972_21629)`"
          fill-opacity="0.6"
        />
      </g>
      <g filter="url(#filter1_bi_1972_21629)">
        <circle
          cx="12"
          cy="6.5"
          r="4.5"
          :fill="`url(#${componentId}_paint6_radial_1972_21629)`"
          fill-opacity="0.6"
        />
      </g>
    </g>
    <defs>
      <filter
        :id="`${componentId}_filter0_bi_1972_21629`"
        x="2.19581"
        y="10.1758"
        width="19.6084"
        height="13.6484"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.16211" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_1972_21629"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1972_21629"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.0456541" dy="0.0228271" />
        <feGaussianBlur stdDeviation="0.114135" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_1972_21629"
        />
      </filter>
      <filter
        :id="`${componentId}_filter1_bi_1972_21629`"
        x="5.17579"
        y="-0.32421"
        width="13.6484"
        height="13.6484"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.16211" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_1972_21629"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1972_21629"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.0456541" dy="0.0228271" />
        <feGaussianBlur stdDeviation="0.114135" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_1972_21629"
        />
      </filter>
      <linearGradient
        :id="`${componentId}_paint0_linear_1972_21629`"
        x1="10.5"
        y1="15.5"
        x2="24"
        y2="15.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9BF8F4" />
        <stop offset="1" stop-color="#6F7BF7" />
      </linearGradient>
      <linearGradient
        :id="`${componentId}_paint1_linear_1972_21629`"
        x1="0"
        y1="15.5"
        x2="13.5"
        y2="15.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9BF8F4" />
        <stop offset="1" stop-color="#6F7BF7" />
      </linearGradient>
      <linearGradient
        :id="`${componentId}_paint2_linear_1972_21629`"
        x1="8.25252"
        y1="7.95116"
        x2="17.4428"
        y2="15.9184"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F8B700" />
        <stop offset="0.51" stop-color="#F87900" />
        <stop offset="1" stop-color="#AE35DD" />
      </linearGradient>
      <linearGradient
        :id="`${componentId}_paint3_linear_1972_21629`"
        x1="13.5"
        y1="5.75"
        x2="21"
        y2="5.75"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9BF8F4" />
        <stop offset="1" stop-color="#6F7BF7" />
      </linearGradient>
      <linearGradient
        :id="`${componentId}_paint4_linear_1972_21629`"
        x1="0"
        y1="3.75"
        x2="7.5"
        y2="3.75"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9BF8F4" />
        <stop offset="1" stop-color="#6F7BF7" />
      </linearGradient>
      <linearGradient
        :id="`${componentId}_paint5_linear_1972_21629`"
        x1="4.5"
        y1="17"
        x2="19.5"
        y2="17"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#9BF8F4" />
        <stop offset="1" stop-color="#6F7BF7" />
      </linearGradient>
      <radialGradient
        :id="`${componentId}_paint6_radial_1972_21629`"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(12 6.5) scale(6)"
      >
        <stop stop-color="#9BF8F4" />
        <stop offset="1" stop-color="#6F7BF7" />
      </radialGradient>
      <clipPath :id="`${componentId}_clip0_1972_21629`">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    componentId: {
      type: String,
      default: ''
    }
  }
}
</script>
