<template lang="pug">
  .lang-switcher(
    :class="{ active: isActive, links: links }"
  )
    img.lang-switcher__icon(
      src="/images/icons/planet.png"
    )
    BSelect.lang-switcher__selector(
      v-model="cuLoc"
      data-testid="dropdownChangeLanguage"
      @input="selectLocale"
    )
      option(
        v-for="locale in availableLocales"
        :key="locale.code"
        :value="locale.code"
      )
        template {{ locale.name }}&nbsp;
        template(
          v-if="locale.code === 'es'"
        )
</template>

<script>
import { config } from '@/config'

export default {
  props: {
    links: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isActive: false,
      cuLoc: this.$i18n.locale
    }
  },

  computed: {
    baseUrl () {
      return config.baseUrl
    },

    availableLocales () {
      return this.$i18n.locales
    },

    currentLocale () {
      return this.$i18n.locales.find(i => i.code === this.$i18n.locale)
    }
  },

  watch: {
    '$route' () {
      this.isActive = false
    }
  },

  methods: {
    setState (val) {
      this.isActive = val
    },

    selectLocale (locale) {
      const newPath = this.switchLocalePath(locale)
      window.location.href = this.baseUrl + newPath
    }
  }
}
</script>

<style lang="scss">
.lang-switcher {
  .dropdown-menu {
    min-width: 50px !important;
    margin-top: 12px !important;
  }
}
</style>

<style lang="scss">
.locale-link {
  color: #fff;
  cursor: pointer;
  margin-right: $gap;
  display: inline-block;
  text-decoration: none;
  text-transform: uppercase;
}

.lang-switcher {
  display: flex;
  position: relative;
  align-items: center;

  &__container {
    margin-left: $gap * 0.5;
  }

  .select {
    select {
      width: 90px;
      border: none;
      min-width: 90px;
      border-radius: 0;
      background: none;
      box-shadow: none;
      color: $colorLight;
      padding: 4px 10px 4px 0;
      border-width: 0 0 1px 0;
      outline: none !important;
      padding-right: 10px!important;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &:active {
        border: none;
        box-shadow: none;
      }
    }

    &::after {
      right: 2px !important;
      border-color: $colorLight !important;
    }
  }

  &__icon {
    margin-right: $gap * 0.5;
  }
}
</style>
