<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g :clip-path="`url(#${componentId}_clip0_1972_21675)`">
      <path
        d="M18.4716 6.18981V8.11286C18.4716 8.61044 18.0596 9.01428 17.552 9.01428C17.0444 9.01428 16.6324 8.61044 16.6324 8.11286V6.18981C16.6324 3.77098 14.6246 1.80286 12.1569 1.80286C9.68932 1.80286 7.68151 3.77098 7.68151 6.18981V8.11286C7.68151 8.61044 7.26952 9.01428 6.7619 9.01428C6.25427 9.01428 5.84229 8.61044 5.84229 8.11286V6.18981C5.84229 2.777 8.6753 0 12.1569 0C15.6386 0 18.4716 2.777 18.4716 6.18981Z"
        :fill="`url(#${componentId}_paint0_linear_1972_21675)`"
        fill-opacity="0.7"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M19.5141 9.91711V19.3003C19.3744 19.0884 19.1313 18.9481 18.8548 18.9481C18.4216 18.9481 18.0704 19.2924 18.0704 19.717C18.0704 20.1416 18.4216 20.4858 18.8548 20.4858C19.1313 20.4858 19.3744 20.3456 19.5141 20.1337V20.9145H4.80029V20.1125C4.93745 20.3362 5.18741 20.4858 5.47301 20.4858C5.9062 20.4858 6.25736 20.1416 6.25736 19.717C6.25736 19.2923 5.9062 18.9481 5.47301 18.9481C5.18741 18.9481 4.93745 19.0978 4.80029 19.3214V9.91711C4.80029 8.92134 5.62365 8.11426 6.63951 8.11426H17.6748C18.6907 8.11426 19.5141 8.92134 19.5141 9.91711ZM16.2936 19.717C16.2936 20.1416 15.9424 20.4858 15.5092 20.4858C15.076 20.4858 14.7249 20.1416 14.7249 19.717C14.7249 19.2924 15.076 18.9481 15.5092 18.9481C15.9424 18.9481 16.2936 19.2924 16.2936 19.717ZM12.1639 20.4853C12.597 20.4853 12.948 20.1412 12.948 19.7167C12.948 19.2922 12.597 18.9481 12.1639 18.9481C11.7309 18.9481 11.3798 19.2922 11.3798 19.7167C11.3798 20.1412 11.7309 20.4853 12.1639 20.4853ZM9.60295 19.717C9.60295 20.1416 9.25178 20.4858 8.81859 20.4858C8.38541 20.4858 8.03424 20.1416 8.03424 19.717C8.03424 19.2924 8.38541 18.9481 8.81859 18.9481C9.25178 18.9481 9.60295 19.2924 9.60295 19.717ZM13.077 13.9295V12.0065C13.077 11.5089 12.665 11.105 12.1573 11.105C11.6497 11.105 11.2377 11.5089 11.2377 12.0065V13.9295C11.2377 14.4271 11.6497 14.8309 12.1573 14.8309C12.665 14.8309 13.077 14.4271 13.077 13.9295Z"
        :fill="`url(#${componentId}_paint1_linear_1972_21675)`"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.1572 20.9145H4.80029V20.1125C4.93745 20.3362 5.18741 20.4858 5.47301 20.4858C5.9062 20.4858 6.25736 20.1416 6.25736 19.717C6.25736 19.2923 5.9062 18.9481 5.47301 18.9481C5.18741 18.9481 4.93745 19.0978 4.80029 19.3214V9.91711C4.80029 8.92134 5.62365 8.11426 6.63951 8.11426H12.1572V11.105C11.6496 11.1051 11.2377 11.5089 11.2377 12.0065V13.9295C11.2377 14.427 11.6496 14.8308 12.1572 14.8309V18.9482C11.7272 18.9517 11.3798 19.2944 11.3798 19.7167C11.3798 20.139 11.7272 20.4817 12.1572 20.4853V20.9145ZM9.60295 19.717C9.60295 20.1416 9.25178 20.4858 8.81859 20.4858C8.38541 20.4858 8.03424 20.1416 8.03424 19.717C8.03424 19.2924 8.38541 18.9481 8.81859 18.9481C9.25178 18.9481 9.60295 19.2924 9.60295 19.717Z"
        :fill="`url(#${componentId}_paint2_linear_1972_21675)`"
      />
      <g :filter="`url(#${componentId}_filter0_bi_1972_21675)`">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M22.2002 22.33V17.2061C22.2002 16.3571 21.4981 15.6689 20.632 15.6689H3.69562C2.82946 15.6689 2.12744 16.3571 2.12744 17.2061V22.33C2.12744 23.1791 2.82946 23.8672 3.69562 23.8672H20.632C21.4981 23.8672 22.2002 23.1791 22.2002 22.33ZM19.639 19.7163C19.639 20.1409 19.2879 20.4852 18.8547 20.4852C18.4215 20.4852 18.0703 20.1409 18.0703 19.7163C18.0703 19.2917 18.4215 18.9475 18.8547 18.9475C19.2879 18.9475 19.639 19.2917 19.639 19.7163ZM15.5091 20.4852C15.9423 20.4852 16.2934 20.1409 16.2934 19.7163C16.2934 19.2917 15.9423 18.9475 15.5091 18.9475C15.0759 18.9475 14.7247 19.2917 14.7247 19.7163C14.7247 20.1409 15.0759 20.4852 15.5091 20.4852ZM12.9479 19.7161C12.9479 20.1405 12.5968 20.4847 12.1638 20.4847C11.7307 20.4847 11.3797 20.1405 11.3797 19.7161C11.3797 19.2916 11.7307 18.9475 12.1638 18.9475C12.5968 18.9475 12.9479 19.2916 12.9479 19.7161ZM8.81847 20.4852C9.25166 20.4852 9.60282 20.1409 9.60282 19.7163C9.60282 19.2917 9.25166 18.9475 8.81847 18.9475C8.38529 18.9475 8.03412 19.2917 8.03412 19.7163C8.03412 20.1409 8.38529 20.4852 8.81847 20.4852ZM6.25724 19.7163C6.25724 20.1409 5.90608 20.4852 5.47289 20.4852C5.0397 20.4852 4.68854 20.1409 4.68854 19.7163C4.68854 19.2917 5.0397 18.9475 5.47289 18.9475C5.90608 18.9475 6.25724 19.2917 6.25724 19.7163Z"
          fill="#77FFDB"
          fill-opacity="0.5"
        />
      </g>
    </g>
    <defs>
      <filter
        :id="`${componentId}_filter0_bi_1972_21675`"
        x="-0.196769"
        y="13.3447"
        width="24.7212"
        height="12.8467"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.16211" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_1972_21675"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1972_21675"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.116211" dy="0.0581053" />
        <feGaussianBlur stdDeviation="0.290526" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_1972_21675"
        />
      </filter>
      <linearGradient
        :id="`${componentId}_paint0_linear_1972_21675`"
        x1="10.5001"
        y1="-2.5"
        x2="10.0001"
        y2="11.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#CBFFF1" />
        <stop offset="0.510667" stop-color="#1DFFC3" />
        <stop offset="1" stop-color="#0A4C3B" />
      </linearGradient>
      <linearGradient
        :id="`${componentId}_paint1_linear_1972_21675`"
        x1="11.5003"
        y1="4.00049"
        x2="11.5003"
        y2="22.0005"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#CBFFF1" />
        <stop offset="0.510667" stop-color="#1DFFC3" />
        <stop offset="1" stop-color="#0A4C3B" />
      </linearGradient>
      <linearGradient
        :id="`${componentId}_paint2_linear_1972_21675`"
        x1="13.5003"
        y1="11.0005"
        x2="-1.49971"
        y2="22.5005"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#CBFFF1" />
        <stop offset="0.551244" stop-color="#1DFFC3" />
        <stop offset="1" stop-color="#0A4C3B" />
      </linearGradient>
      <clipPath :id="`${componentId}_clip0_1972_21675`">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    componentId: {
      type: String,
      default: ''
    }
  }
}
</script>
