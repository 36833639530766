<template lang="pug">
article.ui-article
  .ui-article__container
    header.ui-article__header(
      v-if="$slots.header || title || notice"
    )
      slot(name="upper-header")
      h1(
        v-html="title"
        class="ui-article__title"
      )
      p(
        v-html="notice"
        class="ui-article__notice"
      )
      slot(name="header")

    .ui-article__content
      slot
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: undefined
    },
    notice: {
      type: String,
      default: undefined
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-article {
  flex: 1;
  background-color: #fff;

  &__container {
    @extend %inner;
    max-width: 946px;
    padding-top: 56px;
    padding-bottom: 56px;
  }

  &__header {
    margin: 0 0 $gap*2;
  }

  &__title {
    margin: 0;
  }

  &__notice {
    margin: 0;
    color: $colorTextSecondary;
    font-style: italic;
  }

  &__content {
    /deep/ ul {
      width: 100%;
      list-style: disk;
      padding-left: 32px;

      li {
        list-style: disk;
      }
    }

    /deep/ ul, /deep/ ol {
      padding-left: 24px;
    }

    /deep/ li {
      margin: 8px 0;
    }
  }

  h4 {
    margin: $gap*2 0 $gap;
    font-weight: 600;
  }
}
</style>
