<template>
  <component :is="size" :class="classes">
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    size: {
      type: String,
      default: 'h1',
      validator: (value) => {
        return ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(value)
      }
    },
    color: {
      type: String,
      default: 'white',
      validator: (value) => {
        return ['white', 'orange', 'black'].includes(value)
      }
    }
  },

  computed: {
    classes () {
      return [
        'commonHeading',
        `commonHeading_${this.size ? this.size : 'h1'}`,
        `commonHeading_${this.color ? this.color : 'white'}`
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.commonHeading {
  font-weight: 700;
  margin: 0;

  &_h1 {
    font-size: 40px;
    line-height: 48px;
  }

  &_h2 {
    font-size: 32px;
    line-height: 38px;
  }

  &_h3 {
    font-size: 28px;
    line-height: 34px;
  }

  &_h4 {
    font-size: 24px;
    line-height: 28px;
  }

  &_h5 {
    font-size: 20px;
    line-height: 24px;
  }

  &_h6 {
    font-size: 16px;
    line-height: 20px;
  }

  &_white {
    color: #fff;
  }

  &_black {
    color: #2D2D2D;
  }

  &_orange {
    background: linear-gradient(130.92deg, #F8B700 -0.63%, #F87900 58%, #AE35DD 114.33%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
</style>
