export default {
  setPodcasts (state, payload) {
    const podcasts = payload.map((podcast) => {
      podcast.onboardingStats = {}
      return podcast
    })
    state.podcasts = podcasts
  },

  removePodcast (state, pid) {
    const newPodcastsList = []
    state.podcasts.forEach((podcast) => {
      if (parseInt(podcast.id) !== parseInt(pid)) {
        newPodcastsList.push(podcast)
      }
    })
    state.podcasts = newPodcastsList
  },

  setPodcastsLoadingStatus (state, payload) {
    state.isPodcastsLoading = payload
  },

  setItunesCategories (state, categories) {
    const grouped = false
    const groupedCategories = []
    categories.forEach((category) => {
      const categoryLabel = category.label
      const groupName = categoryLabel.split(':')[0].replace('&amp;', '&')
      const groupElementName = categoryLabel.split(':')[1]

      if (!grouped) {
        if (!categoryLabel.includes('DEPRECATED')) {
          const categoryType = groupElementName ? 'element' : 'group'
          category.type = categoryType

          if (categoryType === 'element') {
            category.group = groupName
            category.name = groupElementName.replace('&amp;', '&')
          } else {
            category.name = categoryLabel.replace('&amp;', '&')
          }

          groupedCategories.push(category)
        }
      }

      if (grouped) {
        if (!groupElementName) {
          if (!groupName.includes('DEPRECATED')) {
            category.name = groupName

            groupedCategories.push({
              category,
              name: groupName,
              items: [category]
            })
          }
        } else {
          const group = groupedCategories.find((g) => {
            return g.name === groupName
          })
          if (group && !groupElementName.includes('DEPRECATED')) {
            category.name = groupElementName.replace('&amp;', '&')
            group.items.push(category)
          }
        }
      }
    })
    state.itunesCategories = groupedCategories
  },

  updatePodcastDetails (state, payload) {
    const podcasts = [...state.podcasts]
    podcasts.forEach((podcast) => {
      if (podcast.id === payload.pid) {
        podcast.details = payload.data
      }
    })
    state.podcasts = [...podcasts]
  },

  updatePodcastEpisodes (state, payload) {
    const data = payload.data
    const podcasts = [...state.podcasts]
    podcasts.forEach((podcast) => {
      if (podcast.id === payload.pid && data && data.items) {
        const episodes = payload.data.items
        const sortedPublishedEpisodes = episodes
        // .sort((a, b) => {
        //   const dateA = new Date(a.timestamp_created).getTime()
        //   const dateB = new Date(b.timestamp_created).getTime()
        //   return dateB - dateA
        // })
        podcast.episodes = sortedPublishedEpisodes
      }
    })
    state.podcasts = [...podcasts]
  },

  updatePodcastCategories (state, payload) {
    const data = payload.data
    const podcasts = [...state.podcasts]
    podcasts.forEach((podcast) => {
      if (podcast.id === payload.pid && data) {
        const updatedCategories = data.map((cat) => {
          const name = cat.label.split(':')
          if (name[1]) {
            cat.name = name[1].replace('&amp;', '&')
          } else {
            cat.name = name[0].replace('&amp;', '&')
          }
          return cat
        })
        podcast.categories = updatedCategories
      }
    })
    state.podcasts = [...podcasts]
  },

  extendPodcastsWithDetails (state, podcastsDetails) {
    const podcasts = [...state.podcasts]
    podcasts.forEach((podcast) => {
      const podcastDetails = podcastsDetails.find((details) => {
        if (details && (parseInt(details.podcast_id) === parseInt(podcast.id))) {
          return true
        }
        return false
      })
      podcast.details = podcastDetails || null
    })
    state.podcasts = [...podcasts]
  },

  extendPodcastsWithEpisodes (state, podcastsEpisodes) {
    const podcasts = [...state.podcasts]
    podcasts.forEach((podcast) => {
      const podcastEpisodes = podcastsEpisodes.find((episodes) => {
        if (episodes && (episodes.pid === podcast.id)) {
          return true
        }
        return false
      })
      if (!podcastEpisodes || !podcastsEpisodes.items) {
        podcast.episodes = null
      } else {
        const sortedEpisodes = podcastsEpisodes.items
        // .sort((a, b) => {
        //   const dateA = new Date(a.timestamp_created).getTime()
        //   const dateB = new Date(b.timestamp_created).getTime()
        //   return dateB - dateA
        // })
        podcast.episodes = sortedEpisodes
      }
    })
    state.podcasts = [...podcasts]
  },

  removeEpisodeFromPodcast (state, payload) {
    const pid = payload.pid
    const eid = payload.eid
    const podcasts = [...state.podcasts]
    podcasts.forEach((podcast) => {
      if (parseInt(podcast.id) === parseInt(pid)) {
        const delitingEpisodeIndex = podcast.episodes.findIndex((episode) => {
          return parseInt(episode.id) === parseInt(eid)
        })
        if (delitingEpisodeIndex >= 0) {
          podcast.episodes.splice(delitingEpisodeIndex, 1)
        }
      }
    })
    state.podcasts = [...podcasts]
  },

  extendPodcastEpisodesWithKeywords (state, payload) {
    const podcasts = [...state.podcasts]
    const pid = payload.pid
    const eid = payload.eid
    const keywords = payload.keywords

    const podcast = podcasts.find(p => parseInt(p.id) === parseInt(pid))
    const episode = podcast.episodes.find(e => parseInt(e.id) === parseInt(eid))

    episode.keywords = keywords || []

    state.podcasts = [...podcasts]
  },

  setPodcastOnboardingStat (state, payload) {
    const podcasts = [...state.podcasts]

    const pid = payload.pid
    const field = payload.field
    const value = payload.value

    podcasts.map((podcast) => {
      if (podcast.id !== pid) {
        return podcasts
      }

      if (!podcast.onboardingStats) {
        podcast.onboardingStats = {}
      }

      podcast.onboardingStats[field] = value
      return podcast
    })

    state.podcasts = [...podcasts]
  }
}
