<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g :clip-path="`url(#${componentId}_clip0_1972_21665)`">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M22.385 1.76555C22.125 1.50055 19.735 1.46555 16.92 2.29053C14.5096 2.95917 12.3033 4.21694 10.5 5.95053C9.24245 7.19077 8.1425 8.58124 7.22503 10.0905L7.22979 10.0907C6.36407 11.4501 5.77616 12.9675 5.5 14.5553C6.43459 14.9101 7.28304 15.4596 7.98905 16.1674C8.69506 16.8751 9.24249 17.7249 9.595 18.6603C11.1822 18.3842 12.6983 17.7944 14.0549 16.9254L14.055 16.9305C15.5628 16.0128 16.9531 14.9147 18.195 13.6605C19.93 11.8544 21.1879 9.64454 21.855 7.23053C22.385 5.1453 22.6449 2.03055 22.385 1.76555ZM19.014 8.52701C19.9395 7.60147 19.9395 6.10087 19.014 5.17532C18.0884 4.24978 16.5878 4.24978 15.6623 5.17532C14.7367 6.10087 14.7367 7.60147 15.6623 8.52701C16.5878 9.45255 18.0884 9.45255 19.014 8.52701Z"
        :fill="`url(#${componentId}_paint0_linear_1972_21665)`"
      />
      <path
        d="M7.31006 16.8395C3.94506 13.4745 3.62506 20.5245 3.31006 20.8395C3.62506 20.4995 10.6751 20.1995 7.31006 16.8395Z"
        :fill="`url(#${componentId}_paint1_linear_1972_21665)`"
      />
      <g :filter="`url(#${componentId}_filter0_b_1972_21665)`">
        <path
          d="M1.20516 11.8901L6.40016 6.69512C6.44632 6.64801 6.50922 6.62106 6.57516 6.62012H12.5002L6.81016 12.3151H1.38516C1.33623 12.3144 1.28858 12.2994 1.24811 12.2719C1.20764 12.2444 1.17613 12.2056 1.1575 12.1603C1.13887 12.1151 1.13393 12.0654 1.14329 12.0173C1.15265 11.9693 1.1759 11.9251 1.21016 11.8901H1.20516ZM11.8302 17.3401L17.5252 11.6451V17.5701C17.5242 17.6361 17.4973 17.699 17.4502 17.7451L12.2602 22.9451C12.2252 22.9794 12.181 23.0026 12.133 23.012C12.0849 23.0214 12.0352 23.0164 11.9899 22.9978C11.9447 22.9791 11.9059 22.9476 11.8784 22.9072C11.8509 22.8667 11.8358 22.819 11.8352 22.7701L11.8302 17.3401Z"
          :fill="`url(#${componentId}_paint2_linear_1972_21665)`"
          fill-opacity="0.8"
        />
        <path
          d="M1.44826 11.7901H1.44658L6.47087 6.76583L6.47088 6.76583L6.47159 6.76511C6.49914 6.73699 6.53662 6.72084 6.57596 6.72012H12.2589L6.76871 12.2151H1.38597C1.35682 12.2146 1.32845 12.2056 1.30433 12.1892C1.28005 12.1727 1.26115 12.1494 1.24997 12.1223C1.23879 12.0951 1.23583 12.0653 1.24144 12.0365C1.24706 12.0076 1.26101 11.9811 1.28156 11.9601L1.44826 11.7901ZM17.3802 17.6737L17.3794 17.6745L12.1902 22.8737C12.19 22.8738 12.1899 22.8739 12.1898 22.874C12.1689 22.8944 12.1425 22.9082 12.1138 22.9138C12.085 22.9195 12.0552 22.9165 12.028 22.9053C12.0009 22.8941 11.9776 22.8752 11.9611 22.8509C11.9447 22.8268 11.9357 22.7985 11.9352 22.7693C11.9352 22.7691 11.9352 22.7689 11.9352 22.7687L11.9302 17.3815L17.4252 11.8865V17.5694C17.4244 17.6087 17.4083 17.6462 17.3802 17.6737Z"
          :stroke="`url(#${componentId}_paint3_linear_1972_21665)`"
          stroke-width="0.2"
        />
      </g>
    </g>
    <defs>
      <filter
        :id="`${componentId}_filter0_b_1972_21665`"
        x="-0.861328"
        y="4.62012"
        width="20.3867"
        height="20.3965"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="1" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_1972_21665"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1972_21665"
          result="shape"
        />
      </filter>
      <linearGradient
        :id="`${componentId}_paint0_linear_1972_21665`"
        x1="4.52349"
        y1="18.9445"
        x2="16.3459"
        y2="13.9224"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.43" stop-color="#E74C3C" />
        <stop offset="0.96" stop-color="#FF9B91" />
      </linearGradient>
      <linearGradient
        :id="`${componentId}_paint1_linear_1972_21665`"
        x1="2.74986"
        y1="21.0022"
        x2="9.52848"
        y2="18.1176"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.43" stop-color="#E74C3C" />
        <stop offset="0.96" stop-color="#FF9B91" />
      </linearGradient>
      <linearGradient
        :id="`${componentId}_paint2_linear_1972_21665`"
        x1="-0.731761"
        y1="23.5602"
        x2="21.9053"
        y2="13.9332"
        gradientUnits="userSpaceOnUse"
      >
        <stop />
        <stop offset="0.43" stop-color="#E74C3C" />
        <stop offset="0.96" stop-color="#FF9B91" />
      </linearGradient>
      <linearGradient
        :id="`${componentId}_paint3_linear_1972_21665`"
        x1="3.74713"
        y1="8.53033"
        x2="14.2077"
        y2="21.4781"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" stop-opacity="0.25" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
      <clipPath :id="`${componentId}_clip0_1972_21665`">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    componentId: {
      type: String,
      default: ''
    }
  }
}
</script>
