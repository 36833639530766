<template lang="pug">
.info
  .info__overlay(
    v-if="!isFullScreen"
    @click="$emit('fullScreenChange', !isFullScreen)"
  )
  img.info__cover(
    v-if="!isFullScreen"
    :src="chapterCoverUrl.default"
    :srcset="chapterCoverUrl.retina + ' 2x'"
  )
  .info__text
    .info__text-desktop
      ScrollingText.info__text-desktop-title(
        :rawValue="episodeTitle"
        :isFullScreen="isFullScreen"
        :isChaptersViewOpen="isChaptersViewOpen"
      )
        nuxt-link.info__text-desktop-title-content(
          :to="$locatedLink('/podcasts/' + podcastSlug + '/' + episodeId + '/')"
        )
          h3 {{ episodeTitle }}
    .info__text-mobile(
      v-if="!isFullScreen || !isChaptersViewOpen"
    )
      .info__text-mobile-chapter(
        v-if="getCurrentChapter() && isFullScreen"
      )
        h3.info__text-mobile-chapter-subtitle {{ `${$t('chapter_playing').toUpperCase()} #${getCurrentChapterIndex() + 1}` }}
        ScrollingText.info__text-mobile-chapter-title(
          :rawValue="currentChapterTitle"
          :isFullScreen="isFullScreen"
          :isChaptersViewOpen="isChaptersViewOpen"
        )
          h3.info__text-mobile-chapter-title-content {{ currentChapterTitle }}
        a.info__text-mobile-chapter-link(
          v-if="currentChapterLink"
          target="_blank"
          :href="currentChapterLink"
        )
          LinkIcon.info__text-mobile-chapter-link-icon
          span {{ $t('single_words.link') }}
      .info__text-mobile-episode(
        v-else
      )
        ScrollingText.info__text-mobile-episode-title(
          :rawValue="episodeTitle"
          :isFullScreen="isFullScreen"
          :isChaptersViewOpen="isChaptersViewOpen"
        )
          nuxt-link.info__text-mobile-episode-title-content(
            :class="{'info__text-mobile-episode-title-content-full-screen': isFullScreen}"
            :to="$locatedLink('/podcasts/' + podcastSlug + '/' + episodeId + '/')"
          )
            h3 {{ episodeTitle }}
    ScrollingText.info__text-subtitle(
      v-if="!isFullScreen"
      :rawValue="podcastInfo"
      :isFullScreen="isFullScreen"
      :isChaptersViewOpen="isChaptersViewOpen"
    )
      nuxt-link.info__text-subtitle-content(
        :to="$locatedLink('/podcasts/' + podcastSlug + '/')"
      ) {{ podcastInfo }}
</template>

<script>
import { mapState, mapGetters } from 'vuex'

import { getResizedImageUrl } from '@/lib/utils'

import ScrollingText from '@/components/elements/ScrollingText.vue'
import LinkIcon from '@/components/icons/dashboard/Link.vue'

export default {
  components: {
    ScrollingText,
    LinkIcon
  },
  props: {
    isChaptersViewOpen: {
      type: Boolean,
      default: false
    },
    isFullScreen: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      nameMarqueeNeeded: false,
      podcastMarqueeNeeded: false,
      spaceBetweenMarquee: 50 // px
    }
  },

  computed: {
    ...mapState('player', [
      'currentEpisode',
      'currentPodcast'
    ]),
    ...mapGetters('player', ['getCurrentChapter', 'getCurrentChapterIndex']),

    podcastSlug () {
      return this.currentPodcast.slug.toLowerCase()
    },

    episodeId () {
      return this.currentEpisode.id
    },

    episodeTitle () {
      if (!this.episodeNumbers) {
        return this.currentEpisode?.title
      }
      return this.episodeNumbers + ' - ' + this.currentEpisode.title
    },

    episodeNumbers () {
      let season = this.currentEpisode?.itunes_season
      let episode = this.currentEpisode?.itunes_episode
      if (!parseInt(season) && !parseInt(episode)) {
        return ''
      }
      if (season > 0 && season < 10) { season = '0' + season.toString() }
      if (episode > 0 && episode < 10) { episode = '0' + episode.toString() }
      const seasonLetter = this.$t('single_words.season')[0]
      const episodeLetter = this.$t('single_words.episode')[0]
      if (!episode) {
        return `${seasonLetter}${season}`
      }
      if (!season) {
        return `${episodeLetter}${episode}`
      }
      return `${seasonLetter}${season} ${episodeLetter}${episode}`
    },

    podcastCoverUrl () {
      if (!this.currentPodcast.cover) {
        return {
          default: '/images/no-cover.png',
          retina: '/images/no-cover.png'
        }
      }

      const filename = this.currentPodcast.cover
      if (filename === 'no-cover-1400.jpg') {
        return {
          default: this.currentPodcast.cover,
          retina: this.currentPodcast.cover
        }
      }

      const cover = getResizedImageUrl(58, this.podcastSlug, filename)
      return cover
    },

    episodeCoverUrl () {
      if (!this.currentEpisode.episode_cover) {
        if (this.podcastCoverUrl) {
          return this.podcastCoverUrl
        } else {
          return {
            default: '/images/no-cover.png',
            retina: '/images/no-cover.png'
          }
        }
      }
      const filename = this.currentEpisode.episode_cover
      if (filename === 'no-cover-1400.jpg') {
        return {
          default: this.currentEpisode.episode_cover,
          retina: this.currentEpisode.episode_cover
        }
      }
      const cover = getResizedImageUrl(58, this.podcastSlug, filename)
      return cover
    },

    chapterCoverUrl () {
      const chapter = this.getCurrentChapter()
      if (!chapter || !chapter.img) {
        if (this.episodeCoverUrl) {
          return this.episodeCoverUrl
        } else {
          return {
            default: '/images/no-cover.png',
            retina: '/images/no-cover.png'
          }
        }
      }
      const slicedUrl = chapter.img.split('/')
      const filename = slicedUrl[slicedUrl.length - 1]
      if (filename === 'no-cover-1400.jpg') {
        return {
          default: this.currentEpisode.episode_cover,
          retina: this.currentEpisode.episode_cover
        }
      }

      const cover = getResizedImageUrl(58, this.podcastSlug, filename)
      return cover
    },

    podcastTitle () {
      return this.currentPodcast.title
    },

    podcastAuthor () {
      return this.currentPodcast.author_name
    },

    podcastInfo () {
      return `${this.podcastTitle} ${this.$t('single_words.by')} ${this.podcastAuthor}`
    },

    currentChapterLink () {
      return this.getCurrentChapter()?.url
    },

    currentChapterTitle () {
      const defaultTitle = `${this.$t('single_words.chapter')} ${this.getCurrentChapterIndex() + 1}`
      return this.getCurrentChapter()?.title ?? defaultTitle
    }
  },

  watch: {
    episodeTitle () {
      this.$nextTick(() => {
        this.checkMarqueeNeeded()
      })
    }
  },

  mounted () {
    this.checkMarqueeNeeded()
    window.addEventListener('resize', this.checkMarqueeNeeded)
  },

  beforeDestroy () {
    window.addEventListener('resize', this.checkMarqueeNeeded)
  },

  methods: {
    checkMarqueeNeeded () {
      const infoAreaWidth = this.$refs.infoArea ? this.$refs.infoArea.clientWidth : 1

      const infoNameWidth = this.$refs.infoName ? this.$refs.infoName[0].$el.clientWidth : 0
      const infoPodcastWidth = this.$refs.infoPodcast ? this.$refs.infoPodcast[0].$el.clientWidth : 0

      this.nameMarqueeNeeded = infoAreaWidth <= infoNameWidth - this.spaceBetweenMarquee
      this.podcastMarqueeNeeded = infoAreaWidth <= infoPodcastWidth - this.spaceBetweenMarquee
    }
  }
}
</script>

<style lang="scss" scoped>
.marquee-content {
  animation: marquee calc(0.3s * var(--num-chars)) linear infinite;
  @keyframes marquee {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
}
.info {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: relative;

  @include display-less(phablet) {
    padding-right: 0;
  }

  &__overlay {
    display: none;
    @include display-less(phablet) {
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 40;
      cursor: pointer;
    }
  }

  &__cover {
    width: 58px;
    height: 58px;
    display: block;
    margin-right: $gap;
    border-radius: 8px;
    margin-top: auto;
    margin-bottom: auto;

    @include display-less(phablet) {
      width: 48px;
      height: 48px;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    gap: $gap * 0.25;
    margin: auto 0;
    &-desktop {
      display: block;

      @include display-less(phablet) {
        display: none;
      }

      &-title {
        &-content {
          color: #fff;
          font-size: 16px;
          text-decoration: none;
          line-height: 120%;
          > h3 {
            font-weight: 700;
          }
        }
      }
    }

    &-mobile {
      display: none;

      @include display-less(phablet) {
        display: block;
      }

      &-chapter {
        display: flex;
        flex-direction: column;
        gap: $gap * 0.75;

        &-subtitle {
          font-size: 12px;
          line-height: 120%;
        }

        &-title {
          &-content {
            color: #fff;
            font-size: 20px;
            text-decoration: none;
            line-height: 120%;
            font-weight: 700;
            > h3 {
              font-weight: 700;
            }
          }
        }

        &-link {
          font-size: 14px;
          display: flex;
          flex-direction: row;
          gap: $gap * 0.25;
          color: #FFFFFF;
          text-decoration: none;
          border-radius: 4px;
          background-color: #6C26D6;
          width: fit-content;
          padding: $gap * 0.25 $gap * 0.75;
          font-weight: 700;
          text-align: center;
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

          &-icon {
            width: 16px;
          }
        }
      }

      &-episode {
        &-title-content {
          color: #ffffff;
          text-decoration: none;
          font-size: 16px;
          > h3 {
            font-weight: 700;
          }

          &-full-screen {
            font-size: 20px;
          }
        }
      }
    }

    &-subtitle {
      &-content {
        color: #fff;
        font-size: 16px;
        text-decoration: none;
        line-height: 150%;
      }
    }
  }
}
</style>
