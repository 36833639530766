import { config } from '@/config'

const cookiesDomain = config.baseUrl.replace('https://', '.')

export default function (context) {
  const shareasaleSSCID = context.query.sscid
  if (shareasaleSSCID) {
    context.app.$cookiz.set('shareasaleSSCID', shareasaleSSCID, {
      path: '/',
      secure: true,
      domain: cookiesDomain
    })
  }
}
