import { config } from '@/config'

const siteUrl = config.baseUrl

export default function getMeta (params) {
  const title = params.title || 'RSS Podcast Hosting - Set Up Your Podcast in Less Than 3 Minutes'
  const metaTitle = params.metaTitle || 'The best podcast hosting and publishing platform - Set Up Your Podcast in Less Than 3 Minutes'
  const description = params.description || 'Our podcast hosting service allows you to set up your podcast and RSS feed in less than 3 minutes. Fully customizable. Apple and Spotify friendly. Free trial!'
  const isDefaultCover = !params.cover
  const cover = params.cover || siteUrl + '/images/rss.png'
  const ogImage = params.ogImage || siteUrl + '/images/rss-og-image.jpg'
  const twitterOgImage = params.twitterOgImage || siteUrl + '/images/rss-twitter.jpg'
  const coverSize = params.coverSize || [1200, 630]
  const author = params.author
  const route = params.route
  const i18nSeo = params.i18nSeo
  const i18nLocales = params.i18nLocales
  const type = params.type || 'website'
  const coverAlt = params.coverAlt || 'RSS.com'
  const withoutTitlePostfix = params.withoutTitlePostfix
  const robotsNoindex = params.robotsNoindex
  const podcastQuality = params.quality
  const script = params.script

  // Options
  const options = params.options || {
    gdpr: true
  }

  const coverAsFavicon = options.coverAsFavicon || false

  const titlePostfix = ''
  let url
  let htmlAttrs = {}
  let pageMeta
  // let canonicalUrl

  if (route) {
    url = siteUrl + route.path
  }

  // I18n SEO alternate meta and links
  if (i18nSeo && i18nSeo.htmlAttrs && i18nSeo.meta) {
    htmlAttrs = { ...htmlAttrs, ...i18nSeo.htmlAttrs }
    i18nSeo.meta = i18nSeo.meta.map((localeMetaTag) => {
      const localeInfo = i18nLocales.find((l) => { return l.code === localeMetaTag.content })
      localeMetaTag.content = localeMetaTag.content + '_' + localeInfo.territory
      return localeMetaTag
    })
    i18nSeo.link = i18nSeo.link.map((localLinkTag) => {
      let result = config.baseUrl
      const value = localLinkTag.href
      if (value !== '/') {
        result += value
      }
      localLinkTag.href = result
      return localLinkTag
    })
  }

  if (!url) {
    pageMeta = []
  } else {
    pageMeta = [
      { hid: 'og:url', property: 'og:url', content: url },
      { property: 'al:web:url', content: url },
      ...i18nSeo.meta
    ]
  }

  const resultMetaList = [
    { hid: 'description', name: 'description', content: description },
    { hid: 'og:title', property: 'og:title', content: metaTitle + titlePostfix },
    { hid: 'og:image', property: 'og:image', content: ogImage },
    { hid: 'og:image:secure_url', property: 'og:image:secure_url', content: ogImage },
    { hid: 'og:description', property: 'og:description', content: description },
    { hid: 'og:site_name', property: 'og:site_name', content: 'RSS.com' },
    { hid: 'og:type', property: 'og:type', content: type },
    { hid: 'og:image:height', property: 'og:image:height', content: coverSize[1] },
    { hid: 'og:image:width', property: 'og:image:width', content: coverSize[0] },
    { hid: 'itemprop:name', itemprop: 'name', content: metaTitle + titlePostfix },
    { hid: 'itemprop:description', itemprop: 'description', content: description },
    { hid: 'itemprop:image', itemprop: 'image', content: cover },
    { hid: 'twitter:card', name: 'twitter:card', content: 'summary_large_image' },
    { hid: 'twitter:site', name: 'twitter:site', content: '@rss' },
    { hid: 'twitter:title', name: 'twitter:title', content: withoutTitlePostfix ? metaTitle : metaTitle + titlePostfix },
    { hid: 'twitter:description', name: 'twitter:description', content: description },
    { hid: 'twitter:image', name: 'twitter:image', content: twitterOgImage },
    { hid: 'twitter:image:alt', name: 'twitter:image:alt', content: coverAlt },

    ...pageMeta,
    { hid: 'charset', charset: 'utf-8' },
    { hid: 'viewport', name: 'viewport', content: 'width=device-width,minimum-scale=1,initial-scale=1' },

    // Social media meta
    { hid: 'fb:app_id', property: 'fb:app_id', content: '264367114976413' }
  ]

  const resultLinkList = [
    // {
    //   hid: 'canonical',
    //   rel: 'canonical',
    //   href: canonicalUrl
    // },
    {
      rel: 'alternate',
      hreflang: 'x-default',
      href: url ?? siteUrl,
      'data-hid': 'alternate-hreflang-x-default'
    },
    ...i18nSeo.link
  ]

  // Add robots noindex
  if (robotsNoindex || podcastQuality === false) {
    resultMetaList.push({
      hid: 'robots-noindex',
      name: 'robots',
      content: 'noindex'
    })
  }

  // Add author meta
  if (author) {
    resultMetaList.push({
      hid: 'author',
      name: 'author',
      content: author
    })
  }

  // Change default favicon for cover
  if (!isDefaultCover && coverAsFavicon) {
    const splittedCoverUrl = cover.split('/')
    splittedCoverUrl[splittedCoverUrl.length - 2] = 64
    const customFaviconUrl = splittedCoverUrl.join('/')

    resultLinkList.push({ rel: 'shortcut icon', hid: 'favicon-shortcut', href: customFaviconUrl })
    resultLinkList.push({ rel: 'icon', hid: 'favicon-icon', sizes: '64x64', href: customFaviconUrl })
    resultLinkList.push({ rel: 'apple-touch-icon', hid: 'favicon-apple-touch-icon', sizes: '64x64', href: customFaviconUrl })
  }

  // Scripts
  const resultScriptList = []

  if (script && script.list && script.list.includes('freshdesk')) {
    resultScriptList.push({
      async: true,
      defer: true,
      type: 'text/javascript',
      hid: 'freshdesk-widget-main',
      src: 'https://widget.freshworks.com/widgets/44000001365.js'
    })
    resultScriptList.push({
      type: 'text/javascript',
      hid: 'freshdesk-widget-init',
      innerHTML: 'window.fwSettings={ widget_id: 44000001365, locale: "en" }; !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()'
    })
  }

  if (options.gdpr) {
    resultScriptList.push({
      defer: true,
      hid: 'shareasale',
      type: 'text/javascript',
      src: 'https://www.dwin1.com/19038.js'
    })
  }

  return {
    title,
    meta: resultMetaList,
    link: resultLinkList,
    script: resultScriptList,
    htmlAttrs
  }
}
