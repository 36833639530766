<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.3609 12.899C15.0684 13.0472 14.7251 13.0317 14.4459 12.8565C14.1666 12.6813 14 12.3788 14 12.0473L14 6.95207C14 6.62056 14.1666 6.31803 14.4459 6.14352C14.5985 6.04851 14.7691 6 14.9411 6C15.0844 6 15.2276 6.03301 15.3616 6.10174L18.4477 6.51976C21.9023 6.98767 21.9145 11.9787 18.4623 12.4635L15.3609 12.899Z"
      :fill="`url(#${componentId}_paint0_linear_2042_28013)`"
    />
    <path
      d="M15.3609 12.899C15.0684 13.0472 14.7251 13.0317 14.4459 12.8565C14.1666 12.6813 14 12.3788 14 12.0473L14 6.95207C14 6.62056 14.1666 6.31803 14.4459 6.14352C14.5985 6.04851 14.7691 6 14.9411 6C15.0844 6 15.2276 6.03301 15.3616 6.10174L18.4477 6.51976C21.9023 6.98767 21.9145 11.9787 18.4623 12.4635L15.3609 12.899Z"
      :fill="`url(#${componentId}_paint1_linear_2042_28013)`"
    />
    <path
      d="M4.04057 11.1217C3.52865 9.58595 4.67174 8 6.29057 8C7.31142 8 8.21775 8.65324 8.54057 9.62171L11.9594 19.8783C12.4713 21.414 11.3283 23 9.70943 23C8.68858 23 7.78225 22.3468 7.45943 21.3783L4.04057 11.1217Z"
      fill="#D9D9D9"
    />
    <path
      d="M4.04057 11.1217C3.52865 9.58595 4.67174 8 6.29057 8C7.31142 8 8.21775 8.65324 8.54057 9.62171L11.9594 19.8783C12.4713 21.414 11.3283 23 9.70943 23C8.68858 23 7.78225 22.3468 7.45943 21.3783L4.04057 11.1217Z"
      :fill="`url(#${componentId}_paint2_linear_2042_28013)`"
    />
    <path
      d="M4.04057 11.1217C3.52865 9.58595 4.67174 8 6.29057 8C7.31142 8 8.21775 8.65324 8.54057 9.62171L11.9594 19.8783C12.4713 21.414 11.3283 23 9.70943 23C8.68858 23 7.78225 22.3468 7.45943 21.3783L4.04057 11.1217Z"
      :fill="`url(#${componentId}_paint3_linear_2042_28013)`"
    />
    <g :filter="`url(#${componentId}_filter0_b_2042_28013)`">
      <path
        d="M3 7.81404C3 6.78483 3.78111 5.92365 4.80544 5.82353L9.55898 5.3589C9.84947 5.3305 10.1302 5.23887 10.3815 5.09044L15.9829 1.78204C17.3161 0.994575 19 1.95567 19 3.50409V15.601C19 17.1259 17.3618 18.0899 16.0287 17.3493L10.3228 14.1794C10.1094 14.0608 9.87609 13.9823 9.6344 13.9478L4.71716 13.2453C3.73186 13.1046 3 12.2607 3 11.2654V7.81404Z"
        fill="#77F19E"
        fill-opacity="0.3"
      />
      <path
        d="M3.25 7.81404C3.25 6.91348 3.93347 6.15995 4.82976 6.07234L9.5833 5.60771C9.9101 5.57577 10.226 5.47268 10.5087 5.30569L16.11 1.9973C17.2766 1.30826 18.75 2.14922 18.75 3.50409V15.601C18.75 16.9353 17.3166 17.7788 16.1501 17.1307L10.4443 13.9608C10.2042 13.8274 9.94166 13.7391 9.66975 13.7003L4.75251 12.9978C3.89038 12.8747 3.25 12.1363 3.25 11.2654V7.81404Z"
        :stroke="`url(#${componentId}_paint4_linear_2042_28013)`"
        stroke-width="0.5"
      />
    </g>
    <defs>
      <filter
        :id="`${componentId}_filter0_b_2042_28013`"
        x="1"
        y="-0.499023"
        width="20"
        height="20.1025"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="1" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_2042_28013"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_2042_28013"
          result="shape"
        />
      </filter>
      <linearGradient
        :id="`${componentId}_paint0_linear_2042_28013`"
        x1="19.9785"
        y1="13"
        x2="19.9785"
        y2="6"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFB37F" />
        <stop offset="1" stop-color="#FF7B0D" />
      </linearGradient>
      <linearGradient
        :id="`${componentId}_paint1_linear_2042_28013`"
        x1="25"
        y1="9.5"
        x2="16"
        y2="9.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#57EBDE" />
        <stop offset="1" stop-color="#AEFB2A" />
      </linearGradient>
      <linearGradient
        :id="`${componentId}_paint2_linear_2042_28013`"
        x1="8.57942"
        y1="8"
        x2="8.57942"
        y2="25"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#FFB37F" />
        <stop offset="1" stop-color="#FF7B0D" />
      </linearGradient>
      <linearGradient
        :id="`${componentId}_paint3_linear_2042_28013`"
        x1="3"
        y1="15.5"
        x2="13"
        y2="15.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#57EBDE" />
        <stop offset="1" stop-color="#AEFB2A" />
      </linearGradient>
      <linearGradient
        :id="`${componentId}_paint4_linear_2042_28013`"
        x1="2.95332"
        y1="-0.129201"
        x2="19.746"
        y2="16.7535"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" stop-opacity="0.4" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    componentId: {
      type: String,
      default: ''
    }
  }
}
</script>
