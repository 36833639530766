<template lang="pug">
button(
  @click="$emit('click')"
  :class="['burger', {'burger_active': active}, {'burger_inverse': inverse}]"
  aria-label='Menu'
)
  span.burger__icon
    span.line-1
    span.line-2
    span.line-3
    span.line-4
</template>

<script>
export default {
  props: {
    active: Boolean,
    inverse: Boolean
  }
}
</script>

<style lang="scss" scoped>
$size: 32px;

.burger {
  @extend %resetButton;
  display: inline-flex;
  align-items: center;
  font-size: $fontSizeBase;
  outline: none;

  &__icon {
    flex: 0 0 $size;
    position: relative;
    width: $size;
    height: $size;
    border-radius: 50%;

    span {
      display: block;
      position: relative;
      width: $size;
      height: 3px;
      border-radius: 3px;
      margin-top: calc((100% / 4) - 2.5px);
      background: $colorLight;
      transition: transform $speed;
      will-change: transform;

      &.line-3 {
        margin-top: -3.5px;
      }

      .header_inner & {
        background: $colorText;
      }
    }

    .burger_active & {
      span {
        background: $colorLight;
      }
    }

    .burger_inverse & {
      span {
        background: $colorText;
      }
    }
  }

  &_active {
    .line-1 {
      transform: translateY(200%) scale(0);
    }

    .line-4 {
      transform: translateY(-200%) scale(0);
    }

    .line-2 {
      transform: rotate(-45deg);
    }

    .line-3 {
      transform: rotate(45deg);
    }
  }
}
</style>
