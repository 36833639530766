export default {
  setCurrentPlayingEpisode ({ commit }, episode) {
    commit('setCurrentPlayingEpisode', episode)

    if (!episode) {
      return
    }
    commit('setCurrentPlayingEpisodeId', episode.id)
  },

  setCurrentPlayingPodcast ({ commit }, podcast) {
    commit('setCurrentPlayingPodcast', podcast)
  },

  setChapters ({ commit }, chapters) {
    commit('setChapters', chapters)
  },

  setPlayerStatus ({ commit }, status) {
    commit('setPlayerStatus', status)
  },

  setCurrentTime ({ commit }, currentTime) {
    commit('setCurrentTime', currentTime)
  },

  setSkipTo ({ commit }, status) {
    commit('setSkipTo', status)
  },

  setSaveCurrentTime ({ commit }, isSaved) {
    commit('setSaveCurrentTime', isSaved)
  },

  setCountdown ({ commit }, countdown) {
    commit('setCountdown', countdown)
  },

  setPlayList ({ commit }, playlist) {
    commit('setPlayList', playlist)
  },

  setLoadingStatus ({ commit }, isLoading) {
    commit('setLoadingStatus', isLoading)
  },

  setVolume ({ commit }, volume) {
    commit('setVolume', volume)
  },

  addTooltipId ({ commit }, tooltipId) {
    commit('addTooltipId', tooltipId)
  },

  removeTooltipId ({ commit }, tooltipId) {
    commit('removeTooltipId', tooltipId)
  }
}
