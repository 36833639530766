<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g :clip-path="`url(#${componentId}_clip0_1972_21584)`">
      <path
        d="M0.95347 22.5575L8.53501 18.619C8.65272 18.5582 8.74768 18.4611 8.80578 18.3421L15.2612 4.72361C15.3016 4.63759 15.3614 4.56211 15.436 4.50309C15.5105 4.44407 15.5977 4.40311 15.6907 4.38344C15.7837 4.36377 15.88 4.36591 15.972 4.38969C16.0641 4.41348 16.1493 4.45827 16.2212 4.52054L23.7904 11.0682C23.8568 11.1266 23.9099 11.1986 23.946 11.2793C23.9821 11.36 24.0004 11.4475 23.9996 11.5359V20.9082C23.9996 21.5611 23.7403 22.1872 23.2787 22.6488C22.817 23.1104 22.1909 23.3698 21.5381 23.3698H1.15039C1.05432 23.3691 0.96123 23.3363 0.885924 23.2767C0.810618 23.217 0.757418 23.1339 0.734783 23.0405C0.712147 22.9471 0.721376 22.8489 0.761 22.7613C0.800625 22.6738 0.868372 22.6021 0.95347 22.5575Z"
        :fill="`url(#${componentId}_paint0_linear_1972_21584)`"
        fill-opacity="0.8"
      />
      <path
        d="M0.467524 21.8926L7.44599 12.4526C7.52071 12.3502 7.62526 12.2734 7.74532 12.2326C7.86539 12.1919 7.99511 12.1892 8.11675 12.225L15.286 14.3173C15.4231 14.3575 15.5699 14.3488 15.7012 14.2927C15.8326 14.2366 15.9403 14.1365 16.006 14.0096L22.8306 0.96957C22.8937 0.846459 22.9964 0.748187 23.1221 0.69061C23.2479 0.633033 23.3894 0.61951 23.5238 0.652221C23.6582 0.684933 23.7777 0.761971 23.8629 0.870907C23.9482 0.979843 23.9942 1.11432 23.9937 1.25265V20.908C23.9937 21.5609 23.7343 22.187 23.2727 22.6486C22.8111 23.1102 22.185 23.3696 21.5321 23.3696H1.21214C1.04071 23.3696 0.872674 23.3218 0.72685 23.2317C0.581026 23.1416 0.463179 23.0126 0.386515 22.8593C0.309851 22.706 0.277398 22.5343 0.292793 22.3636C0.308188 22.1929 0.370822 22.0298 0.473678 21.8926H0.467524Z"
        :fill="`url(#${componentId}_paint1_linear_1972_21584)`"
        fill-opacity="0.8"
      />
    </g>
    <defs>
      <linearGradient
        :id="`${componentId}_paint0_linear_1972_21584`"
        x1="3.87555"
        y1="24.7326"
        x2="13.5509"
        y2="6.44079"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F2994A" />
        <stop offset="0.5" stop-color="#F2C94C" />
        <stop offset="0.985" stop-color="#FFEBAE" />
      </linearGradient>
      <linearGradient
        :id="`${componentId}_paint1_linear_1972_21584`"
        x1="3.49988"
        y1="25.0003"
        x2="24.9999"
        y2="5.50034"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#F2994A" />
        <stop offset="0.5" stop-color="#F2C94C" />
        <stop offset="0.985" stop-color="#FFEBAE" />
      </linearGradient>
      <clipPath :id="`${componentId}_clip0_1972_21584`">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    componentId: {
      type: String,
      default: ''
    }
  }
}
</script>
