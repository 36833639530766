<template>
  <div
    class="notification-for-ie-users"
    style="left: 0; top: 0; right: 0; position: absolute; z-index: 9999; background-color: #ffd974; color: #000; display: none; font-size: 20px; font-weight: bold; padding: 20px; align-items: center; justify-content: center; text-align: center; box-sizing: border-box"
  >
    You are using an old version of Internet Explorer, which is not supported any more by Microsoft. <br>To fully take advantage of our service, we recommend to user a modern browser, such as <a href="https://www.google.com/chrome/">Google Chrome</a>.
  </div>
</template>

<script>
export default {}
</script>

<style scoped>
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .notification-for-ie-users {
    display: block!important;
  }
}
</style>
