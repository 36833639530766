<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2.60408 7.8817L2.94528 8.23902H6.50232L9.34795 11.2192V14.1993H10.7708V17.1795H12.1936L14.3278 12.7092V9.7291H10.0594L8.63654 8.23902L12.1936 4.51383V2.04785C17.8904 2.15131 22.4767 6.80166 22.4767 12.5232C22.4767 18.3095 17.7859 23.0003 11.9996 23.0003C6.21322 23.0003 1.52246 18.3095 1.52246 12.5232C1.52246 10.8565 1.91164 9.28068 2.60408 7.8817Z"
      :fill="`url(#${componentId}_paint0_linear_2042_28007)`"
    />
    <g :filter="`url(#${componentId}_filter0_bi_2042_28007)`">
      <circle cx="19" cy="5" r="4" fill="#77FFDB" fill-opacity="0.5" />
      <circle
        cx="19"
        cy="5"
        r="3.875"
        :stroke="`url(#${componentId}_paint1_linear_2042_28007)`"
        stroke-width="0.25"
      />
    </g>
    <defs>
      <filter
        :id="`${componentId}_filter0_bi_2042_28007`"
        x="14.5"
        y="0.5"
        width="9"
        height="9"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.25" />
        <feComposite
          in2="SourceAlpha"
          operator="in"
          result="effect1_backgroundBlur_2042_28007"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_2042_28007"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="0.0418636" dy="0.0209318" />
        <feGaussianBlur stdDeviation="0.104659" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect2_innerShadow_2042_28007"
        />
      </filter>
      <linearGradient
        :id="`${componentId}_paint0_linear_2042_28007`"
        x1="44.0129"
        y1="-2.02828"
        x2="16.9874"
        y2="36.6334"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#CBFFF1" />
        <stop offset="0.551244" stop-color="#1DFFC3" />
        <stop offset="1" stop-color="#0A4C3B" />
      </linearGradient>
      <linearGradient
        :id="`${componentId}_paint1_linear_2042_28007`"
        x1="16.2735"
        y1="1.93201"
        x2="21.3766"
        y2="8.25238"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="white" stop-opacity="0.25" />
        <stop offset="1" stop-color="white" stop-opacity="0" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    componentId: {
      type: String,
      default: ''
    }
  }
}
</script>
