<template>
  <svg viewBox="0 0 24 24" :fill="color" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.75 14.25C21.1285 14.25 20.625 14.7535 20.625 15.375V20.625H15.3973C14.7759 20.625 14.2723 21.1285 14.2723 21.75C14.2723 22.3715 14.7759 22.875 15.3973 22.875H21.75C22.3715 22.875 22.875 22.3715 22.875 21.75V15.375C22.875 14.7535 22.3715 14.25 21.75 14.25Z"
    />
    <path
      d="M2.25 1.125L8.64001 1.125C9.26147 1.125 9.76501 1.62854 9.76501 2.25C9.76501 2.87146 9.26147 3.375 8.64001 3.375L3.375 3.375L3.375 8.625C3.375 9.24646 2.87146 9.75 2.25 9.75C1.62854 9.75 1.125 9.24646 1.125 8.625L1.125 2.25C1.125 1.62854 1.62854 1.125 2.25 1.125Z"
    />
    <path
      d="M15.3973 1.125L21.75 1.125C22.3715 1.125 22.875 1.62854 22.875 2.25V8.625C22.875 9.24646 22.3715 9.75 21.75 9.75C21.1285 9.75 20.625 9.24646 20.625 8.625V3.375L15.3973 3.375C14.7759 3.375 14.2723 2.87146 14.2723 2.25C14.2723 1.62854 14.7759 1.125 15.3973 1.125Z"
    />
    <path
      d="M3.375 20.625H8.64001C9.26147 20.625 9.76501 21.1285 9.76501 21.75C9.76501 22.3715 9.26147 22.875 8.64001 22.875H2.25C1.62854 22.875 1.125 22.3715 1.125 21.75L1.125 15.375C1.125 14.7535 1.62854 14.25 2.25 14.25C2.87146 14.25 3.375 14.7535 3.375 15.375L3.375 20.625Z"
    />
  </svg>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: 'white'
    }
  }
}
</script>
