<template lang="pug">
.chapters
  .chapters__container
    .chapters__container-head(
      v-if="currentChapter"
      :class="!isChaptersViewOpen ? 'chapters__container-head-hidden': ''"
    )
      h3.chapters__container-head-title {{ `${$t('chapter_playing').toUpperCase()} #${ getCurrentChapterIndex() + 1 }` }}
      h2.chapters__container-head-subtitle {{ getTitle(currentChapter) || getDefaultTitle(getCurrentChapterIndex()) }}
      a.chapters__container-head-link(
        v-if="getLink(currentChapter)"
        target="_blank"
        :href="getLink(currentChapter)"
      )
        LinkIcon.chapters__container-head-link-icon
        span {{ $t('single_words.link') }}

    .chapters__container-body(v-if="isChaptersViewOpen")
      h3.chapters__container-body-title {{ $t('single_words.chapters').toUpperCase() }}
      ul.chapters__container-body-list
        li.chapters__container-body-list-item(
          v-for="(chapter, index) in chapters"
          :class="isChapterPlaying(chapter) ? 'chapters__container-body-list-item-playing': ''"
          @click="skipTo(getStartTime(chapter))"
        )
          span.chapters__container-body-list-item-index {{ index + 1 }}
          span.chapters__container-body-list-item-title {{ getTitle(chapter) || getDefaultTitle(index) }}
          .chapters__container-body-list-item-wave(v-if="isChapterPlaying(chapter) && status === 'playing'")
            WaveIcon.chapters__container-body-list-item-wave-icon
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'

import LinkIcon from '@/components/icons/dashboard/Link.vue'
import WaveIcon from '@/components/icons/player/Wave.vue'

export default {
  components: {
    LinkIcon,
    WaveIcon
  },
  props: {
    isChaptersViewOpen: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState('player', ['currentEpisode', 'currentTime', 'chapters', 'status']),
    ...mapGetters('player', ['getCurrentChapter', 'getCurrentChapterIndex']),

    currentChapter () {
      return this.getCurrentChapter()
    }
  },

  methods: {
    ...mapActions('player', ['setSkipTo', 'setPlayerStatus']),

    getStartTime (chapter) {
      return chapter.startTime
    },

    getTitle (chapter) {
      return chapter.title
    },

    getDefaultTitle (index) {
      return `${this.$t('single_words.chapter')} ${index + 1}`
    },

    getLink (chapter) {
      return chapter.url
    },

    skipTo (seconds) {
      this.setSkipTo(seconds)
      this.setPlayerStatus('playing')
    },

    isChapterPlaying (chapter) {
      const startTime = chapter.startTime
      const endTime = chapter.endTime
      return startTime <= this.currentTime && this.currentTime < endTime
    }
  }
}
</script>

<style lang="scss" scoped>
.chapters {
  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: $gap * 1.5;

    &-head {
      display: flex;
      flex-direction: column;
      gap: $gap * 0.75;

      &-hidden {
        @include display-less(phablet) {
          display: none;
        }
      }

      &-title {
        font-size: 12px;
        font-weight: 700;
      }

      &-subtitle {
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
      }

      &-link {
        display: flex;
        flex-direction: row;
        gap: $gap * 0.25;
        color: #FFFFFF;
        text-decoration: none;
        border-radius: 4px;
        background-color: #6C26D6;
        width: fit-content;
        padding: $gap * 0.25 $gap * 0.75;
        font-weight: 700;
        text-align: center;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        &-icon {
          width: 16px;
        }
      }
    }

    &-body {
      display: flex;
      flex-direction: column;
      gap: $gap * 0.75;
      overflow-y: auto;
      padding-bottom: $gap;

      &::-webkit-scrollbar {
        width: 5px;
      }

      &::-webkit-scrollbar-track {
        background-color: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background-color: #555;
      }

      &-title {
        font-weight: 700;
        font-size: 12px;
      }

      &-list {
        display: flex;
        flex-direction: column;
        gap: $gap * 0.75;

        &-item {
          cursor: pointer;
          display: flex;
          flex-direction: row;
          gap: $gap * 0.25;

          &-index {
            margin-right: $gap * 0.50;
          }

          &-title {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            flex-grow: 1;
          }

          &-wave {
            width: 44px;
            position: relative;
            flex-shrink: 0;
            &-icon {
              position: absolute;
              min-width: 44px;
              max-width: 44px;
              width: 44px;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }

          &-playing {
            font-weight: 700;
          }
        }
      }
    }
  }
}
</style>
