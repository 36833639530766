export default {
  isFirstEpisodeInsideThePlaylist: (state) => {
    const currentEpisode = state.currentEpisode
    const firstEpisodeOfPlaylist = state.playlist[0]

    if (currentEpisode && firstEpisodeOfPlaylist && currentEpisode.id === firstEpisodeOfPlaylist) {
      return true
    }
    return false
  },

  isLatestEpisodeInsideThePlaylist: (state) => {
    const currentEpisode = state.currentEpisode
    const lastEpisodeOfPlaylist = state.playlist[state.playlist.length - 1]

    if (currentEpisode && lastEpisodeOfPlaylist && currentEpisode.id === lastEpisodeOfPlaylist) {
      return true
    }
    return false
  },

  getNextEpisodeInsideThePlaylist: (state) => {
    return (eid) => {
      let episodeIndex
      let currentEpisodeId

      if (!eid) {
        currentEpisodeId = state.currentEpisode.id
      } else {
        currentEpisodeId = eid
      }

      state.playlist.forEach((episodeId, index) => {
        if (currentEpisodeId === episodeId) {
          episodeIndex = index
        }
      })

      return state.playlist[episodeIndex + 1]
    }
  },

  getPreviousEpisodeInsideThePlaylist: (state) => {
    return (eid) => {
      let episodeIndex
      let currentEpisodeId

      if (!eid) {
        currentEpisodeId = state.currentEpisode.id
      } else {
        currentEpisodeId = eid
      }

      state.playlist.forEach((episodeId, index) => {
        if (currentEpisodeId === episodeId) {
          episodeIndex = index
        }
      })

      return state.playlist[episodeIndex - 1]
    }
  },

  getCurrentChapter: (state) => {
    return () => {
      if (!state.chapters || state.chapters.length <= 0) {
        return null
      }

      for (const chapter of state.chapters) {
        if (chapter.startTime <= state.currentTime && state.currentTime < chapter.endTime) {
          return chapter
        }
      }
      return null
    }
  },

  getCurrentChapterIndex: (state) => {
    return () => {
      if (!state.chapters || state.chapters.length <= 0) {
        return null
      }

      for (const i in state.chapters) {
        const chapter = state.chapters[i]
        if (chapter.startTime <= state.currentTime && state.currentTime < chapter.endTime) {
          return Number(i)
        }
      }
      return null
    }
  }
}
