import Vue from 'vue'

export default function ({ app }) {
  Vue.prototype.$locatedLink = (path, domain) => {
    let result = path

    if (app.i18n.defaultLocale !== app.i18n.locale) {
      result = '/' + app.i18n.locale + path
    }

    if (domain) {
      result = domain + result
    }

    return result
  }
}
